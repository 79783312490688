import { useParams } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { botBuilderNavPaths } from "@circle-react/helpers/urlHelpers/botBuilderNavPaths";
import type { SidebarLink } from "./types";

interface BotParams {
  botPublicId: string;
}

export const useCollapsedSidebarLinks = (): SidebarLink[] => {
  const { currentCommunityMember } = usePunditUserContext();
  const { botPublicId } = useParams<BotParams>();

  return [
    {
      label: t("community_bot.bot_builder.customize_page.title"),
      icon: "20-bot-customize",
      path: botBuilderNavPaths.customize({
        botPublicId,
      }),
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      label: t("community_bot.bot_builder.audience_page.title"),
      icon: "20-audience",
      path: botBuilderNavPaths.audience({
        botPublicId,
      }),
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      label: t("community_bot.bot_builder.behavior_page.title"),
      icon: "20-bot-behavior",
      path: botBuilderNavPaths.behavior({
        botPublicId,
      }),
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      label: t("community_bot.bot_builder.knowledge_page.title"),
      icon: "20-bot-content",
      path: botBuilderNavPaths.knowledge({
        botPublicId,
      }),
      enabled: isCommunityAdmin(currentCommunityMember),
    },
  ];
};
