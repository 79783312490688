import { useModal } from "@ebay/nice-modal-react";
import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Content } from "../SnippetForm/Content";
import { Header } from "../SnippetForm/Header";
import { useCreateSnippet } from "../snippetsQueries";

export interface Snippet {
  title: string;
  body: string;
}

export const AddSnippetModalComponent = () => {
  const modal = useModal();
  const createSnippetMutation = useCreateSnippet({ onSuccess: modal.remove });

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content
        size="auto"
        className="h-full max-h-full max-w-[720px] overflow-visible !rounded-t-none sm:h-full sm:!rounded-t-lg md:max-h-[80vh] lg:max-h-[550px]"
        animations="fadeIn"
      >
        <Header
          title={t(
            "settings.community_bot.knowledge.custom.create_modal.title",
          )}
        />
        <Content
          defaultValues={{}}
          onSubmit={(data: Snippet) => createSnippetMutation.mutate(data)}
          actionLabel={t("settings.community_bot.knowledge.custom.add")}
        />
      </Modal.Content>
    </Modal>
  );
};
