import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { TableCell } from "@circle-react-uikit/Table";
import type { CellVariant } from "@circle-react-uikit/Table/TableCell";
import type { Member } from "../types";

interface DropdownColumnProps {
  member: Member;
  cellVariant: CellVariant;
  onEdit: (member: Member) => void;
  onRemove: (id: string) => void;
  tableVariant?: "space-members" | "space-group-members";
}

export const DropdownColumn = ({
  member,
  cellVariant,
  onEdit,
  onRemove,
  tableVariant,
}: DropdownColumnProps) => {
  const { id } = member;

  return (
    <TableCell variant={cellVariant} className="w-12 pr-4 text-right">
      <Dropdown
        direction="bottom-end"
        button={
          <Dropdown.MenuButton className="invisible group-hover:visible">
            <Icon
              type="20-menu-dots-horizontal"
              size={20}
              className="text-dark"
            />
          </Dropdown.MenuButton>
        }
      >
        <Dropdown.Item>
          <Dropdown.ItemLink type="button" onClick={() => onEdit(member)}>
            {t("spaces.form.edit.members.edit_member")}
          </Dropdown.ItemLink>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onRemove(id)}>
          <Dropdown.ItemLink type="button">
            {tableVariant == "space-group-members"
              ? t("space_groups.form.edit.members.remove_from_space_group")
              : t("spaces.form.edit.members.remove_from_space")}
          </Dropdown.ItemLink>
        </Dropdown.Item>
      </Dropdown>
    </TableCell>
  );
};
