import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { WorkflowBlock } from "../WorkflowBlock";
import { useCompactViewElements } from "./useCompactViewElements";

export const ActionBlock = ({
  id,
  children,
  onAddClick,
  onDeleteClick,
  hasBottomConnection = true,
  actionType,
  title,
  isApplicableToMembersOnly,
  ...rest
}) => {
  const { isCompactView, compactViewTitle } = useCompactViewElements(
    actionType,
    title,
  );

  return (
    <WorkflowBlock
      id={id}
      title={title}
      description={t("settings.workflows.action")}
      isApplicableToMembersOnly={isApplicableToMembersOnly}
      onAddNewConnection={onAddClick}
      hasBottomConnection={hasBottomConnection}
      className="m-auto"
      actions={[
        {
          label: t("settings.workflows.delete_step"),
          onClick: onDeleteClick,
        },
      ]}
      isCompactView={isCompactView}
      compactViewTitle={compactViewTitle}
      {...rest}
    >
      {children}
    </WorkflowBlock>
  );
};

ActionBlock.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasBottomConnection: PropTypes.bool,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  children: PropTypes.node,
  actionType: PropTypes.string,
  title: PropTypes.string,
  isApplicableToMembersOnly: PropTypes.bool,
};
