import { useState } from "react";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { liveStreamApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useRecorder = room => {
  const { error } = useToast();
  const [recorder, setRecorder] = useState(null);
  const [recordingKey, setRecordingKey] = useState(null);

  const createRecorderMutation = useMutation(
    recording_key => {
      setRecordingKey(recording_key);
      return liveStreamApi.createRecorder({
        id: room?.id,
        recording_key,
      });
    },
    {
      onSuccess: newRecorder => setRecorder(newRecorder),
      onError: response => {
        const errorMessage = response.firstErrorDetail
          ? `${t("live_streams.room.join_error")}: ${response.firstErrorDetail}`
          : `${t("live_streams.room.join_error")}.`;
        error(errorMessage);
      },
    },
  );

  return {
    createRecorder: createRecorderMutation.mutate,
    isCreatingRecorder: createRecorderMutation.isLoading,
    recorder: recorder && { ...recorder, recording_key: recordingKey },
  };
};
