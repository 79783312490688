import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { FormItem } from "./FormItem";
import { SenderEmail } from "./SenderEmail";
import { SettingsSection } from "./SettingsSection";
import { FIELD_USE_CUSTOM_REPLY_TO_EMAIL, I18N_ROOT } from "./constants";

export const FromAddress = () => {
  const { watch } = useFormContext();
  const useCustomReplyToEmail = watch(FIELD_USE_CUSTOM_REPLY_TO_EMAIL);

  return (
    <SettingsSection
      title={t([I18N_ROOT, "from_address"])}
      description={t([I18N_ROOT, "from_address_description"])}
    >
      <FormItem name="sender_name" label={t([I18N_ROOT, "sender_name"])} />
      <FormItem
        name="sender_email"
        label={t([I18N_ROOT, "sender_email"])}
        tooltipText={t([I18N_ROOT, "sender_email_tooltip"])}
      >
        <SenderEmail />
      </FormItem>
      <Form.Item
        className="!flex flex-row-reverse !pt-4"
        labelClassName="text-base text-default !font-normal ml-4"
        translationRoot={I18N_ROOT}
        hideDescription
        hideBorder
        fullWidth
        inline
        name={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
        id={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
      >
        <Form.ToggleSwitch
          id={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
          wrapperClass="!ml-0"
          name={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
          variant="small"
        />
      </Form.Item>
      {useCustomReplyToEmail && (
        <FormItem
          name="reply_to_email"
          label={t([I18N_ROOT, "reply_to_email"])}
          tooltipText={t([I18N_ROOT, "reply_to_email_tooltip"])}
        />
      )}
    </SettingsSection>
  );
};
