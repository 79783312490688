import { useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { useAddMemberModal } from "@/react/components/Modals/AddSpaceMember";
import { ConfirmRemoveMember } from "@/react/components/Modals/ConfirmRemoveMember";
import { Section } from "@/react/components/Spaces/CustomizeSpaceFullPage/Section";
import { MembersInfiniteTable } from "@/react/components/Spaces/EditV2/EditForm/Tabs/Members/MembersInfiniteTable";
import { isDraftSpace } from "@/react/helpers/spaceHelpers";
import {
  SORT_OPTIONS_MAP,
  useCommunityMembersInfiniteQuery,
} from "@/react/hooks/useCommunityMembersInfiniteQuery";
import { useRemoveMemberFromSpace } from "@/react/hooks/useRemoveMemberFromSpace";
import { useUpdateSpaceMemberModerator } from "@/react/hooks/useUpdateSpaceMemberModerator";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { usePunditUserContext } from "@circle-react/contexts";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import { useShowProfileEdit } from "@circle-react/hooks/profile/useShowProfileEdit";
import { DebouncedSearchBar } from "@circle-react-shared/DebouncedSearchBar";
import { DownloadMembersCSVButton } from "@circle-react-shared/DownloadMembersCSVButton";
import { Icon } from "@circle-react-shared/Icon";
import { ROLES } from "@circle-react-shared/RoleSelect";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

const getRole = (roles = {}, spaceId, moderatingIds = []) => {
  if (roles.admin) return "Admin";
  if (roles.moderator && moderatingIds.includes(spaceId)) return "Moderator";
  return "Member";
};

export const SpaceMembersForm = ({ space }) => {
  const toastr = useToast();
  const queryClient = useQueryClient();
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [query, setQuery] = useState("");
  const [removeId, setRemoveId] = useState(null);
  const { currentCommunityMember } = usePunditUserContext();
  const { showOwnProfileEdit, showMemberProfileEdit } = useShowProfileEdit();
  const { count, isLoading: isLoadingSpaceMembersCount } =
    useActiveSpaceMembersCount({
      spaceId: space.id,
    });
  const addMemberModal = useAddMemberModal();

  const { isLoading, hasNextPage, fetchNextPage, members, queryKey } =
    useCommunityMembersInfiniteQuery({
      // TODO: Sort by roles
      // TODO: Sort by latest added to space
      sort: SORT_OPTIONS_MAP.ROLE,
      filters: { space_id: space.id },
      query,
    });

  const {
    space_members_limit_reached: memberLimitReached = false,
    space_members_limit: memberLimit = 0,
  } = space;

  const { mutate: removeCommunityMember } = useRemoveMemberFromSpace({
    onError: error => {
      toastr.error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toastr.success(t("spaces.form.edit.members.members_removed"));
    },
    onSettled: () => {
      setIsConfirmRemoveOpen(false);
      setIsRemoving(false);
      setRemoveId(null);
    },
  });

  const { mutate: updateSpaceModeratorStatus } = useUpdateSpaceMemberModerator({
    onError: error => {
      toastr.error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toastr.success(t("spaces.form.edit.members.changed_role"));
    },
  });

  const handleEditMember = member => {
    const { id, publicUid } = member;
    const isCurrentCommunityMemberProfile = currentCommunityMember?.id == id;
    if (isCurrentCommunityMemberProfile) {
      return showOwnProfileEdit();
    }
    showMemberProfileEdit({ memberPublicId: publicUid });
  };

  const handleRemoveMember = () => {
    if (!removeId || !isConfirmRemoveOpen) return;
    setIsRemoving(true);
    removeCommunityMember({ spaceId: space.id, memberId: removeId });
  };

  const handleChangeMemberRole = (memberId, role) => {
    const isModerator = role === ROLES.MODERATOR;

    updateSpaceModeratorStatus({
      spaceId: space.id,
      memberId,
      isModerator,
    });
  };

  const confirmText = isRemoving
    ? t("spaces.form.edit.members.removing")
    : t("spaces.form.edit.members.remove_member");

  if (isLoading) {
    return (
      <div className="flex w-screen justify-center">
        <Loader />
      </div>
    );
  }

  const mappedMembers = members.map(
    ({
      id,
      avatar_url,
      name,
      email,
      roles,
      moderating_space_ids,
      public_uid,
      current_space_member = null,
      last_seen_at,
    }) => ({
      id: id,
      avatarUrl: avatar_url,
      name: name,
      email: email,
      role: getRole(roles, space.id, moderating_space_ids),
      publicUid: public_uid,
      lastActive: last_seen_at,
      currentSpaceMember: current_space_member,
    }),
  );

  return (
    <Section id="scrollable-container" className="!max-w-6xl px-0 md:!px-6">
      {/* Header */}
      <div className="flex flex-row flex-wrap items-center gap-4 md:gap-0">
        <div>
          <Typography.TitleSm weight="bold">
            {t("customize_space.members")}{" "}
            {!isLoadingSpaceMembersCount && `(${count})`}
            {isDraftSpace(space) && (
              <TippyV2
                content={t("courses.admin_summary.waitlist_tooltip")}
                placement="right"
              >
                <Icon type="16-clock" className="ml-2" size={16} />
              </TippyV2>
            )}
          </Typography.TitleSm>
        </div>
        <div className="flex w-full justify-between gap-4 md:flex-1 md:justify-end">
          <DownloadMembersCSVButton spaceId={space.id} />
          <TippyV2
            content={t("chat_space.member_limit_title", {
              member_limit: memberLimit,
            })}
            disabled={!memberLimitReached}
          >
            <Button
              variant="secondary"
              onClick={() =>
                addMemberModal.show({
                  spaceId: space.id,
                })
              }
              disabled={memberLimitReached}
            >
              {isDraftSpace(space)
                ? t("customize_space.add_waitlist")
                : t("customize_space.add_members")}
            </Button>
          </TippyV2>
        </div>
      </div>
      <div className="border-primary mt-6 flex flex-row items-center border-y py-3 lg:mt-8">
        <DebouncedSearchBar
          onSearch={setQuery}
          placeholder={t("spaces.form.edit.members.search_members")}
        />
      </div>
      <div className="h-full overflow-auto pt-4">
        <MembersInfiniteTable
          searchTerm={query}
          members={mappedMembers}
          dataLength={members.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          shouldHideAddMemberButton
          onEdit={handleEditMember}
          onAdd={() =>
            addMemberModal.show({
              spaceId: space.id,
            })
          }
          onRemove={id => {
            setRemoveId(id);
            setIsConfirmRemoveOpen(true);
          }}
          onRoleChange={handleChangeMemberRole}
          scrollableTarget="scrollable-container"
          shouldShowHeader
          cellVariant="no-x-padding"
        />
      </div>
      <ConfirmRemoveMember
        isOpen={isConfirmRemoveOpen}
        onClose={() => setIsConfirmRemoveOpen(false)}
        onConfirm={handleRemoveMember}
        disabled={isRemoving}
        confirmText={confirmText}
      />
    </Section>
  );
};

SpaceMembersForm.propTypes = {
  space: PropTypes.object,
};
