import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import type { DropdownOption } from "./types";

export interface LogoDropdownProps {
  children?: ReactNode;
  hasFullWidth?: boolean;
  dropDownOptions?: DropdownOption[];
}

const v2Props = {
  direction: "bottom-end",
  transitionWrapperClassName: "mt-2 md:-mt-2",
} as const;

const v3props = {
  direction: "bottom-start",
  transitionWrapperClassName:
    "mt-1 lg:-mt-2 !w-[16.25rem] !left-[1.65rem] lg:!-left-2.5",
  menuWrapperClassName: "!px-0 !mt-0",
} as const;

export const LogoDropdown = ({
  children,
  hasFullWidth = true,
  dropDownOptions = [],
}: LogoDropdownProps) => {
  const { isV3Enabled } = useIsV3();
  const dropdownProps = isV3Enabled ? v3props : v2Props;

  return (
    <Dropdown
      {...dropdownProps}
      size="full"
      popperOptions={{
        strategy: "absolute",
      }}
      className={classNames("h-full", {
        "w-full": hasFullWidth,
      })}
      buttonWrapperClassName="flex w-full h-full px-4 py-3"
      buttonClassName="w-full focus-visible:outline-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 rounded-md group"
      menuItemsClassName="overflow-y-auto max-h-[calc(100vh-4rem)]"
      button={
        <div className="flex w-full items-center justify-between">
          {children}
          {isV3Enabled && (
            <div className="group-hover:bg-c-header-active text-c-header group-hover:text-c-header-active ml-1.5 flex h-5 w-5 flex-col items-center justify-center rounded-full transition-colors duration-150">
              <Icon
                size={12}
                type="12-chevron-down"
                className="!transition-none"
              />
            </div>
          )}
          {!isV3Enabled && (
            <Icon className="text-c-sidebar" size={16} type="caret-down-bold" />
          )}
        </div>
      }
      buttonDataTestId="community-menu"
    >
      <div data-testid="community-dropdown-menu">
        {!isV3Enabled && (
          <Dropdown.Header>
            {t("community_header_dropdown.title")}
          </Dropdown.Header>
        )}
        <Dropdown.IconWithBadgeList options={dropDownOptions} />
      </div>
    </Dropdown>
  );
};
