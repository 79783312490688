import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import {
  durationToHrMin,
  sumDurations,
} from "@/react/helpers/dateTimeHelpers/formattedDuration";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { SectionContent } from "./SectionContent";
import { useCollapsibleSections } from "./hooks/useCollapsibleSections";

export const Content = ({ isMember = false, sections = [] }) => {
  const {
    hasExpandedSections,
    isSectionExpanded,
    toggleSectionExpanded,
    expandAllSections,
    collapseAllSections,
  } = useCollapsibleSections({ sections });

  if (sections?.length === 0) {
    return null;
  }

  const sectionsCount = sections.length;
  const lessonsCount = sections.reduce(
    (total, section) => total + section.lessons.length,
    0,
  );

  const duration = sumDurations(
    sections
      .map(section => section.lessons)
      .flat()
      .map(lesson => lesson.featured_media?.duration)
      .filter(Boolean),
  );
  const durationString = (duration.hours !== 0 || duration.minutes !== 0) && (
    <>
      <Typography.LabelSm>{t("separator_dot")}</Typography.LabelSm>
      <Typography.LabelSm>{durationToHrMin(duration)}</Typography.LabelSm>
    </>
  );

  return (
    <div className="flex w-full flex-col" data-testid="summary-course-content">
      <Typography.TitleSm as="h2" weight="semibold" color="text-darkest">
        {t("courses.summary.content.title")}
      </Typography.TitleSm>
      <div className="mb-4 flex justify-between">
        <div className="text-dark flex items-center space-x-1">
          <Typography.LabelSm>
            {t("courses.summary.content.sections_count", {
              count: sectionsCount,
            })}
          </Typography.LabelSm>
          <span>{t("separator_dot")}</span>
          <Typography.LabelSm>
            {t("courses.summary.content.lessons_count", {
              count: lessonsCount,
            })}
          </Typography.LabelSm>
          {durationString}
        </div>
        <Button
          className="!p-0"
          variant="plain"
          onClick={() => {
            hasExpandedSections() ? collapseAllSections() : expandAllSections();
          }}
        >
          <Typography.LabelSm>
            {hasExpandedSections()
              ? t("courses.summary.content.collapse_all_sections")
              : t("courses.summary.content.expand_all_sections")}
          </Typography.LabelSm>
        </Button>
      </div>
      <div className="border-primary overflow-hidden rounded-md border">
        {sections.map((section, index) => {
          const isLastSection = index === sectionsCount - 1;
          return (
            <SectionContent
              key={section.id}
              section={section}
              isMember={isMember}
              hasBorder={!isLastSection}
              open={isSectionExpanded(section)}
              onToggle={() => toggleSectionExpanded(section)}
            />
          );
        })}
      </div>
    </div>
  );
};

Content.propTypes = {
  isMember: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object),
};
