import isFunction from "lodash/isFunction";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { spaceGroupApi } from "@circle-react/api";
import { ContentSpecAccessGroupsControl } from "@circle-react/components/shared/AccessRegistry/ContentSpecAccessGroupsControl";
import {
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";
import { contentSpecTypes } from "@circle-react/types/AccessRegistry";
import { Accordion } from "@circle-react-shared/AccessRegistry/Accordion";
import { SecureConfirmationModal } from "@circle-react-shared/ModalsV2/SecureConfirmationModal";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

export interface DeleteSpaceGroupModalProps {
  show: boolean;
  spaceGroupId: number;
  onClose: () => void;
  onSuccess?: () => void;
}

export const DeleteSpaceGroupModal = ({
  show,
  spaceGroupId,
  onClose,
  onSuccess,
}: DeleteSpaceGroupModalProps) => {
  const { error, success } = useToast();
  const { goToCommunityRootPath } = useLinkToRoot();

  const {
    records: spaces,
    actions: { refresh: refreshSpaces },
  } = useSpacesContext();
  const {
    actions: { refresh: refreshSpaceGroups },
  } = useSpaceGroupsContext();

  const { mutate: deleteSpaceGroupMutation, isLoading: isDeleting } =
    useMutation(() => spaceGroupApi.destroy(spaceGroupId), {
      onSuccess: async () => {
        success(t("space_groups.form.delete.success"));
        await Promise.all([refreshSpaces(), refreshSpaceGroups()]);
        goToCommunityRootPath();

        isFunction(onClose) && onClose();
        isFunction(onSuccess) && onSuccess();
      },
      onError: () => {
        error(t("space_groups.form.delete.failed"));
      },
    });

  const spacesInSpaceGroup: any[] = spaces.filter(
    (space: any) => space.space_group_id === spaceGroupId,
  );
  const spacesInSpaceGroupCount = spacesInSpaceGroup.length;

  const descriptionParts = [t("space_groups.delete.description")];
  if (spacesInSpaceGroupCount > 0) {
    descriptionParts.push(t("space_groups.delete.this_includes"));
  }
  const description = descriptionParts.join(" ");

  return (
    <SecureConfirmationModal
      title={t("space_groups.delete.title")}
      confirmUserPrompt={t("space_groups.delete.confirm_prompt")}
      isOpen={show}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={deleteSpaceGroupMutation}
      confirmVariant="danger"
      isDisabled={isDeleting}
      confirmText={isDeleting ? t("deleting") : t("confirm")}
    >
      <div className="flex flex-col gap-y-5">
        <Typography.BodyMd>{description}</Typography.BodyMd>
        {spacesInSpaceGroupCount > 0 && (
          <Accordion
            title={t("space_groups.delete.spaces_count", {
              count: spacesInSpaceGroup.length,
            })}
            defaultExpanded={spacesInSpaceGroup.length <= 5}
          >
            <Accordion.BulletedList
              items={spacesInSpaceGroup.map(space => space.name)}
            />
          </Accordion>
        )}
        <ContentSpecAccessGroupsControl
          contentSpecType={contentSpecTypes.SpaceGroup}
          contentSpecId={spaceGroupId}
        />
      </div>
    </SecureConfirmationModal>
  );
};
