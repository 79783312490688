import PropTypes from "prop-types";
import { ActionGroup } from "../../NewWorkflowV2/ActionGroup";
import { EntryRule } from "../EntryRule";
import TemplateItemWrapper from "../TemplateItemWrapper";
import { getActionsByActionType } from "../utils";
import { Title } from "./Title";
import { TypeBadge } from "./TypeBadge";

export const TemplateItem = ({
  name,
  templateType,
  trigger,
  audience,
  actions: actionProps = [],
  onClick,
}) => {
  const actions = getActionsByActionType(actionProps);
  const hasActions = actions.length > 0;

  return (
    <TemplateItemWrapper onClick={onClick}>
      <div className="flex gap-2">
        <EntryRule
          trigger={trigger}
          audience={audience}
          workflowType={templateType}
        />
        {hasActions && <ActionGroup actions={actions} />}
      </div>
      <div className="flex h-full flex-col justify-between gap-2">
        <div className="flex">
          <Title>{name}</Title>
        </div>
        {templateType && (
          <div className="flex h-5">
            <TypeBadge type={templateType} />
          </div>
        )}
      </div>
    </TemplateItemWrapper>
  );
};

TemplateItem.propTypes = {
  name: PropTypes.string,
  templateType: PropTypes.string,
  trigger: PropTypes.object,
  audience: PropTypes.object,
  actions: PropTypes.array,
  onClick: PropTypes.func,
};
