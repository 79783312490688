import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { memberDirectoryPath } from "@circle-react/helpers/routerPathHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import type { NavigationLink } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavItemsToLinks";
import { MemberLinks } from "./MemberLinks";
import { PlatformMenuItem } from "./PlatformMenuItem";
import { PlatformMenuItemLink } from "./PlatformMenuItemLink";
import { PlatformMenuItemRenderer } from "./PlatformMenuItemRenderer";

export interface PlatformMenuViewProps {
  allDMsBadgeText?: string;
  disabled?: boolean;
  hasCurrentCommunityMember?: boolean;
  isActive?: boolean;
  isHomePageEnabled?: boolean;
  isMessagingEnabled?: boolean;
  isOnboardingActive?: boolean;
  notificationBadgeText?: string;
  onGettingStartedClick?: () => void;
  onGettingStartedClose?: () => void;
  onNotificationClick?: () => void;
  onSearchClick?: () => void;
  resetNewNotificationsCount?: () => void;
  shouldShowActiveHomeLink?: boolean;
  shouldShowAllEventsPage?: boolean;
  shouldShowChatThreadsTab?: boolean;
  shouldShowCoursesDirectory?: boolean;
  shouldShowLeaderboard?: boolean;
  shouldShowMemberDirectory?: boolean;
  shouldShowOnboarding?: boolean;
  shouldShowSpaceDirectory?: boolean;
  unreadThreadsCount?: number;
  links: NavigationLink[];
}

export const PlatformMenuView = ({
  allDMsBadgeText = "",
  disabled = false,
  hasCurrentCommunityMember = false,
  isHomePageEnabled = false,
  isMessagingEnabled = false,
  isOnboardingActive = false,
  notificationBadgeText = "",
  onGettingStartedClick,
  onGettingStartedClose,
  onNotificationClick,
  onSearchClick,
  resetNewNotificationsCount,
  shouldShowActiveHomeLink = false,
  shouldShowAllEventsPage = false,
  shouldShowChatThreadsTab = false,
  shouldShowCoursesDirectory = false,
  shouldShowLeaderboard = false,
  shouldShowMemberDirectory = false,
  shouldShowOnboarding = false,
  shouldShowSpaceDirectory = false,
  unreadThreadsCount,
  links,
}: PlatformMenuViewProps) => {
  const isLgScreenTw = useTailwindLgScreenMediaQuery();
  const { isV3Enabled } = useIsV3();

  return (
    <div className="mt-2.5 px-3">
      {!isLgScreenTw && (
        <PlatformMenuItem
          label={t("search_item")}
          onClick={() => isFunction(onSearchClick) && onSearchClick()}
          iconName="16-search"
        />
      )}
      {shouldShowOnboarding && (
        <PlatformMenuItem
          label={t("getting_started")}
          iconName="lightning"
          isActive={isOnboardingActive}
          onClick={() => {
            if (isFunction(onGettingStartedClick) && !disabled) {
              onGettingStartedClick();
            }
          }}
          rightSideContent={
            <button
              type="button"
              className="flex h-5 w-5 rounded py-0.5 transition-colors hover:bg-black/10 focus:bg-black/10"
              onClick={() => {
                if (isFunction(onGettingStartedClose) && !disabled) {
                  onGettingStartedClose();
                }
              }}
              aria-label={t("close")}
            >
              <Icon type="16-close" size={16} />
            </button>
          }
        />
      )}
      {!isV3Enabled && isHomePageEnabled && (
        <PlatformMenuItemLink
          label={t("home_page.title")}
          linkTo="/home"
          iconName="home-v2"
          disabled={disabled}
          isActive={shouldShowActiveHomeLink}
        />
      )}
      {hasCurrentCommunityMember && (
        <MemberLinks
          notificationBadgeText={notificationBadgeText}
          onNotificationClick={onNotificationClick}
          disabled={disabled}
          resetNewNotificationsCount={resetNewNotificationsCount}
          allDMsBadgeText={allDMsBadgeText}
          isMessagingEnabled={isMessagingEnabled}
          unreadThreadsCount={unreadThreadsCount}
          shouldShowChatThreadsTab={shouldShowChatThreadsTab}
        />
      )}
      {shouldShowSpaceDirectory && (
        <PlatformMenuItemLink
          label={t("sidebar_spaces")}
          linkTo="/spaces"
          iconName="all-spaces"
          disabled={disabled}
        />
      )}
      {!isV3Enabled && (
        <>
          {shouldShowMemberDirectory && (
            <PlatformMenuItemLink
              label={t("members_directory.menu.title")}
              linkTo={memberDirectoryPath()}
              iconName="members-v2"
              disabled={disabled}
            />
          )}
          {shouldShowAllEventsPage && (
            <PlatformMenuItemLink
              label={t("events.title")}
              linkTo="/events"
              iconName="16-calendar"
              disabled={disabled}
            />
          )}
          {shouldShowCoursesDirectory && (
            <PlatformMenuItemLink
              label={t("courses.directory.title")}
              linkTo="/courses"
              iconName="16-book"
              disabled={disabled}
              isGrayBadge
            />
          )}
          {shouldShowLeaderboard && (
            <PlatformMenuItemLink
              label={t("leaderboard.title")}
              linkTo="/leaderboard?period=7_days"
              iconName="16-analytics"
              disabled={disabled}
              isGrayBadge
            />
          )}
        </>
      )}
      {isV3Enabled &&
        links.map(link => (
          <PlatformMenuItemRenderer
            key={link.linkTo}
            link={link}
            disabled={disabled}
            isHomePageEnabled={isHomePageEnabled}
            shouldShowActiveHomeLink={shouldShowActiveHomeLink}
            shouldShowMemberDirectory={shouldShowMemberDirectory}
            shouldShowAllEventsPage={shouldShowAllEventsPage}
            shouldShowCoursesDirectory={shouldShowCoursesDirectory}
            shouldShowLeaderboard={shouldShowLeaderboard}
          />
        ))}
    </div>
  );
};
