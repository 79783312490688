import { useMemo } from "react";
import { useQuery } from "react-query";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useCommunityBotProfileSearch = ({
  name = "",
  disabled = false,
} = {}) => {
  const { currentCommunitySettings = {} } = usePunditUserContext();
  const { community_bot_enabled: isCommunityBotEnabled } =
    currentCommunitySettings;

  const fetchBotProfiles = () =>
    reactQueryGet(internalApi.communityInbox.botSearch({ params: { name } }));

  const isEnabled = Boolean(isCommunityBotEnabled) && !disabled;

  const { data, isLoading } = useQuery(
    ["community-inbox", "chat-bot", "profiles", name],
    fetchBotProfiles,
    {
      enabled: isEnabled,
    },
  );

  const botProfiles = useMemo(() => data?.records || [], [data?.records]);

  return {
    botProfiles,
    isBotsLoading: isLoading,
  };
};
