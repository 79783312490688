import { useEffect } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";

export const useUnPaginateQuery = ({
  api,
  key = "tags",
  useParamsWrapper = true,
}) => {
  const fetchPage = ({ pageParam = 1 }) =>
    reactQueryGet(
      api(
        useParamsWrapper
          ? { params: { page: pageParam } }
          : { page: pageParam },
      ),
    );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["un-paginate", key, api],
    fetchPage,
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data]);

  const records = flatten(data?.pages.map(page => page.records));

  return {
    records: records || [],
    isLoading: isLoading || hasNextPage,
    hasNextPage,
  };
};
