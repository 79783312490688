import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS } from "../../../constants";
import { ActionLabelWithUpgradeIcon } from "../../ActionRule/ActionLabelWithUpgradeIcon";

export const TitleSendToWebhook = () => {
  const { currentCommunity = {} } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;
  const isEligiblePlanTier =
    SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS.includes(currentPlanTier);

  return (
    <ActionLabelWithUpgradeIcon
      showUpgradeButton={!isEligiblePlanTier}
      label={t("settings.workflows.form.actions.send_to_webhook")}
    />
  );
};
