import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import type { HistoryDetailViewContactProps } from "./HistoryDetailViewContact";
import { HistoryDetailViewContact } from "./HistoryDetailViewContact";

export const unsubscribe: Readonly<
  ActionOption<HistoryDetailViewContactProps>
> = {
  label: t("settings.workflows.form.actions.unsubscribe"),
  value: "unsubscribe",
  group: t("settings.workflows.form.action_groups.communication"),
  leftAddon: <Icon type="16-unsubscribe" size={16} className="!h-4 !w-4" />,
  formComponent: null,
  viewComponent: null,
  historyDetailViewComponent: HistoryDetailViewContact,
  icon: "unsubscribe",
  isApplicableToMembersOnly: false,
} as const;
