import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { WORKFLOW_RESULT } from "@circle-react/components/SettingsApp/Workflows/constants";
import { StatusBadge } from "@circle-react-shared/uikit/StatusBadge";
import { StatusText } from "@circle-react-shared/uikit/StatusText";

const CAPITALIZE = "capitalize";

const RESULT_VARIANT_MAP = {
  [WORKFLOW_RESULT.SUCCESS]: "success",
  [WORKFLOW_RESULT.PARTIAL_SUCCESS]: "warning",
  [WORKFLOW_RESULT.FAILED]: "danger",
  [WORKFLOW_RESULT.IN_PROGRESS]: "info",
};

const RESULT_VARIANT_MAP_FOR_STATUS_TEXT = {
  [WORKFLOW_RESULT.SUCCESS]: "success",
  [WORKFLOW_RESULT.PARTIAL_SUCCESS]: "warning",
  [WORKFLOW_RESULT.FAILED]: "danger",
  [WORKFLOW_RESULT.CANCELLED]: "danger",
  [WORKFLOW_RESULT.IN_PROGRESS]: "info",
};

export const Result = ({ result, isBadge = true }) => {
  const isWorkflowProcessed = Object.values(WORKFLOW_RESULT).includes(result);
  const variant = isBadge
    ? RESULT_VARIANT_MAP[result]
    : RESULT_VARIANT_MAP_FOR_STATUS_TEXT[result];
  const Component = isBadge ? StatusBadge : StatusText;

  return (
    <Component
      variant={variant}
      {...(!isWorkflowProcessed && {
        textTransform: CAPITALIZE,
      })}
    >
      {t(["settings.workflows.table.result", result], {
        defaultValue: result || "─",
      })}
    </Component>
  );
};

Result.propTypes = {
  result: PropTypes.oneOf(Object.values(WORKFLOW_RESULT)),
  isBadge: PropTypes.bool,
};
