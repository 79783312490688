import { t } from "@/i18n-js/instance";
import { toLocaleString } from "@circle-react/helpers/number";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.workflows.view";

interface RecipientCountProps {
  recipientCount: number | null;
}

export const RecipientCount = ({ recipientCount }: RecipientCountProps) => {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();

  const TypographyComponent = isWorkflowsV2Enabled
    ? Typography.LabelXs
    : Typography.LabelSm;

  if (recipientCount === null) {
    return (
      <TypographyComponent weight="semibold" color="text-default">
        {t([localeNamespace, "no_recipients"])}
      </TypographyComponent>
    );
  }

  return (
    <>
      <TypographyComponent weight="semibold" color="text-default">
        {toLocaleString(recipientCount)}
      </TypographyComponent>
      <TypographyComponent weight="semibold" color="text-very-dark">
        {t([localeNamespace, "recipients"])}
      </TypographyComponent>
    </>
  );
};
