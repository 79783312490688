import { generateJSON } from "@tiptap/core";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { t } from "@/i18n-js/instance";

export const DEFAULT_EMAIL_BODY = generateJSON(
  t(
    "courses.edit.sections.notify_students_modal.form.email_notification_body_default_text",
  ).replace(/[{}]/g, match => `${match}${match}`),
  [Document, Paragraph, Text],
);
