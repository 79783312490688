import { useEffect, useRef } from "react";
import type { KeyboardEvent } from "react";
import { isFunction, noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useSearchResultsModal } from "@circle-react/components/Modals/SearchResultsModal";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";

interface Props {
  isFixed?: boolean;
  onChange: (value: string) => void;
  searchValue: string;
  onKeydown: (event: KeyboardEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  onClickCancel?: () => void;
  shouldShowClearButton?: boolean;
}

export const SearchInput = ({
  isFixed,
  onChange,
  searchValue,
  onKeydown,
  isLoading,
  onClickCancel = noop,
  shouldShowClearButton,
}: Props) => {
  const shouldRenderClearButton =
    shouldShowClearButton && searchValue.length > 0;
  const shouldRenderCancelButton =
    !shouldShowClearButton || searchValue.length === 0;
  const { visible: isSearchResultsModalVisible, hide } =
    useSearchResultsModal();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div
      className={classNames(
        "bg-primary isolate z-10 flex w-full items-center justify-between gap-4 rounded-t-md",
        {
          "border-primary sticky top-0 border-b px-4 py-4 md:static md:top-auto md:px-0 md:pl-6 md:pr-1":
            isFixed,
        },
      )}
    >
      <div className="bg-secondary sm:bg-primary mr-0 flex w-full flex-1 items-center gap-3 overflow-hidden rounded-md p-3 sm:rounded-none md:p-0">
        {isLoading ? (
          <Icon
            type="loader"
            className="text-default !h-5 !w-5"
            size={20}
            useWithFillCurrentColor
          />
        ) : (
          <Icon
            type="search-v2"
            className="text-default !h-5 !w-5"
            size={20}
            useWithFillCurrentColor
          />
        )}
        <input
          ref={inputRef}
          className="text-darkest sm:bg-primary placeholder:text-light w-full rounded bg-transparent text-base outline-none placeholder:opacity-100 sm:rounded-none"
          placeholder={t("search_here")}
          data-name="v2-search"
          data-testid="v2-search"
          aria-autocomplete="list"
          value={searchValue}
          onChange={e => onChange(e.target.value)}
          onKeyDown={onKeydown}
        />
      </div>
      {shouldRenderCancelButton && (
        <Button
          variant="text"
          className="inline-flex md:hidden"
          onClick={() => {
            isFunction(onClickCancel) && onClickCancel();
            isSearchResultsModalVisible && void hide();
          }}
        >
          {t("cancel")}
        </Button>
      )}
      {shouldRenderClearButton && (
        <Button variant="text" onClick={onClickCancel}>
          {t("search_v2.clear_search")}
        </Button>
      )}
    </div>
  );
};
