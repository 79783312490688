import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { internalApi } from "@/react/helpers/urlHelpers";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Show } from "../Show";
import { jumpToComment } from "../helpers";

export const CommentHierarchy = ({ post, ...rest }) => {
  if (!post?.is_comments_enabled) {
    return null;
  }

  return <CommentHierarchyFetch post={post} {...rest} />;
};

CommentHierarchy.propTypes = {
  post: PropTypes.object.isRequired,
};

const CommentHierarchyFetch = ({ post, id, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetch(
        internalApi.comments.commentHierarchy({
          postId: post.id,
          id: id,
        }),
      );
      if (response.ok) {
        const data = await response.json();
        setComment(data);
        setLoading(false);
        setTimeout(window.initialiseTippy, 300);
        jumpToComment(id);
      } else {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  if (loading) {
    return <LoaderRow />;
  }

  if (!comment) {
    return (
      <div className="post__comment-not-found alert alert-warning">
        {t("comments.hierarchy.not_found")}
      </div>
    );
  }

  return (
    <Show
      key={comment.id}
      comment={comment}
      defaultShowReplyForm
      post={post}
      id={id}
      {...rest}
    />
  );
};

CommentHierarchyFetch.propTypes = {
  post: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};
