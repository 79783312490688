import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { workflowsApi } from "@/react/api";
import {
  isMarketingHubRequired,
  isPlanCheckFailed,
} from "@/react/components/SettingsApp/Workflows/workflowApiErrorHelper";
import { nextPlanTierForWorkflowUpgrade } from "@/react/helpers/planHelpers";
import { getWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { usePunditUserContext } from "@circle-react/contexts";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { useToast } from "@circle-react-uikit/ToastV2";
import { WORKFLOW_STATUS } from "../../../constants";
import { useArchiveConfirmationActiveWorkflowModal } from "./ArchiveConfirmationActiveWorkflowModal";
import { useArchiveConfirmationModal } from "./ArchiveConfirmationModal";
import { useCancelConfirmationModal } from "./CancelConfirmationModal";
import { useDeleteConfirmationModal } from "./DeleteConfirmationModal";

const i18nRoot = "settings.workflows.table.actions";

interface UseActionsProps {
  workflowId: string;
  type?: string;
  status?: string;
  name?: string;
  refetch?: () => Promise<void>;
}

export const useActions = ({
  workflowId,
  type,
  status,
  name,
  refetch,
}: UseActionsProps) => {
  const history = useHistory();
  const { success, error } = useToast();
  const queryClient = useQueryClient();
  const queryParams = useRouterQueryParams();

  const deleteConfirmationModal = useDeleteConfirmationModal();
  const cancelConfirmationModal = useCancelConfirmationModal();

  const archiveConfirmationNonActiveWorkflowModal =
    useArchiveConfirmationModal();
  const archiveConfirmationActiveWorkflowModal =
    useArchiveConfirmationActiveWorkflowModal();

  const upgradePlanModal = useUpgradePlanModal();
  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;

  const archiveConfirmationModal =
    status === WORKFLOW_STATUS.ACTIVE
      ? archiveConfirmationActiveWorkflowModal
      : archiveConfirmationNonActiveWorkflowModal;

  const { mutate: onRun } = useMutation(
    () => workflowsApi.createRun({ workflowId }),
    {
      onSuccess: () => {
        success(t([i18nRoot, "run_in_progress"]), {
          messageComplete: t([i18nRoot, "run_success"]),
          onComplete: () =>
            history.push({
              pathname: workflowsSettingsPaths.history(),
              search: "workflow_type=bulk_action",
            }),
        });
      },
      onError: (err: any) => {
        if (isPlanCheckFailed(err) || isMarketingHubRequired(err)) {
          void showUpgradePlanModal(err);
        } else {
          error(err.message);
        }
      },
    },
  );

  const showUpgradePlanModal = (error: any) =>
    upgradePlanModal.show({
      ...(isPlanCheckFailed(error) && {
        planTier: nextPlanTierForWorkflowUpgrade(currentPlanTier),
      }),
      subheading: error.body.message,
      source: getWorkflowSource(),
      isMarketingHubRequired: isMarketingHubRequired(error),
      onSuccess: () => {
        void upgradePlanModal.hide();
        onRun();
      },
      successButtonLabel: t(
        "settings.workflows.upgrade_modal.run_this_workflow",
      ),
      usedForWorkflows: true,
      showPlanDetails: true,
    });

  const { mutate: onDelete, isLoading: isDeletingWorkflow } = useMutation(
    () => workflowsApi.deleteWorkflow({ id: workflowId }),
    {
      onSuccess: async () => {
        void deleteConfirmationModal.hide();
        success(t([i18nRoot, "deleting_workflow"]), {
          messageComplete: t([i18nRoot, "deletion_successful"]),
        });
        await refetch?.();
      },
      onError: (err: any) => error(err.message),
    },
  );

  const { mutate: onCancel, isLoading: isCancellingWorkflow } = useMutation(
    () => workflowsApi.inactivate({ id: workflowId }),
    {
      onSuccess: async () => {
        void cancelConfirmationModal.hide();
        success(t([i18nRoot, "cancel", "cancelling_schedule"]), {
          messageComplete: t([i18nRoot, "cancel", "cancellation_successful"]),
        });

        await refetch?.();
      },
      onError: (err: any) => error(err.message),
    },
  );

  const { mutate: onArchive, isLoading: isArchivingWorkflow } = useMutation(
    () => workflowsApi.archiveWorkflow({ id: workflowId }),
    {
      onSuccess: async () => {
        void archiveConfirmationModal.hide();
        success(t([i18nRoot, "archiving_workflow"]), {
          messageComplete: t([i18nRoot, "archive_successful"]),
          onComplete: () => {
            void queryClient.invalidateQueries({
              queryKey: ["totalArchivedWorkflows"],
            });
          },
        });
        await refetch?.();
      },
      onError: (err: any) => error(err.message),
    },
  );

  const handleDeleteWithConfirmation = () => {
    void deleteConfirmationModal.show({
      disabled: isDeletingWorkflow,
      onConfirm: onDelete,
    });
  };

  const handleArchiveWithConfirmation = () => {
    void archiveConfirmationModal.show({
      name,
      disabled: isArchivingWorkflow,
      onConfirm: onArchive,
    });
  };

  const handleCancelWithConfirmation = () => {
    void cancelConfirmationModal.show({
      name,
      disabled: isCancellingWorkflow,
      onConfirm: onCancel,
    });
  };

  const { page, status: statusFilter } = queryParams;
  const { mutate: onDuplicate } = useMutation(
    () => workflowsApi.duplicateWorkflow({ id: workflowId }),
    {
      onSuccess: async () => {
        success(t([i18nRoot, "duplicating_workflow"]), {
          messageComplete: t([i18nRoot, "duplication_successful"]),
        });
        if (statusFilter === WORKFLOW_STATUS.ARCHIVED || Number(page) !== 1) {
          history.push({
            pathname: workflowsSettingsPaths.index(),
            search: `workflow_type=${type}&page=1`,
          });
        } else {
          await refetch?.();
        }
      },
      onError: (err: any) => error(err.message),
    },
  );

  return {
    onDelete,
    onDuplicate,
    handleDeleteWithConfirmation,
    handleArchiveWithConfirmation,
    handleCancelWithConfirmation,
  };
};
