import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import {
  stepKeys,
  useOnboarding,
} from "@/react/components/CommunityOnboarding/useOnboarding";
import { communityApi } from "@circle-react/api";
import { usePunditUserContext } from "@circle-react/contexts";
import { Button } from "@circle-react-uikit/Button";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Drawer } from "@circle-react-uikit/Drawer";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ColorPickerField } from "./ColorPickerField";
import { FormItem } from "./FormItem";
import "./styles.scss";

export const BrandingEditForm = () => {
  const { currentCommunity, isLoading } = usePunditUserContext();
  const { updateStepIfIncomplete } = useOnboarding();

  const { success, error } = useToast();
  const mutation = useMutation(
    formData => communityApi.update({ community: formData }),
    {
      onSuccess: async () => {
        success(I18n.t("settings.branding_form.saved_successfully"));
        await updateStepIfIncomplete(stepKeys.customize_branding);
        window.location.reload();
      },
      onError: err => {
        error(err.message);
      },
    },
  );

  if (isLoading || isEmpty(currentCommunity)) {
    return <Loader center />;
  }

  const defaultFormValues = {
    prefs: {
      brand_color: currentCommunity.prefs.brand_color,
    },
    logo: currentCommunity.logo,
    icon: currentCommunity.icon,
  };

  return (
    <Form
      onSubmit={mutation.mutate}
      defaultValues={defaultFormValues}
      className="branding-drawer__form"
    >
      <div className="branding-drawer__container">
        <ColorPickerField name="prefs.brand_color.light" />
        <FormItem name="logo" variant="extra-bottom-margin">
          <div className="branding-cover-image branding-cover-image__logo">
            <CoverImageInput
              alt=""
              aspectRatio={4}
              changeImageText={t("replace")}
              customAddCoverBtn={
                <CustomAddCoverBtn
                  label={t("settings.branding_form.upload_logo")}
                />
              }
              hideEmbedUrl
              hideUnsplash
              isCropEnabled={false}
              name="logo"
              showAddCoverBtn
              title={t("customize_theme.logo_tab.upload_community_logo")}
            />
          </div>
        </FormItem>
        <FormItem name="icon" variant="extra-bottom-margin">
          <div className="branding-cover-image branding-cover-image__icon">
            <CoverImageInput
              alt=""
              aspectRatio={1}
              changeImageText={t("replace")}
              customAddCoverBtn={
                <CustomAddCoverBtn
                  label={t("settings.branding_form.upload_icon")}
                />
              }
              hideEmbedUrl
              hideUnsplash
              name="icon"
              showAddCoverBtn
              title={t("customize_theme.logo_tab.upload_community_icon")}
            />
          </div>
        </FormItem>
      </div>
      <Drawer.Footer sticky>
        <Button
          type="submit"
          variant="primary"
          disabled={mutation.isLoading}
          full
        >
          {mutation.isLoading ? I18n.t("saving") : I18n.t("save")}
        </Button>
      </Drawer.Footer>
    </Form>
  );
};

const CustomAddCoverBtn = ({ label }) => (
  <Button type="button" variant="secondary" full>
    {label}
  </Button>
);

CustomAddCoverBtn.propTypes = {
  label: PropTypes.string.isRequired,
};
