import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { ACTION_TYPE_TO_ICON } from "@circle-react-shared/WorkflowsV2/ActionNode/constants";
import { getActionIcon } from "../../Actions";

const i18nRoot = "settings.workflows";

export const useActionGroup = ({ actions: actionsProp, truncationLimit }) => {
  const titlePrefix = `${t([i18nRoot, "action"])}: `;

  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();

  const generateActionIconFromActionValue = actionType =>
    ACTION_TYPE_TO_ICON[actionType];

  const mappedActions = useMemo(
    () =>
      actionsProp
        .filter(actionType => actionType !== "")
        .map(actionType => {
          const icon = isWorkflowsV2Enabled
            ? generateActionIconFromActionValue(actionType)
            : getActionIcon(actionType);
          if (isWorkflowsV2Enabled) {
            return {
              title: `${t([i18nRoot, "action_types", actionType], {
                defaultValue: t([i18nRoot, "action_types", "unknown"]),
              })}`,
              icon,
            };
          }

          return {
            title: `${titlePrefix}${t([i18nRoot, "action_types", actionType], {
              defaultValue: t([i18nRoot, "action_types", "unknown"]),
            })}`,
            icon,
          };
        }),
    [actionsProp, titlePrefix, isWorkflowsV2Enabled],
  );

  const remainingItemsTooltip = useMemo(
    () =>
      actionsProp
        .slice(truncationLimit)
        .map(actionType =>
          isWorkflowsV2Enabled
            ? `${t([i18nRoot, "action_types", actionType], {
                defaultValue: t([i18nRoot, "action_types", "unknown"]),
              })}`
            : `${titlePrefix}${t([i18nRoot, "action_types", actionType], {
                defaultValue: t([i18nRoot, "action_types", "unknown"]),
              })}`,
        )
        .join(", "),
    [actionsProp, truncationLimit, titlePrefix, isWorkflowsV2Enabled],
  );

  const remainingActionsCount = actionsProp.length - truncationLimit;

  const actions =
    remainingActionsCount > 1
      ? mappedActions.slice(0, truncationLimit)
      : mappedActions;

  return {
    actions,
    remainingActionsCount,
    remainingItemsTooltip,
  };
};
