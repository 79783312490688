import { t } from "@/i18n-js/instance";
import { useIsMarketingHubStatusEnabled } from "@circle-react/hooks/useIsMarketingHubStatus";
import { ActionLabelWithUpgradeIcon } from "../../../ActionRule/ActionLabelWithUpgradeIcon";

export const TitleSendEmail = () => {
  const isMarketingHubStatusEnabled = useIsMarketingHubStatusEnabled();

  return (
    <ActionLabelWithUpgradeIcon
      showUpgradeButton={!isMarketingHubStatusEnabled}
      label={t("settings.workflows.form.actions.send_email")}
    />
  );
};
