import { useEffect, useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { useHomePageApi } from "../../../hooks/home_page/useHomePageApi";
import { ButtonBasedPagination } from "../../shared/ButtonBasedPagination/index";
import { SpaceCard } from "../../shared/SpaceCard/index";
import { NoSpaces } from "./NoSpaces";

export const SuggestedSpacesSection = () => {
  const {
    spaces = [],
    fetchSpaceSuggestions,
    participatingSpacesIds,
    spacesHasNextPage: hasNextPage,
    setParticipatingSpacesIds,
  } = useHomePageApi();

  const { size: participatingSize = 0 } = participatingSpacesIds || {};
  const spacesLength = spaces?.length || 0;

  useEffect(() => {
    fetchSpaceSuggestions({ page: 1 });
  }, []);

  const handleJoinSpace = spaceId => {
    const updatedParticipatingSpacesIds = new Set(participatingSpacesIds);
    updatedParticipatingSpacesIds.add(spaceId);
    setParticipatingSpacesIds(updatedParticipatingSpacesIds);
  };

  const remainingSpaces = useMemo(
    () => Math.max(3 - participatingSize, 0),
    [participatingSize],
  );

  const shouldShowSuggestedSpaces = useMemo(
    () => spacesLength >= remainingSpaces,
    [spacesLength, remainingSpaces],
  );

  if (!shouldShowSuggestedSpaces) {
    return <NoSpaces />;
  }

  return (
    <div className="suggested-spaces">
      <div className="suggested-spaces__header">
        <div className="header__left">
          <div className="header__title">
            {remainingSpaces > 0 ? (
              <h2>
                {t("home_page.suggested_spaces.join_count_spaces", {
                  count: remainingSpaces,
                })}
              </h2>
            ) : (
              <h2>{t("home_page.suggested_spaces.you_are_ready")}</h2>
            )}
          </div>
          <div className="header__subtitle">
            {remainingSpaces > 0
              ? t("home_page.suggested_spaces.to_get_started")
              : t("home_page.suggested_spaces.click_continue")}
          </div>
        </div>
        <div className="header__right">
          <button
            type="button"
            className="btn btn-primary"
            disabled={participatingSpacesIds.size < 3}
            onClick={() => window.location.reload()}
          >
            {t("home_page.suggested_spaces.continue")}
          </button>
        </div>
      </div>
      <div className="suggested-spaces__listing">
        {spaces.map(space => (
          <SpaceCard
            key={space.id}
            id={space.id}
            url={space.url}
            emoji={space.emoji}
            customEmojiUrl={space.custom_emoji_url}
            customEmojiDarkUrl={space.custom_emoji_dark_url}
            name={space.name}
            totalMembers={space.total_members}
            handleJoinSpace={handleJoinSpace}
          />
        ))}
      </div>
      {hasNextPage && (
        <ButtonBasedPagination
          fetchNewPage={page => fetchSpaceSuggestions({ page: page })}
          initialPage={2}
        />
      )}
    </div>
  );
};
