import { t } from "@/i18n-js/instance";
import setupAgentsImage from "@circle-assets/images/setup-agents.png";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useCreateAgent } from "./agentsQueries";

export const NoAgents = () => {
  const createAgentMutation = useCreateAgent();

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-9 p-8 text-center"
      data-testid="empty-state"
    >
      <img
        width={287}
        height={415}
        src={setupAgentsImage}
        alt=""
        loading="eager"
      />
      <div className="mt-[-160px] flex flex-col items-center justify-center gap-5">
        <div className="flex max-w-lg flex-col gap-3">
          <Typography.TitleMd>
            {t("settings.community_bot.empty.header")}
          </Typography.TitleMd>
          <Typography.BodyMd>
            {t("settings.community_bot.empty.description")}
          </Typography.BodyMd>
        </div>
        <div className="flex gap-3">
          <Button
            type="button"
            variant="circle"
            large
            onClick={() => createAgentMutation.mutate()}
          >
            {t("settings.community_bot.empty.primary_label")}
          </Button>
        </div>
      </div>
    </div>
  );
};
