import { ALL_SEGMENTS_QUERY_KEY_PREFIX } from "@circle-react/components/SettingsApp/Segments/hooks/useSegmentsInfiniteQuery";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useUnPaginateQuery } from "@circle-react/hooks/reactQuery/useUnPaginateQuery";

export const useSegments = () => {
  const { records: segments = [], isLoading } = useUnPaginateQuery({
    api: internalApi.segments.index,
    key: ALL_SEGMENTS_QUERY_KEY_PREFIX,
  });

  return { segments, isLoading };
};
