import { useCallback, useMemo } from "react";
import isFunction from "lodash/isFunction";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { communityApi } from "@/react/api";
import { useEditBrandingDrawer } from "@/react/components/Drawers/BrandingDrawer";
import { usePaywalls } from "@/react/components/PaywallCoupons/Admin/FormFields/usePaywalls";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { usePunditUserContext } from "@circle-react/contexts";
import { memberProfileModalPathsWithState } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

const REQUEST_KEY = "onboarding";

export const stepKeys = Object.freeze({
  basic_group: "basic_group",
  setup_paywall_group: "setup_paywall_group",
  invite_member_group: "invite_member_group",
  engage_community_group: "engage_community_group",
  join_circle_community_group: "join_circle_community_group",
  profile_completed: "profile_completed",
  customize_branding: "customize_branding",
  setup_spaces_group: "setup_spaces_group",
  browse_examples: "browse_examples",
  update_notifications: "update_notifications",
  default_space: "default_space",
  confirm_community_access: "confirm_community_access",
  create_paywall: "create_paywall",
  customize_onboarding: "customize_onboarding",
  invite_first_member: "invite_first_member",
  onboarding_completed: "onboarding_completed",
  download_app: "download_app",
  customize_home: "customize_home",
  create_event: "create_event",
  first_week_action: "first_week_action",
  start_conversation: "start_conversation",
  engagement_means: "engagement_means",
  paywall_analytics_info: "paywall_analytics_info",
});

const allowedStepKeys = new Set([
  stepKeys.customize_branding,
  stepKeys.profile_completed,
]);

export const shouldSkipMutationForOnboardingKey = (onboardingKey: any) =>
  allowedStepKeys.has(onboardingKey);

export const stepKeysToOnboardingWidget: any = Object.freeze({
  basic_group: {
    label: t("community_onboarding.basics"),
    queryParam: "basic_group",
  },
  setup_paywall_group: {
    label: t("community_onboarding.setup_a_paywall"),
    queryParam: "setup_paywall_group",
  },
  invite_member_group: {
    label: t("community_onboarding.invite_your_first_members"),
    queryParam: "invite_member_group",
  },
});

const onboardingKeyGroups: any = Object.freeze({
  [stepKeys.basic_group]: [
    stepKeys.profile_completed,
    stepKeys.customize_branding,
    stepKeys.confirm_community_access,
  ],
  [stepKeys.setup_spaces_group]: [
    stepKeys.browse_examples,
    stepKeys.update_notifications,
    stepKeys.default_space,
  ],
  [stepKeys.setup_paywall_group]: [stepKeys.create_paywall],
  [stepKeys.invite_member_group]: [
    stepKeys.customize_onboarding,
    stepKeys.invite_first_member,
  ],
  [stepKeys.engage_community_group]: [
    stepKeys.download_app,
    stepKeys.customize_home,
    stepKeys.create_event,
    stepKeys.first_week_action,
    stepKeys.start_conversation,
    stepKeys.engagement_means,
  ],
  join_circle_community_group: [stepKeys.join_circle_community_group],
});

export const stepKeysToActionsMap: any = Object.freeze({
  [stepKeys.engage_community_group]: null,
  [stepKeys.join_circle_community_group]: ({ isCommunityOnTrial }: any) =>
    (window.location.href = isCommunityOnTrial
      ? "/settings/plans"
      : "https://community.circle.so/home"),
  [stepKeys.profile_completed]: ({
    openEditProfile,
  }: {
    openEditProfile: () => Promise<void>;
  }) => openEditProfile && openEditProfile(),
  [stepKeys.customize_branding]: ({ openCustomiseBrandingDrawer }: any) =>
    openCustomiseBrandingDrawer && openCustomiseBrandingDrawer(),
  [stepKeys.setup_spaces_group]: null,
  [stepKeys.browse_examples]: null,
  [stepKeys.default_space]: null,
  [stepKeys.update_notifications]: null,
  [stepKeys.confirm_community_access]: null,
  [stepKeys.create_paywall]: () =>
    (window.location.href = "/settings/paywalls"),
  [stepKeys.customize_onboarding]: () =>
    (window.location.href = "/settings/onboarding"),
  [stepKeys.invite_first_member]: null,
  [stepKeys.download_app]: null,
  [stepKeys.customize_home]: null,
  [stepKeys.create_event]: null,
  [stepKeys.first_week_action]: null,
  [stepKeys.start_conversation]: null,
  [stepKeys.engagement_means]: null,
});

const keyToBool = ({ key }: any) => !!key;

export const stepKeysIsCompletedMap: any = Object.freeze({
  [stepKeys.basic_group]: keyToBool,
  [stepKeys.setup_paywall_group]: ({ hasPaywalls }: any) => hasPaywalls,
  [stepKeys.invite_member_group]: keyToBool,
  [stepKeys.engage_community_group]: keyToBool,
  [stepKeys.join_circle_community_group]: ({ isCommunityOnTrial }: any) =>
    !isCommunityOnTrial,
  [stepKeys.profile_completed]: keyToBool,
  [stepKeys.customize_branding]: keyToBool,
  [stepKeys.setup_spaces_group]: keyToBool,
  [stepKeys.browse_examples]: keyToBool,
  [stepKeys.update_notifications]: keyToBool,
  [stepKeys.default_space]: keyToBool,
  [stepKeys.confirm_community_access]: keyToBool,
  [stepKeys.create_paywall]: ({ hasPaywalls }: any) => hasPaywalls,
  [stepKeys.customize_onboarding]: keyToBool,
  [stepKeys.download_app]: keyToBool,
  [stepKeys.customize_home]: keyToBool,
  [stepKeys.create_event]: keyToBool,
  [stepKeys.first_week_action]: keyToBool,
  [stepKeys.start_conversation]: keyToBool,
  [stepKeys.engagement_means]: keyToBool,
  [stepKeys.invite_first_member]: keyToBool,
  [stepKeys.onboarding_completed]: keyToBool,
});

const getGroupKeyForStep = (stepKey: any) => {
  for (const groupKey of Object.keys(onboardingKeyGroups)) {
    if (onboardingKeyGroups[groupKey].includes(stepKey)) {
      return groupKey;
    }
  }
  return undefined;
};

export const useOnboarding = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { hasPaywalls } = usePaywalls();
  const { success, error } = useToast();
  const { isCommunityOnTrial } = usePlanStatus();
  const customiseBrandingDrawer = useEditBrandingDrawer();
  const history = useHistory();
  const location = useLocation();

  const queryResponse = useQuery(REQUEST_KEY, communityApi.getOnboarding, {
    notifyOnChangeProps: "tracked",
    retry: 2,
    enabled: !!isCommunityAdmin(currentCommunityMember),
  });
  const { data, refetch, isLoading } = queryResponse;

  const { mutateAsync: updateStepMutation } = useMutation(({ step }: any) =>
    communityApi.completeOnboardingStep({ onboarding: { [step]: true } }),
  );

  const isGroupComplete = useCallback(
    (groupKey, onboardingData = data) =>
      onboardingKeyGroups[groupKey].reduce((prev: any, curr: any) => {
        if (!onboardingData[curr]) {
          return false;
        }
        return prev;
      }, true),
    [data],
  );

  const stepActionParams = useMemo(
    () => ({
      isCommunityOnTrial,
      openCustomiseBrandingDrawer: customiseBrandingDrawer.show,
      openEditProfile: async () => {
        history.push(memberProfileModalPathsWithState.profile(location));
        await updateStepMutation({ step: stepKeys.profile_completed });
      },
    }),
    [
      customiseBrandingDrawer.show,
      history,
      isCommunityOnTrial,
      location,
      updateStepMutation,
    ],
  );

  const updateStep = useCallback(
    async ({ step, skipMutation = false }) => {
      const actionFunction = stepKeysToActionsMap[step];

      if (skipMutation) {
        if (isFunction(actionFunction)) {
          actionFunction(stepActionParams);
        }
      } else {
        let shouldRefetchRequest = true;
        try {
          const groupKey = getGroupKeyForStep(step);
          const updatedOnboardingData = await updateStepMutation({ step });
          if (isGroupComplete(groupKey, updatedOnboardingData)) {
            await updateStepMutation({ step: groupKey });
          }
          if (isFunction(actionFunction)) {
            shouldRefetchRequest = false;
            actionFunction(stepActionParams);
          }
          return updatedOnboardingData;
        } catch (e: any) {
          error(e.message);
        } finally {
          shouldRefetchRequest && void refetch();
        }
      }
    },
    [error, isGroupComplete, refetch, stepActionParams, updateStepMutation],
  );

  const completeWholeOnboarding = useCallback(
    () =>
      updateStepMutation(
        { step: stepKeys.onboarding_completed },
        {
          onSuccess: () => {
            success(t("onboarding_hide_success"));
          },
        },
      ),
    [updateStepMutation, success],
  );

  const isStepCompleted = useCallback(
    stepKey =>
      data
        ? stepKeysIsCompletedMap[stepKey]({
            key: data[stepKey],
            hasPaywalls,
            isCommunityOnTrial,
          })
        : false,
    [data, hasPaywalls, isCommunityOnTrial],
  );

  const isOnboardingAvailable = useMemo(
    () => (data ? !data[stepKeys.onboarding_completed] : false),
    [data],
  );

  const updateStepIfIncomplete: (onboardingKey: string) => Promise<void> =
    useCallback(
      async (onboardingKey: any) => {
        if (!isStepCompleted(onboardingKey) && !isLoading) {
          await updateStep({
            step: onboardingKey,
          });
        }
      },
      [isLoading, isStepCompleted, updateStep],
    );

  return {
    onboardingData: data,
    updateStep,
    isLoading,
    refetchOnboarding: refetch,
    isOnboardingAvailable,
    completeWholeOnboarding,
    isStepCompleted,
    updateStepIfIncomplete,
  };
};
