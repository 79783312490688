import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import {
  isChatSpace,
  isCourseSpace,
  isMembersSpace,
} from "@/react/helpers/spaceHelpers";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { useCustomizeUIData } from "@circle-react/components/shared/CustomizeUI";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { Form } from "@circle-react-uikit/Form";
import { ImageInput } from "@circle-react-uikit/ImageInput";

export const SpaceImagesForm = ({ space, onChange }) => {
  const { isV3Enabled } = useIsV3();
  const { setIsSaveButtonDisabled } = useCustomizeUIData();
  const { currentCommunitySettings } = usePunditUserContext();
  const { space_directory_enabled: spaceDirectoryEnabled } =
    currentCommunitySettings || {};
  const {
    formState: { dirtyFields },
    watch,
    setValue,
  } = useFormContext();
  const coverImageValue = watch("cover_image");
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();

  useEffect(() => {
    if (coverImageValue) setValue("cover_image_visible", true);
    else setValue("cover_image_visible", false);
  }, [coverImageValue, setValue]);

  const showThumbnailImage = () => {
    if (isChatSpace(space) || isMembersSpace(space)) {
      return spaceDirectoryEnabled;
    }

    return true;
  };
  const shouldShouldCoverImage = !(
    isChatSpace(space) ||
    isCourseSpace(space) ||
    isMembersSpace(space)
  );

  const getThumbnailDescription = () => {
    if (isCourseSpace(space)) {
      return t("spaces.form.edit.customize.course_thumbnail_image_description");
    }
    return t("spaces.form.edit.customize.thumbnail_image_description");
  };

  useEffect(() => {
    if (
      "cover_image_visible" in dirtyFields ||
      "thumbnail_image" in dirtyFields
    ) {
      onChange(true);
    }
  }, [dirtyFields.cover_image_visible, dirtyFields.thumbnail_image]);

  return (
    <div>
      {shouldShouldCoverImage && (
        <Form.Item
          inline={!isMobileWebNewSpaceCustomizationUIEnabled}
          hideBorder
          label={t("spaces.form.edit.customize.cover_image")}
          name="cover_image"
          description={
            isV3Enabled
              ? t("spaces.form.edit.customize.cover_image_description_v3")
              : t("spaces.form.edit.customize.cover_image_description")
          }
          className="gap-x-6"
        >
          <ImageInput
            name="cover_image"
            aspectRatio={isV3Enabled ? ASPECT_RATIO.COVER_V3 : 43 / 9}
            onProcessing={processing => setIsSaveButtonDisabled(!!processing)}
          />
        </Form.Item>
      )}
      {showThumbnailImage() && (
        <Form.Item
          inline={!isMobileWebNewSpaceCustomizationUIEnabled}
          hideBorder
          label={t("spaces.form.edit.customize.thumbnail_image")}
          name="thumbnail_image"
          description={getThumbnailDescription()}
          className="gap-x-6"
        >
          <ImageInput
            name="thumbnail_image"
            aspectRatio={16 / 9}
            onProcessing={processing => setIsSaveButtonDisabled(!!processing)}
          />
        </Form.Item>
      )}
    </div>
  );
};

SpaceImagesForm.propTypes = {
  onChange: PropTypes.func,
  space: PropTypes.object,
};
