import { t } from "@/i18n-js/instance";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { usePaywallBlockContext } from "../../../../Providers/PaywallBlockProvider";
import { TemplateEmptyState } from "../TemplateEmptyState";

const i18nRoot =
  "paywalls.lock_screen.sidebar.paywall_edit_form.branded_app_empty_state";

const defaultOnClick = () => {
  window.open(settingsNavPath.plans.brandedApps, "_blank");
};

export const BrandedAppEmptyState = ({ children }: any) => {
  const { shouldShowBrandedAppBanner, isCommunityOnPlus } =
    usePaywallBlockContext();

  if (shouldShowBrandedAppBanner && !isCommunityOnPlus) {
    return (
      <TemplateEmptyState
        subtitle={t([i18nRoot, "community_not_on_plus_plan", "title"])}
        buttonText={t("learn_more")}
        onClickButton={defaultOnClick}
      />
    );
  }

  if (shouldShowBrandedAppBanner && isCommunityOnPlus) {
    return (
      <TemplateEmptyState
        subtitle={t([i18nRoot, "community_on_plus_plan", "title"])}
        buttonText={t("sign_up")}
      />
    );
  }

  return <>{children}</>;
};
