import { classNames } from "@circle-react/helpers/twMergeWithCN";

export const BannerContent = ({ title, description }: any) => (
  <div
    className={classNames("welcome-banner__content-wrapper", {
      "has-title": title.length > 0,
    })}
  >
    {title && (
      <h1 className="welcome-banner__title" data-testid="welcome-banner-title">
        {title}
      </h1>
    )}
    {description && (
      <div className="welcome-banner__content">
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    )}
  </div>
);
