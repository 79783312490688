import { useMemo } from "react";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { IconType } from "@circle-react-shared/Icon";

const adminIconsV2: Record<string, IconType> = {
  membersIcon: "members-v2",
  postsIcon: "16-text-square",
  marketingHubIcon: "16-send",
  spacesCrudIcon: "all-spaces",
  moderationIcon: "settings-moderation",
  liveStreamIcon: "live-stream-settings",
  workflowsIcon: "lightning",
  paywallsIcon: "16-paywalls",
  plansIcon: "16-plans",
  analyticsIcon: "16-analytics",
  affiliatesIcon: "16-paywalls-affiliates",
  settingsIcon: "16-settings",
  customizeThemeIcon: "16-palette",
  toggleThemeLightIcon: "16-moon",
  toggleThemeDarkIcon: "16-sun",
  viewAsIcon: "16-eye-open",
  inviteMemberIcon: "16-invite-member",
  keyboardShortcutsIcon: "16-keyboard-shortcuts",
};

const adminIconsV3: Record<string, IconType> = {
  membersIcon: "20-users-v3",
  postsIcon: "20-doc-v3",
  marketingHubIcon: "16-send", // TODO: replace when design is ready
  spacesCrudIcon: "20-spaces-v3",
  moderationIcon: "20-shield-v3",
  liveStreamIcon: "20-live-v3",
  workflowsIcon: "20-flash-v3",
  aiAgentsIcon: "20-community-bot",
  paywallsIcon: "20-currency-v3",
  plansIcon: "20-card-v3",
  analyticsIcon: "20-analytics-v3",
  affiliatesIcon: "20-affiliates-v3",
  settingsIcon: "20-settings-v3",
  toggleThemeIcon: "20-moon-v3",
  toggleThemeLightIcon: "20-moon-v3",
  toggleThemeDarkIcon: "20-moon-v3", // TODO: replace when design is ready
  customizeThemeIcon: "20-color-palette-v3",
  viewAsIcon: "20-open-eye-v3",
  inviteMemberIcon: "20-user-plus-v3",
  keyboardShortcutsIcon: "20-keyboard-shortcuts",
};

export const useAdminIcons = () => {
  const { isV3Enabled } = useIsV3();

  return useMemo(
    () => (isV3Enabled ? adminIconsV3 : adminIconsV2),
    [isV3Enabled],
  );
};
