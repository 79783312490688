import { Form } from "@circle-react-uikit/Form";
import { Body } from "./Body";
import { Footer } from "./Footer";

export const Content = ({
  defaultValues,
  onSubmit,
  actionLabel,
}: {
  defaultValues: any;
  onSubmit: (data: any) => void;
  actionLabel: string;
}) => (
  <Form
    defaultValues={defaultValues}
    mode="all"
    className="h-[calc(100%-62px)]"
    formClassName="h-full flex flex-col"
    onSubmit={onSubmit}
  >
    <Body />
    <Footer label={actionLabel} />
  </Form>
);
