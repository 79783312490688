import { Elements } from "@stripe/react-stripe-js";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useStripeElement } from "@circle-react/hooks/paywalls/useStripeElement";
import type { CommunityMember } from "@circle-react/types";
import { Modal } from "@circle-react-uikit/ModalV2";
import { stripeElementsOptions } from "../../StripeElements/stripeElementsOptions";
import { BillingInfoFormWrapper } from "./BillingInfoFormWrapper";

export interface BillingInfoModalProps {
  communityMember: CommunityMember;
  onSuccess: () => Promise<void> | void;
  asPrerequisite?: boolean;
  localeRoot?: string;
}

const BillingInfoModal = ({
  communityMember,
  onSuccess,
  asPrerequisite,
  localeRoot = "paywalls.community_members.billing_info_form",
}: BillingInfoModalProps) => {
  const modal = useModal();

  const onModalClose = () => {
    void modal.hide();
    modal.remove();
  };
  const onModalSuccess = () => {
    void onSuccess();
    void modal.hide();
    modal.remove();
  };
  const { stripePromise } = useStripeElement();

  return (
    <Modal
      title={t([localeRoot, "title"])}
      isOpen={modal.visible}
      onClose={onModalClose}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header>
          <Modal.Title>{t([localeRoot, "title"])}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onModalClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Elements stripe={stripePromise} options={stripeElementsOptions}>
          <BillingInfoFormWrapper
            communityMember={communityMember}
            onSuccess={onModalSuccess}
            asPrerequisite={asPrerequisite}
          />
        </Elements>
      </Modal.Content>
    </Modal>
  );
};

const BillingInfoModalDrawer = ModalManager.create<BillingInfoModalProps>(
  props => <BillingInfoModal {...props} />,
);

export const useBillingInfoModal = () => useModal(BillingInfoModalDrawer);

export type UseWithBillingInfoModal = (
  drawerComponentKey: Parameters<typeof useModal>[0],
  resolveCommunityMember: (props: any) => CommunityMember,
) => ReturnType<typeof useModal>;

export const useWithBillingInfoModal: UseWithBillingInfoModal = (
  drawerComponentKey,
  resolveCommunityMember,
) => {
  /**
   * Members with active subscriptions that predates the tax collection feature will not have a
   * billing info. We need to prompt them to add one before they can change their plan, billing
   * cycle, or add/edit their payment method.
   */
  const modal = useModal(drawerComponentKey);

  const billingInfoModal = useBillingInfoModal();
  return {
    ...modal,
    show: (props: any) => {
      const communityMember = resolveCommunityMember(props);
      if (!communityMember?.policies?.requires_billing_info) {
        return modal.show(props);
      }
      return billingInfoModal.show({
        communityMember,
        onSuccess: async () => {
          await modal.show(props);
        },
        asPrerequisite: true,
      });
    },
  };
};
