import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import { useSendTestEmail } from "@circle-react/components/SettingsApp/Emails/hooks/useSendTestEmail";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { BroadcastTipTapReadonlyView } from "@circle-react-shared/Emails/EmailPreview/BroadcastTipTapReadonlyView";
import { Button } from "@circle-react-shared/uikit/Button";
import { SkeletonLoader } from "@circle-react-shared/uikit/SkeletonLoader";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useActions, useCurrentAction } from "../../../ActionRule";
import { getSendEmailPath } from "../../../BroadcastContentEditorRouteWrapper";
import { useResourceStatus } from "../../../WorkflowForm/useResourceStatus";
import { useUpsertBroadcast } from "./useUpsertBroadcast";

const localeNamespace = "settings.workflows.form";

interface EmailContentFieldProps {
  lastUpdatedAt?: string;
}

export function EmailContentField({ lastUpdatedAt }: EmailContentFieldProps) {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
  const { watch, setValue } = useFormContext();
  const { paramsPath } = useCurrentAction();
  const history = useHistory();
  const { handleTestEmail, isSending } = useSendTestEmail();

  const formattedLastUpdatedAt = lastUpdatedAt
    ? `${formatDateString(lastUpdatedAt, { day: "2-digit", month: "short", year: "numeric" })} ${t("workflows_v2.action_selector.email.at")} ${formatDateString(lastUpdatedAt, { hour: "2-digit", minute: "2-digit", hour12: true })}`
    : undefined;

  const emailContent = watch(`${paramsPath}.email_content`);
  const existingBroadcastId = watch(`${paramsPath}.broadcast_id`);

  const { onUpsert } = useUpsertBroadcast(
    existingBroadcastId ? Number(existingBroadcastId) : null,
  );

  const { doesActionhasServerId, saveAction } = useActions();
  const { action, path } = useCurrentAction();
  const { value } = useResourceStatus(path);
  const { url: routeUrl } = useRouteMatch();

  useEffect(() => {
    const createBroadcast = async () => {
      const isDuplicatedWorkflowSendEmailAction =
        doesActionhasServerId(action) && emailContent && !existingBroadcastId;

      if (isDuplicatedWorkflowSendEmailAction) {
        const data = await onUpsert();
        const broadcastId = data?.id;
        invariant(
          broadcastId,
          "Broadcast ID is required to start writing email",
        );
        const currentAction = isWorkflowsV2Enabled ? value : action;
        setValue(`${paramsPath}.broadcast_id`, broadcastId);
        await saveAction({ path, action: currentAction, skipOpenNext: true });
      }
    };

    void createBroadcast();
  }, []);

  const handleStartWriting = async () => {
    const data = await onUpsert();
    const broadcastId = data?.id;
    invariant(broadcastId, "Broadcast ID is required to start writing email");
    setValue(`${paramsPath}.broadcast_id`, broadcastId);
    history.push(
      getSendEmailPath({
        // Remove trailing slash if it exists in routeUrl
        path: routeUrl.replace(/\/$/, ""),
        broadcastId,
      }),
    );
  };

  if (isWorkflowsV2Enabled) {
    return (
      <div className="w-full space-y-4">
        <div className="flex justify-between">
          <div className="flex flex-col gap-y-1">
            <Typography.LabelSm weight="semibold" color="text-very-dark">
              {t([localeNamespace, "email_content"])}
            </Typography.LabelSm>
            <Typography.LabelXs color="text-default">
              {emailContent && formattedLastUpdatedAt
                ? t(["workflows_v2.action_selector.email", "last_updated_at"], {
                    timestamp: formattedLastUpdatedAt,
                  })
                : t([localeNamespace, "email_content_description"])}
            </Typography.LabelXs>
          </div>
          <div className="flex gap-2">
            {emailContent && (
              <Button
                variant="secondary-light"
                type="button"
                onClick={handleTestEmail}
                disabled={isSending}
              >
                {t("workflows_v2.action_selector.email.send_a_test")}
              </Button>
            )}
            <Button
              variant="secondary-light"
              type="button"
              onClick={handleStartWriting}
              disabled={isSending}
            >
              {emailContent ? t("edit") : t([localeNamespace, "start_writing"])}
            </Button>
          </div>
        </div>
        <div className="bg-secondary max-h-60 overflow-y-auto rounded-lg px-6 py-5">
          <div className="bg-primary h-[25rem] w-full max-w-[24rem] rounded-lg p-6 shadow">
            {emailContent ? (
              <BroadcastTipTapReadonlyView content={emailContent} />
            ) : (
              <SkeletonLoader variant="email-preview" />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="border-primary flex justify-between rounded-b-none rounded-t-lg border px-5 py-4">
        <div className="flex flex-col gap-y-1">
          <Typography.LabelSm weight="semibold" color="text-very-dark">
            {t([localeNamespace, "email_content"])}
          </Typography.LabelSm>
          <Typography.LabelXs color="text-default">
            {t([localeNamespace, "email_content_description"])}
          </Typography.LabelXs>
        </div>
        <div>
          <Button
            variant="secondary"
            type="button"
            onClick={handleStartWriting}
          >
            {emailContent ? t("edit") : t([localeNamespace, "start_writing"])}
          </Button>
        </div>
      </div>
      <div className="bg-secondary border-primary h-64 overflow-y-auto rounded-b-lg border-x border-b py-6">
        <div className="bg-primary mx-auto max-w-[500px] rounded-lg p-8 shadow-sm">
          {emailContent ? (
            <BroadcastTipTapReadonlyView content={emailContent} />
          ) : (
            <SkeletonLoader variant="email-preview" />
          )}
        </div>
      </div>
    </div>
  );
}
