import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { APPEARANCE } from "@circle-react/providers";
import type { DropdownButton } from "./types";
import { useAdminIcons } from "./useAdminIcons";

interface GetToggleThemeProps {
  showToggleTheme: boolean;
  currentAppearance: "light" | "dark";
  toggleAppearance?: () => void;
}
export const useGetToggleTheme = ({
  showToggleTheme,
  currentAppearance,
  toggleAppearance = noop,
}: GetToggleThemeProps): DropdownButton[] => {
  const { toggleThemeLightIcon, toggleThemeDarkIcon } = useAdminIcons();
  const { iconSize } = useIsV3();

  if (!showToggleTheme) return [];

  return [
    {
      value: currentAppearance,
      label:
        currentAppearance === APPEARANCE.LIGHT
          ? t("sidebar_switch_to_dark_mode")
          : t("sidebar_switch_to_light_mode"),
      onClick: toggleAppearance,
      icon:
        currentAppearance === APPEARANCE.LIGHT
          ? toggleThemeLightIcon
          : toggleThemeDarkIcon,
      iconSize,
    },
  ];
};
