import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { SettingsHeader } from "@/react/components/SettingsApp/SettingsHeader";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Tab } from "@circle-react-uikit/TabV2";
import { NoAgents } from "./NoAgents";
import { Snippets } from "./Snippets";
import { Spaces } from "./Spaces";
import { useIndexAgents } from "./agentsQueries";

export const TABS = {
  TAB_COMMUNITY: "tab_community",
  TAB_CUSTOM: "tab_custom",
};
export const TABS_LABELS = {
  [TABS.TAB_COMMUNITY]: t("settings.community_bot.knowledge.tab_community"),
  [TABS.TAB_CUSTOM]: t("settings.community_bot.knowledge.tab_custom"),
};
const tabNames = Object.values(TABS);

export const CommunityBotKnowledge = () => {
  const { data: agentsQuery, isLoading } = useIndexAgents({ currentPage: 1 });
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    tabNames.indexOf(TABS.TAB_COMMUNITY),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (
    !agentsQuery ||
    !agentsQuery.records ||
    agentsQuery.records.length === 0
  ) {
    return <NoAgents />;
  }

  return (
    <div className="bg-primary h-full">
      <Tab.Group
        className="!h-auto overflow-visible"
        selectedIndex={selectedTabIndex}
        onChange={tabIndex => setSelectedTabIndex(tabIndex)}
      >
        <SettingsHeader
          pageTitle={t("settings.community_bot.knowledge.title")}
          hasBorder={false}
          filters={
            <Tab.ListWrapper>
              <Tab.List>
                {tabNames.map(tab => (
                  <Tab key={tab}>{TABS_LABELS[tab]}</Tab>
                ))}
              </Tab.List>
            </Tab.ListWrapper>
          }
        />

        <Tab.Panels>
          <Tab.Panel>
            <Spaces />
          </Tab.Panel>
          <Tab.Panel>
            <Snippets />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
