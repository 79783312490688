import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { toLocaleString } from "@circle-react/helpers/number";
import { memberSettingsPaths } from "@circle-react/helpers/urlHelpers";
import type { CommunityMember } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Settings } from "./Settings";

export interface MembersHeaderTitleProps {
  currentCommunityMember: CommunityMember;
  memberCount: number;
  hasDropdown?: boolean;
  headerTitle?: string;
}

export const MembersHeaderTitle = ({
  currentCommunityMember,
  memberCount,
  hasDropdown = true,
  headerTitle = t("members_directory.header.title"),
}: MembersHeaderTitleProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const onEditMemberDirectoryClick = () => {
    setIsSettingsOpen(true);
  };

  const onSettingsCloseClick = () => {
    setIsSettingsOpen(false);
  };

  const countString = `(${toLocaleString(memberCount)})`;
  const spaceHeaderTitleText =
    memberCount <= 1 ? headerTitle : `${headerTitle} ${countString}`;

  if (!isCommunityAdmin(currentCommunityMember)) {
    return <SpaceHeaderTitleText>{spaceHeaderTitleText}</SpaceHeaderTitleText>;
  }

  if (!hasDropdown) {
    return <SpaceHeaderTitleText>{spaceHeaderTitleText}</SpaceHeaderTitleText>;
  }

  return (
    <>
      <Dropdown
        direction="bottom-start"
        button={
          <Dropdown.MenuButton>
            <SpaceHeaderTitleText>{spaceHeaderTitleText}</SpaceHeaderTitleText>
            <Icon size={16} type="caret-down-bold" className="text-dark" />
          </Dropdown.MenuButton>
        }
      >
        <Dropdown.ItemLink to={memberSettingsPaths.index()}>
          {t("members_directory.header.manage_audience")}
        </Dropdown.ItemLink>
        <Dropdown.ItemLink forceClose onClick={onEditMemberDirectoryClick}>
          {t("members_directory.header.edit_member_directory")}
        </Dropdown.ItemLink>
      </Dropdown>
      <Settings open={isSettingsOpen} onClose={onSettingsCloseClick} />
    </>
  );
};
