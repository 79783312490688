import type { ReactNode } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface CoverImageWrapperProps {
  children: ReactNode;
}

export const CoverImageWrapper = ({ children }: CoverImageWrapperProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames({
        "md:px-6 md:pb-3 md:pt-5 lg:px-9": isV3Enabled,
      })}
    >
      {children}
    </div>
  );
};
