import { t } from "@/i18n-js/instance";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { DropdownButton } from "./types";
import { useAdminIcons } from "./useAdminIcons";

export const useGetviewAs = ({ showBanner }: any): DropdownButton => {
  const { viewAsIcon } = useAdminIcons();
  const { iconSize } = useIsV3();

  const viewAs: DropdownButton = {
    icon: viewAsIcon,
    iconSize,
    label: t("view_as"),
    onClick: () => {
      // setTimeout is used here to transfer focus from LogoDropdown to newly opened modal
      // if you just call showBanner, the modal is opened, but search inside modal is not focused,
      // because focus stays on LogoDropdown. Couldn't find a better way to solve this
      setTimeout(() => {
        showBanner();
      }, 0);
    },
    value: "viewAs",
  };
  return viewAs;
};
