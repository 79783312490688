import type { TwoUserStackProps } from "@circleco/ui-kit";
import { TwoUserStack } from "@circleco/ui-kit";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

type Users = TwoUserStackProps["users"];

interface User {
  name: string;
  avatar_url: string;
}

interface GroupUserImageProps {
  user1: User;
  user2: User;
  className?: string;
}

/** @deprecated Please use TwoUserStack component from @circleco/ui-kit */
export const GroupUserImage = ({
  user1,
  user2,
  className = "",
}: GroupUserImageProps) => {
  const { isV3Enabled } = useIsV3();

  const users: Users = [
    { name: user1?.name || "", src: user1?.avatar_url || "" },
    { name: user2?.name || "", src: user2?.avatar_url || "" },
  ];

  return (
    <div className={classNames("border-tertiary max-h-10", className)}>
      <TwoUserStack users={users} isFullRounded={isV3Enabled} />
    </div>
  );
};
