import { t } from "@/i18n-js/instance";
import { FormChoosePoints } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/FormChoosePoints";
import type { HistoryPointsViewProps } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/HistoryPointsView";
import { HistoryPointsView } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/HistoryPointsView";
import { ViewPoints } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/ViewPoints";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../types";

export const awardPoints: Readonly<ActionOption<HistoryPointsViewProps>> = {
  label: t("settings.workflows.form.actions.award_points"),
  value: "award_points",
  group: t("settings.workflows.form.action_groups.gamification"),
  leftAddon: <Icon type="16-space-directory" size={16} className="!h-4 !w-4" />,
  formComponent: FormChoosePoints,
  viewComponent: ViewPoints,
  historyDetailViewComponent: HistoryPointsView,
  icon: "user",
  isApplicableToMembersOnly: true,
} as const;
