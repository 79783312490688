import type { ComponentPropsWithoutRef } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { CoverImageWrapper } from "../CoverImageWrapper";

export interface CoverImageProps extends ComponentPropsWithoutRef<"div"> {
  url: string;
  alt?: string;
  thumbnail?: boolean;
}

export const CoverImage = ({
  url,
  alt = "",
  thumbnail = false,
  ...props
}: CoverImageProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <CoverImageWrapper>
      <div
        {...props}
        className={classNames("bg-primary block h-auto lg:relative", {
          "md:h-[30vh]": !thumbnail && !isV3Enabled,
          "aspect-16/5 h-full max-h-[20.625rem] md:mx-auto md:max-w-5xl md:overflow-hidden md:rounded-2xl":
            !thumbnail && isV3Enabled,
        })}
        data-testid="cover-image-container"
      >
        <img
          loading="lazy"
          src={url}
          alt={alt}
          className={classNames("h-full w-full", {
            "object-contain": thumbnail,
            "object-cover": !thumbnail,
          })}
          data-testid="cover-image"
        />
      </div>
    </CoverImageWrapper>
  );
};
