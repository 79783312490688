import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOptionWithApiParams } from "../../types";
import { FormSelectPaywall } from "./FormSelectPaywall";
import { HistoryDetailViewPaywall } from "./HistoryDetailViewPaywall";
import type { HistoryDetailViewPaywallProps } from "./HistoryDetailViewPaywall/HistoryDetailViewPaywall";
import { ViewCancelSubscription } from "./ViewCancelSubscription";

export interface CancelSubscriptionApiParams {
  paywall_id: number;
}

export const cancelSubscription: Readonly<
  ActionOptionWithApiParams<
    HistoryDetailViewPaywallProps,
    CancelSubscriptionApiParams
  >
> = {
  label: t(
    "settings.workflows.form.actions.cancel_community_member_paywall_subscription",
  ),
  value: "cancel_community_member_paywall_subscription",
  group: t("settings.workflows.form.action_groups.paywall"),
  leftAddon: <Icon type="16-remove-paywall" size={16} className="!h-4 !w-4" />,
  formComponent: FormSelectPaywall,
  viewComponent: ViewCancelSubscription,
  historyDetailViewComponent: HistoryDetailViewPaywall,
  isApplicableToMembersOnly: true,
} as const;
