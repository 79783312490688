import { flow } from "lodash";
import { t } from "@/i18n-js/instance";
import { SharedFilters } from "@circle-react-shared/MemberFilters";
import { ProfileFieldFilter } from "@circle-react-shared/MemberFilters/ProfileFieldFilter";
import type { RenderFilter } from "@circle-react-shared/MemberFilters/types";
import { usePaywallFilter } from "@circle-react-shared/MemberFilters/usePaywallFilter";
import { useProfileFieldFilters } from "@circle-react-shared/MemberFilters/useProfileFieldFilters";
import { useShouldShowActivityScoreFilter } from "@circle-react-shared/MemberFilters/useShouldShowActivityScoreFilter";
import { useShouldShowAvatarSetFilter } from "@circle-react-shared/MemberFilters/useShouldShowAvatarSetFilter";
import { useShouldShowMemberDaysAgoFilters } from "@circle-react-shared/MemberFilters/useShouldShowDaysAgoFilter";
import { useShouldShowInvitationLinksFilter } from "@circle-react-shared/MemberFilters/useShouldShowInvitationLinksFilter";
import { useShouldShowLevelFilter } from "@circle-react-shared/MemberFilters/useShouldShowLevelFilter";
import { useShouldShowMemberTagsFilter } from "@circle-react-shared/MemberFilters/useShouldShowMemberTagsFilter";

const {
  RoleFilter,
  SpaceGroupsFilterV2,
  SpacesFilter,
  StatusFilter,
  MemberTagsFilter,
  PaywallsFilter,
  EventsFilter,
  HasPastDueSubscriptionFilter,
  JoinedCommunityDaysAgoFilter,
  LastLoggedInDaysAgoFilter,
  AvatarSet,
  ActivityScoreFilter,
  NameFilter,
  BioFilter,
  HeadlineFilter,
  LocationFilter,
  InvitationLinksFilter,
  LevelFilter,
} = SharedFilters;

const daysAgoFilters = [
  {
    name: "joined_community_days_ago",
    component: JoinedCommunityDaysAgoFilter,
    label: t(
      "members_directory.header.search.filters.joined_community_days_ago",
    ),
    listLabel: t(
      "members_directory.header.search.filters.joined_community_days_ago_available",
    ),
  },
  {
    name: "last_logged_in_days_ago",
    component: LastLoggedInDaysAgoFilter,
    label: t("members_directory.header.search.filters.last_logged_in_days_ago"),
  },
];

export const platformMemberFilters: RenderFilter[] = [
  {
    name: "name",
    component: NameFilter,
    label: t("members_directory.header.search.filters.name"),
  },
  {
    name: "bio",
    component: BioFilter,
    label: t("members_directory.header.search.filters.bio"),
  },
  {
    name: "headline",
    component: HeadlineFilter,
    label: t("members_directory.header.search.filters.headline"),
  },
  {
    name: "location",
    component: LocationFilter,
    label: t("members_directory.header.search.filters.location"),
  },
  {
    name: "role",
    component: RoleFilter,
    label: t("members_directory.header.search.filters.role"),
  },
  {
    name: "invitation_link_ids",
    component: InvitationLinksFilter,
    label: t(
      "members_directory.header.search.filters.invitation_link_ids.singular_title",
    ),
  },
  {
    name: "spaces",
    component: SpacesFilter,
    label: t("members_directory.header.search.filters.spaces.singular_title"),
  },
  {
    name: "space_groups",
    component: SpaceGroupsFilterV2,
    label: t(
      "members_directory.header.search.filters.space_groups.singular_title",
    ),
  },
  {
    name: "online_status",
    component: StatusFilter,
    label: t("members_directory.header.search.filters.online_status"),
  },
  {
    name: "tags",
    component: MemberTagsFilter,
    label: t("members_directory.header.search.filters.tags.singular_title"),
  },
  {
    name: "paywalls",
    component: PaywallsFilter,
    label: t("members_directory.header.search.filters.paywalls.singular_title"),
  },
  {
    name: "events",
    component: EventsFilter,
    label: t("members_directory.header.search.filters.events.singular_title"),
  },
  {
    name: "has_past_due_subscription",
    component: HasPastDueSubscriptionFilter,
    label: t(
      "members_directory.header.search.filters.has_past_due_subscription",
    ),
  },
  ...daysAgoFilters,
  {
    name: "is_avatar_set",
    component: AvatarSet,
    label: t("members_directory.header.search.filters.avatar_set"),
  },
  {
    name: "activity_score",
    component: ActivityScoreFilter,
    label: t("members_directory.header.search.filters.activity_score"),
  },
  {
    name: "level",
    component: LevelFilter,
    label: t("members_directory.header.search.filters.level"),
  },
];

export const usePlatformMemberFilters = () => {
  const {
    isLoadingProfileFields,
    customProfileFields,
    filteredDefaultFilters,
  } = useProfileFieldFilters({
    platformFilters: platformMemberFilters,
    component: ProfileFieldFilter,
  });

  const marketingFiltered = flow(
    usePaywallFilter,
    useShouldShowMemberDaysAgoFilters,
    useShouldShowAvatarSetFilter,
    useShouldShowMemberTagsFilter,
    useShouldShowInvitationLinksFilter,
    useShouldShowLevelFilter,
  )(filteredDefaultFilters);
  const finalFilters = useShouldShowActivityScoreFilter({
    filters: marketingFiltered,
    canShowActivityScoreDisabled: true,
  });

  return {
    finalFilters,
    customProfileFields,
    isLoadingProfileFields,
  };
};
