import { t } from "@/i18n-js/instance";
import { GDPRCookieBanner } from "@circle-react/components/GDPR/GDPRCookieBanner";
import { MasqueradeBanner } from "@circle-react/components/MasqueradeBanner";
import { OptInBar } from "@circle-react/components/NewVersionOptIn/OptInBar";
import { RailsFlashMessages } from "@circle-react/components/RailsFlashMessages";
import { SupportWidgetPortal } from "@circle-react/components/SupportWidget/SupportWidgetPortal";
import { twoFaPath } from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { applyV3Fonts } from "@circle-react/providers/Theme";
import { Routes } from "@circle-react/routes/Main";
import { CommunitySwitcherAppData } from "@circle-react/wrappers/CommunitySwitcherAppData";
import { HeaderV3 } from "@circle-react-shared/uikit/HeaderV3";
import { AdminOnlyRouteNotice } from "@circle-react-shared/uikit/HeaderV3/NavigationBar/AdminOnlyRouteNotice";
import { ErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { useRenderAdminPreviewModal } from "../NewVersionOptIn/AdminPreviewModal/hooks/useRenderAdminPreviewModal";
import { PreviewBar } from "../NewVersionOptIn/PreviewBar";
import { useRenderVersionActivationModal } from "../NewVersionOptIn/VersionActivationModal/hooks/useRenderVersionActivationModal";

export interface MainAppContentProps {
  notice: string;
  alert: string;
  shouldDisplayHeader: boolean;
  shouldDisplayCommunitySwitcher: boolean;
  hasMinimalLayout: boolean;
  showWidgetBot: boolean;
}

export const MainAppContent = ({
  notice,
  alert,
  shouldDisplayHeader,
  shouldDisplayCommunitySwitcher,
  hasMinimalLayout,
  showWidgetBot,
}: MainAppContentProps) => {
  const { isV3Enabled } = useIsV3();
  const isNotTwoFaPage = window.location.pathname !== twoFaPath();
  useRenderVersionActivationModal();
  useRenderAdminPreviewModal();

  if (isV3Enabled) {
    applyV3Fonts();

    return (
      <div className="flex">
        {shouldDisplayCommunitySwitcher && (
          <ErrorBoundary>
            <CommunitySwitcherAppData />
          </ErrorBoundary>
        )}
        <div className="w-full">
          <RailsFlashMessages notice={notice} alert={alert} />
          <PreviewBar
            previewBarText={t("new_version_preview_bar.preview_bar_text")}
            previewBarCtaContent={t(
              "new_version_preview_bar.preview_bar_cta_content",
            )}
          />
          <MasqueradeBanner />
          {shouldDisplayHeader && !hasMinimalLayout && isNotTwoFaPage && (
            <HeaderV3 />
          )}
          <Routes />
          <SupportWidgetPortal showWidgetBot={showWidgetBot} />
          <GDPRCookieBanner />
          <AdminOnlyRouteNotice />
        </div>
      </div>
    );
  }

  return (
    <>
      <RailsFlashMessages notice={notice} alert={alert} />
      <OptInBar
        optInText={t("new_version_opt_in.opt_in_bar_text")}
        optInCTAContent={t("new_version_opt_in.opt_in_bar_cta_content")}
      />
      <MasqueradeBanner />
      {shouldDisplayHeader && !hasMinimalLayout && isNotTwoFaPage && (
        <HeaderV3 />
      )}
      {shouldDisplayCommunitySwitcher && (
        <ErrorBoundary>
          <CommunitySwitcherAppData />
        </ErrorBoundary>
      )}
      <Routes />
      <SupportWidgetPortal showWidgetBot={showWidgetBot} />
      <GDPRCookieBanner />
    </>
  );
};
