import { useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { formsApi } from "@circle-react/api/formsApi";
import type { Form } from "@circle-react/types/Form";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export const useGetForm = ({ id }: { id?: number }) => {
  const toastr = useToast();

  return useQuery<Form>({
    queryKey: ["forms", "show", id],
    queryFn: () => formsApi.show(id!),
    onError: () => toastr.error(t("error")),
    enabled: id !== undefined,
  });
};
