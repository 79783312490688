import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import type { HistoryDetailViewMemberProps } from "./HistoryDetailViewMember";
import { HistoryDetailViewMember } from "./HistoryDetailViewMember";

export const removeMember: Readonly<
  ActionOption<HistoryDetailViewMemberProps>
> = {
  label: t(
    "settings.workflows.form.actions.remove_community_member_from_community",
  ),
  value: "remove_community_member_from_community",
  group: t("settings.workflows.form.action_groups.members"),
  leftAddon: (
    <Icon
      type="16-workflow-user-remove-outline"
      size={16}
      className="!h-4 !w-4"
    />
  ),
  formComponent: null,
  viewComponent: null,
  historyDetailViewComponent: HistoryDetailViewMember,
  icon: "user-remove",
  isApplicableToMembersOnly: true,
} as const;
