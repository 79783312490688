import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { Form } from "@circle-react-uikit/Form";
import { FormAttachment } from "../FormAttachment";
import { FormItem } from "../FormItem";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import { PRODUCT_AREAS, TICKET_TYPES } from "../constants";
import { getLocalStorage, setLocalStorage } from "../utils";

export const SendUsAMessageForm = ({
  isLoading,
  uploadAttachments,
  attachments,
  cancelAttachment,
}) => {
  const [text, type, area, is_important] = useWatch({
    name: ["text", "type", "area", "is_important"],
  });
  const isFormDirty = text && type && area;
  const debouncedText = useDebouncedValue(text, 500);
  const [ticketTypes, setTicketTypes] = useState(TICKET_TYPES);
  const [productAreas, setProductAreas] = useState(PRODUCT_AREAS);
  const { setIsDraftConversationPresent } = useSupportWidgetContext();
  const allAttachmentsComplete = attachments.every(
    attachment => attachment.completed,
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop: uploadAttachments,
    noClick: true,
  });

  const openFilePicker = event => {
    event.preventDefault();
    inputRef.current.click();
  };

  const handleAreaSearch = query => {
    if (query === "") {
      setProductAreas(PRODUCT_AREAS);
    } else {
      const filteredProductAreas = PRODUCT_AREAS.filter(area =>
        area.value.toLowerCase().includes(query.toLowerCase()),
      );
      setProductAreas(filteredProductAreas);
    }
  };

  const handleTypeSearch = query => {
    if (query === "") {
      setTicketTypes(TICKET_TYPES);
    } else {
      const filteredTicketTypes = TICKET_TYPES.filter(ticketType =>
        ticketType.value.toLowerCase().includes(query.toLowerCase()),
      );
      setTicketTypes(filteredTicketTypes);
    }
  };

  useEffect(() => {
    setLocalStorage({
      ...getLocalStorage(),
      formValues: {
        text: debouncedText,
        type: type,
        area: area,
        is_important,
      },
    });
    setIsDraftConversationPresent(
      debouncedText || type || area || is_important,
    );
  }, [debouncedText, type, area, setIsDraftConversationPresent, is_important]);

  useEffect(() => {
    const sendMessageFormContainer = document.getElementById(
      "send-message-form-container",
    );
    sendMessageFormContainer.scrollIntoView();

    const labels = sendMessageFormContainer.querySelectorAll("label > span");
    labels.forEach(label => {
      const spanElement = document.createElement("span");
      spanElement.className = "text-red-400";
      spanElement.textContent = " *";
      label.appendChild(spanElement);
    });
  }, []);

  return (
    <div className="flex max-h-full flex-col px-6">
      <FormItem hidePlaceholder={false} name="type">
        <Form.Select
          name="type"
          data={ticketTypes}
          onSearch={handleTypeSearch}
        />
      </FormItem>
      <FormItem hidePlaceholder={false} className="!pt-2" name="area">
        <Form.Select
          name="area"
          data={productAreas}
          onSearch={handleAreaSearch}
        />
      </FormItem>
      <FormItem className="!pt-2" name="text">
        <Form.Input
          name="text"
          variant="textarea"
          rows={12}
          className="form-control resize-none"
        />
      </FormItem>
      <FormItem name="is_important" hideLabel hideDescription hideBorder>
        <Form.Checkbox
          className="dark:border-secondary !mt-1"
          option={{
            label: t(
              "support_widget.send_message_form.is_important_description",
            ),
          }}
        ></Form.Checkbox>
      </FormItem>
      {attachments.length > 0 && (
        <div className="mt-6 flex flex-col gap-2">
          {attachments.map(attachment => (
            <FormAttachment
              key={attachment.url}
              attachment={attachment}
              onCancel={cancelAttachment}
            />
          ))}
        </div>
      )}
      <div className="my-6 flex justify-between" {...getRootProps()}>
        <input {...getInputProps()} />
        <TippyV2
          placement="right"
          content={t("tiptap.attach_file")}
          className="text-dark visited:text-dark hover:bg-tertiary hover:text-dark focus:bg-tertiary flex w-10 items-center justify-center rounded-lg text-center"
        >
          <button
            type="button"
            aria-label={t("tiptap.attach_file")}
            onClick={openFilePicker}
            className="p-2"
          >
            <Icon type="tiptap-paperclip" size={20} />
          </button>
        </TippyV2>

        <TippyV2
          placement="left"
          content={t("support_widget.send_message_form.button_tooltip")}
          disabled={isFormDirty && allAttachmentsComplete}
        >
          <button
            className="bg-circle rounded-lg px-6 py-2.5 font-bold leading-5 text-white hover:opacity-90 disabled:cursor-not-allowed disabled:opacity-50"
            disabled={isLoading || !isFormDirty || !allAttachmentsComplete}
            type="submit"
          >
            {t("support_widget.send_message_form.button_label")}
          </button>
        </TippyV2>
      </div>
    </div>
  );
};

SendUsAMessageForm.propTypes = {
  isLoading: PropTypes.bool,
  uploadAttachments: PropTypes.func,
  attachments: PropTypes.array,
  cancelAttachment: PropTypes.func,
};
