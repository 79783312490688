import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useIsCommunityBotMessagingEnabled } from "@circle-react/hooks/useIsMessagingEnabled";
import { useCommunityBotProfileSearch } from "./useCommunityBotProfileSearch";

export const useShouldShowAgentsTab = () => {
  const { botProfiles } = useCommunityBotProfileSearch({ name: "" });
  const isBotMessagingEnabled = useIsCommunityBotMessagingEnabled();
  const { memberChats } = useCommunityInboxStore();
  return (
    isBotMessagingEnabled &&
    (botProfiles?.length > 0 || memberChats?.length > 0)
  );
};
