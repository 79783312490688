import type { Ref } from "react";
import * as yup from "yup";
import { t } from "@/i18n-js/instance";
import { isValidTiptapBody } from "@/react/components/QuickPostV2/validation";
import { useCourseSections } from "@/react/hooks/courses/useCoursesApi";
import type { CoursesSection } from "@/react/types/CoursesSection";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useEmailNotificationMutation } from "../../hooks";
import { FormContent } from "./FormContent";
import { DEFAULT_EMAIL_BODY } from "./constants";

interface NotifyStudentsEmailFormProps {
  section: CoursesSection;
  onSuccess: () => void;
  onSubmit: () => void;
  onError: () => void;
  initialFocusRef?: Ref<HTMLInputElement | HTMLTextAreaElement>;
  isEdit: boolean;
}

interface FormValues {
  email_notification_subject: string;
  email_notification_body: Record<string, any>;
}

export const FORM_ID = "edit-course-type-form";
const TRANSLATION_ROOT = "courses.edit.sections.notify_students_modal.form";

export const NotifyStudentsEmailForm = ({
  section,
  onSuccess,
  onSubmit,
  onError,
  initialFocusRef,
  isEdit,
}: NotifyStudentsEmailFormProps) => {
  const { success, error } = useToast();

  const { refetch: refetchCourseSections } = useCourseSections(
    section.space_id,
  );
  const { emailNotificationMutation } = useEmailNotificationMutation({
    section,
    options: {
      onSuccess: async () => {
        await refetchCourseSections();
        success(t(`${TRANSLATION_ROOT}.save_success`), {
          duration: "short",
          shouldUseProgress: false,
        });
        onSuccess();
      },
      onError: () => {
        error(t(`${TRANSLATION_ROOT}.save_error`));
        onError();
      },
    },
  });

  const handleSubmit = (payload: FormValues) => {
    emailNotificationMutation.mutate({
      course_section: {
        ...payload,
        ...(!isEdit && { notify_students_enabled: true }),
      },
    });
    onSubmit();
  };

  const validationSchema = yup.object().shape({
    email_notification_subject: yup
      .string()
      .required(t(`${TRANSLATION_ROOT}.required`)),
    email_notification_body: yup.object().test({
      test: value => isValidTiptapBody(value),
      message: t(`${TRANSLATION_ROOT}.required`),
    }),
  });

  const defaultValues: any = {
    email_notification_subject:
      section.email_notification_subject ||
      t(`${TRANSLATION_ROOT}.email_notification_subject_placeholder`).replace(
        /[{}]/g,
        match => `${match}${match}`,
      ),
    email_notification_body: {
      body:
        section.serialized_email_notification_body?.body || DEFAULT_EMAIL_BODY,
    },
  };

  return (
    <Form
      id={FORM_ID}
      onSubmit={handleSubmit}
      preventAccidentalLeave
      validationSchema={validationSchema}
      className="divide-primary divide-y"
      formClassName={undefined}
      defaultValues={defaultValues}
    >
      <FormContent section={section} initialFocusRef={initialFocusRef} />
    </Form>
  );
};
