import classNames from "classnames";
import { ToggleSwitch } from "./ToggleSwitch";
import { useUpdateSpace } from "./queries";

export const SpaceNameCell = ({ id, name, active }: any) => {
  const updateSpaceMutation = useUpdateSpace({ onSuccess: () => {} });

  return (
    <div className="m-0 flex w-full flex-row items-center gap-4">
      <span className="mt-0.5">
        <ToggleSwitch
          name={`space-${id}`}
          checked={active}
          variant="small"
          shouldUseCheckmark
          onToggleChange={() => {
            updateSpaceMutation.mutate({ id: id, active: !active });
          }}
        />
      </span>
      <span
        className={classNames({
          "font-normal": !active,
          "font-semibold": active,
        })}
      >
        {name}
      </span>
    </div>
  );
};
