import { useMemo } from "react";
import { usePunditUserContext } from "@circle-react/contexts";

export const useApiFeatureFlag = () => {
  const { currentCommunity } = usePunditUserContext();

  const isApiFeatureEnabled = useMemo(
    () => !!currentCommunity?.api_access_feature_flag_enabled,
    [currentCommunity],
  );

  return { isApiFeatureEnabled };
};
