import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

const itemProps = {
  translationRoot: "space_groups.form.create",
  hiddenField: true,
  hideDescription: true,
  className: "flex flex-row-reverse justify-end m-0",
  labelClassName: "ml-4 mb-0",
  labelWrapperClassName: "flex items-center",
  shouldToggleValueOnLabelClick: true,
};

export const Advanced = () => (
  <div className="mt-8 space-y-5">
    <Form.Item
      {...itemProps}
      name="hide_members_count"
      tooltipText={t("space_groups.form.create.hide_members_count_tooltip")}
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
    <Form.Item
      {...itemProps}
      name="is_hidden_from_non_members"
      tooltipText={t(
        "space_groups.form.create.is_hidden_from_non_members_tooltip",
      )}
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
    <Form.Item
      {...itemProps}
      name="hide_non_member_spaces_from_sidebar"
      tooltipText={t(
        "space_groups.form.create.hide_non_member_spaces_from_sidebar_tooltip",
      )}
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
    <Form.Item
      {...itemProps}
      name="allow_members_to_create_spaces"
      tooltipText={t(
        "space_groups.form.create.allow_members_to_create_spaces_tooltip",
      )}
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
    <Form.Item
      {...itemProps}
      name="automatically_add_members_to_new_spaces"
      tooltipText={t(
        "space_groups.form.create.automatically_add_members_to_new_spaces_tooltip",
      )}
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
    <Form.Item
      {...itemProps}
      name="add_members_to_space_group_on_space_join"
      tooltipText={t(
        "space_groups.form.create.add_members_to_space_group_on_space_join_tooltip",
      )}
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
  </div>
);
