import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { FullScreenConfetti } from "./FullScreenConfetti";

export const VersionActivationModalComponent = () => {
  const modal = useModal();

  return (
    <>
      <FullScreenConfetti shouldCelebrate={modal.visible} />
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        title={t("version_activation_modal.title")}
      >
        <Modal.Overlay />
        <Modal.Content size="sm">
          <Modal.Header className="flex !flex-col !items-end">
            <Modal.HeaderActions className="!self-end">
              <Modal.CloseButton onClick={modal.hide} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body className="flex flex-col items-center !pb-6 !pt-0">
            <span className="text-center text-[5rem]">
              {t("version_activation_modal.emoji")}
            </span>
            <Typography.TitleMd>
              {t("version_activation_modal.title")}
            </Typography.TitleMd>
            <Typography.BodyMd>
              {t("version_activation_modal.description")}
            </Typography.BodyMd>
            <Button type="button" onClick={modal.hide} className="mt-6">
              {t("version_activation_modal.cta")}
            </Button>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};

const VersionActivationModal = ModalManager.create(() => (
  <VersionActivationModalComponent />
));

export const useVersionActivationModal = () => useModal(VersionActivationModal);
