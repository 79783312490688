import InfiniteScroll from "react-infinite-scroll-component";
import { ChatItem } from "@circle-react/components/SettingsApp/CommunityInbox/Sidebar/ChatList/ChatItem";
import { useChatList } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useCommunityChatList";
import { chatBotPath } from "@circle-react/helpers/urlHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";
import { Loader } from "@circle-react-uikit/Loader";
import { EmptyState } from "./EmptyState";

export const BotChatList = () => {
  const {
    isChatsLoading: isBotChatsLoading,
    hasNextPage,
    fetchNextPage,
  } = useChatList({
    filter: "mine",
  });
  const { currentChatId, memberChats: chats } = useCommunityInboxStore();

  if (isBotChatsLoading) {
    return <Loader center />;
  }
  if (chats.length === 0) {
    return <EmptyState />;
  }
  return (
    <InfiniteScroll
      scrollThreshold={0.8}
      next={fetchNextPage}
      hasMore={Boolean(hasNextPage)}
      dataLength={chats.length}
      scrollableTarget="scrollable-dms-div"
      className="mt-2 h-full overflow-auto"
      loader={<Loader center />}
    >
      {chats.map((chat: Chat) => {
        const isOpenChatRoom = chat.id == currentChatId;
        return (
          <ChatItem
            key={chat.id}
            chat={chat}
            path={chatBotPath({ id: chat.id.toString() })}
            isUnread={!isOpenChatRoom && chat.has_unread_messages_for_member}
            isBotType
          />
        );
      })}
    </InfiniteScroll>
  );
};
