import type { ReactNode } from "react";
import classNames from "classnames";
import { merge } from "lodash";
import { ClearButtonWrapper } from "@circle-react/components/shared/MemberFilters/DynamicFilters/ClearButtonWrapper";
import { DynamicFilterContext } from "@circle-react/components/shared/MemberFilters/DynamicFilters/DynamicFilterContext";
import { DynamicFilterItems } from "@circle-react/components/shared/MemberFilters/DynamicFilters/DynamicFilterItemsV2";
import { useDynamicFilters } from "@circle-react/components/shared/MemberFilters/DynamicFilters/useDynamicFilters";
import type {
  RenderFilter,
  RenderProfileFieldFilter,
} from "@circle-react/components/shared/MemberFilters/types";
import type { BaseFilter } from "@circle-react/components/shared/MemberFilters/types";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

interface DynamicFiltersProps {
  stateFilters: BaseFilter[];
  defaultFilterNames?: string[];
  className?: string;
  children: ReactNode;
  platformFilters: RenderFilter[];
  profileFieldFilters?: RenderProfileFieldFilter[];
}

const DynamicFilterWrapper = ({
  defaultFilterNames = [],
  stateFilters,
  className,
  children,
  platformFilters,
  profileFieldFilters = [],
}: DynamicFiltersProps) => {
  const dynamicFilterProps = useDynamicFilters({
    defaultFilterNames,
  });

  const dynamicFilterContextValue = {
    ...dynamicFilterProps,
    displayedFilters: merge(dynamicFilterProps.displayedFilters, stateFilters),
    platformFilters,
    profileFieldFilters,
  };

  const { isV3Enabled } = useIsV3();
  return (
    <DynamicFilterContext.Provider value={dynamicFilterContextValue}>
      <div
        className={classNames(
          "bg-primary flex w-full items-center justify-between gap-x-1 rounded-b-md px-6 py-3",
          className,
          { "lg:px-9": isV3Enabled },
        )}
      >
        {children}
      </div>
    </DynamicFilterContext.Provider>
  );
};

export const DynamicFilters = Object.assign(DynamicFilterWrapper, {
  Panel: DynamicFilterItems,
  ClearButton: ClearButtonWrapper,
});
