import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Switch } from "./Switch";

export const FormContent = ({ profile }: { profile: AgentProp }) => {
  const [use_community_knowledge, use_custom_snippets, use_platform_support] =
    useWatch({
      name: [
        "use_community_knowledge",
        "use_custom_snippets",
        "use_platform_support",
      ],
    });
  const togglesEnabled = [
    use_community_knowledge,
    use_custom_snippets,
    use_platform_support,
  ].filter(Boolean).length;

  return (
    <div className="flex flex-col gap-2">
      <Switch
        name="use_community_knowledge"
        icon="20-book"
        count={profile.posts_count}
        disabled={use_community_knowledge && togglesEnabled === 1}
      />
      <Switch
        name="use_custom_snippets"
        icon="20-note"
        count={profile.snippets_count}
        disabled={use_custom_snippets && togglesEnabled === 1}
      />
      <Switch
        name="use_platform_support"
        tooltipContent={
          <div className="flex flex-col">
            <Typography.LabelSm weight="semibold" color="text-dark-inverse">
              {t(
                "community_bot.bot_builder.knowledge_page.use_platform_support_tooltip_title",
              )}
            </Typography.LabelSm>
            <Typography.BodySm color="text-dark-inverse font-[InterVariable,sans-serif]">
              {t(
                "community_bot.bot_builder.knowledge_page.use_platform_support_tooltip_description",
              )}

              <a
                className="ml-1 font-bold text-inherit underline hover:text-inherit"
                href="https://help.circle.so/p/members"
                target="_blank"
                rel="noreferrer"
              >
                {t("community_bot.bot_builder.knowledge_page.learn_more")}
              </a>
            </Typography.BodySm>
          </div>
        }
        disabled={use_platform_support && togglesEnabled === 1}
      />
    </div>
  );
};
