import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { useIsEitherOptInOrPreviewBarVisible } from "@circle-react/components/NewVersionOptIn/useIsEitherOptInOrPreviewBarVisible";
import { Icon } from "@circle-react/components/shared/Icon";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { isTouchEvent } from "../utils";
import { SortableLinksList } from "./SortableLinksList";
import { useLinks } from "./useLinks";

export const Links = () => {
  const { canSeeLinks, shouldShowLinks, toggleLinks, orderedList, onSortEnd } =
    useLinks();
  const canHover = useAnyHoverMediaQuery();
  const { isV3Enabled } = useIsV3();
  const shouldDisplayPreviewBar = useIsEitherOptInOrPreviewBarVisible();

  if (!canSeeLinks) {
    return null;
  }

  return (
    <div
      id="links-section"
      className={classNames("mb-8 px-3", {
        "mt-4": !isV3Enabled,
        "!mb-16": shouldDisplayPreviewBar,
        "mt-6 flex flex-col gap-1 lg:px-6": isV3Enabled,
      })}
    >
      <button
        type="button"
        className={classNames(
          "text-c-sidebar hover:bg-c-sidebar-hover focus-visible:bg-c-sidebar-hover group flex w-full items-center space-x-px py-1.5 text-sm font-semibold transition-colors",
          {
            "my-px rounded-md px-2": !isV3Enabled,
            "rounded-lg px-4 transition-colors duration-200": isV3Enabled,
          },
        )}
        onClick={toggleLinks}
      >
        <span>{t("links")}</span>
        <div
          className={classNames("flex rounded py-0.5 transition-transform", {
            "hidden group-hover:flex": canHover,
            "rotate-0": !shouldShowLinks,
            "rotate-90": shouldShowLinks,
          })}
          aria-label={
            shouldShowLinks
              ? t("community_sidebar.expand_group", { group_name: "links" })
              : t("community_sidebar.collapse_group", { group_name: "links" })
          }
        >
          <Icon type="cheveron-right" size={16} />
        </div>
      </button>
      {shouldShowLinks && (
        <SortableLinksList
          links={orderedList}
          distance={5}
          lockAxis="y"
          onSortEnd={onSortEnd}
          shouldCancelStart={e => isTouchEvent(e)}
        />
      )}
    </div>
  );
};
