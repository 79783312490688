import { t } from "@/i18n-js/instance";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useDeleteSnippetModal } from "./DeleteSnippetModal";
import { useEditSnippetModal } from "./EditSnippetModal";

export const DropdownActions = ({ snippet }: { snippet: any }) => {
  const editSnippetModal = useEditSnippetModal();
  const deleteSnippetModal = useDeleteSnippetModal();

  return (
    <DataTable.Actions>
      <DataTable.Actions.Item
        onClick={() => editSnippetModal.show({ snippetId: snippet.id })}
      >
        {t("settings.community_bot.knowledge.custom.edit")}
      </DataTable.Actions.Item>
      <DataTable.Actions.Item
        hasDanger
        onClick={() => {
          void deleteSnippetModal.show({
            snippetId: snippet.id,
            title: snippet.title,
          });
        }}
      >
        {t("settings.community_bot.knowledge.custom.delete")}
      </DataTable.Actions.Item>
    </DataTable.Actions>
  );
};
