import { t } from "@/i18n-js/instance";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { isPublicStream } from "@circle-react/helpers/liveStreamRoomHelpers";
import { Recording, RecordingDuration } from "./Recording";
import "./styles.scss";

interface Room {
  id: number;
  name: string;
  description: string;
  created_at: string;
  recording_url: string;
  recording_name: string;
  recording_duration: string;
  guests_count: number;
}

interface DetailsProps {
  room: Room;
  refetch: () => void;
}

export const Details = ({ room, refetch }: DetailsProps) => {
  const date = formatDateString(room.created_at, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <div className="stream-details__details">
      {room.recording_url && <Recording room={room} refetch={refetch} />}
      {room.description && (
        <div className="mb-6">
          <p className="break-words pr-12">{room.description}</p>
        </div>
      )}
      <div className="mb-6">
        <div className="stream-details__details__label">
          {t("live_streams.dashboard.details.room_id")}
        </div>
        <p>{room.id}</p>
      </div>
      <div className="mb-6">
        <div className="stream-details__details__label">
          {t("live_streams.dashboard.details.created_at")}
        </div>
        <p>{date}</p>
      </div>
      {room.recording_duration && (
        <RecordingDuration recording_duration={room.recording_duration} />
      )}
      {isPublicStream(room) && (
        <div className="mb-6">
          <div className="text-darkest font-medium">
            {t("live_streams.dashboard.details.guests_count")}
          </div>
          <p>{room.guests_count}</p>
        </div>
      )}
    </div>
  );
};
