import { t } from "@/i18n-js/instance";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/Agents";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useDisableModal } from "./DisableModal";
import { useEnableModal } from "./EnableModal";

export const HeaderButtons = ({ agent }: { agent: AgentProp }) => {
  const enableModal = useEnableModal(agent);
  const disableModal = useDisableModal(agent);
  return (
    <div className="flex items-center justify-end gap-3">
      <Button type="submit" form="bot-builder-form" variant="secondary">
        <Typography.LabelSm weight="semibold">
          {t("community_bot.bot_builder.header_buttons.save.title")}
        </Typography.LabelSm>
      </Button>

      {agent.enabled ? (
        <Button
          variant="circle"
          onClick={e => {
            e.preventDefault();
            void disableModal.show();
          }}
        >
          <Typography.LabelSm color="white" weight="semibold">
            {t("community_bot.bot_builder.header_buttons.disable.title")}
          </Typography.LabelSm>
        </Button>
      ) : (
        <Button
          variant="circle"
          onClick={e => {
            e.preventDefault();
            void enableModal.show();
          }}
        >
          <Typography.LabelSm color="white" weight="semibold">
            {t("community_bot.bot_builder.header_buttons.enable.title")}
          </Typography.LabelSm>
        </Button>
      )}
    </div>
  );
};
