import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { SettingsHeader } from "@/react/components/SettingsApp/SettingsHeader";
import { UpgradePlan } from "@circle-react/components/LiveStreams/UpgradePlan";
import { GoLive } from "@circle-react/components/Spaces/SidebarV2/SidebarFooter";
import { usePunditUserContext } from "@circle-react/contexts";
import { useLiveStreamsFeatureFlags } from "@circle-react/hooks/useLiveStreamsFeatureFlags";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { SettingsContainer } from "../SettingsContainer";
import { Limits } from "./Limits";
import { LiveEmptyState } from "./LiveEmptyState";
import { LiveSettingsPagination } from "./LiveSettingsPagination";
import { LiveStreamsTable } from "./LiveStreamsTable";
import { useLiveStreamRoomsApi } from "./hooks";
import "./styles.scss";

export const LiveStreamSettings = ({ hideHeader = false }) => {
  const {
    currentCommunity: {
      supports_live_streams: communitySupportsLiveStreams,
    } = {},
  } = usePunditUserContext();
  const { liveStreamsLimitsEnabled } = useLiveStreamsFeatureFlags();
  const {
    records,
    page,
    hasNextPage,
    totalRecordCount,
    startRecordNumber,
    endRecordNumber,
    isLoading,
    refetch,
    fetchNextPage,
    fetchPrevPage,
  } = useLiveStreamRoomsApi();

  const hasLiveRecords = totalRecordCount > 0;

  return (
    <div className="bg-primary h-full">
      {hideHeader ? (
        <PageMetaData title={t("live_streams_settings")} />
      ) : (
        <SettingsHeader
          pageTitle={t("live_streams_settings")}
          rightContent={<GoLive inSettings />}
          hasBorder
        />
      )}
      <SettingsContainer maxWidth="none">
        {communitySupportsLiveStreams && (
          <>
            {isLoading ? (
              <LoaderRow />
            ) : (
              <>
                {liveStreamsLimitsEnabled && hasLiveRecords && <Limits />}
                {totalRecordCount > 0 && (
                  <DataTable.Headline className="!px-0">
                    <DataTable.Headline.Title>
                      {t("live_streams.count", {
                        count: totalRecordCount,
                      })}
                    </DataTable.Headline.Title>
                  </DataTable.Headline>
                )}
                {hasLiveRecords ? (
                  <>
                    <LiveStreamsTable
                      records={records}
                      refetch={refetch}
                      className="live-stream-settings__table"
                      totalRecordCount={totalRecordCount}
                    />
                    <LiveSettingsPagination
                      startRecordNumber={startRecordNumber}
                      endRecordNumber={endRecordNumber}
                      totalRecordCount={totalRecordCount}
                      page={page}
                      fetchPrevPage={fetchPrevPage}
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                    />
                  </>
                ) : (
                  <LiveEmptyState />
                )}
              </>
            )}
          </>
        )}
        {!communitySupportsLiveStreams && (
          <div className="border-primary bg-primary border p-6 text-center lg:rounded-lg lg:p-36">
            <UpgradePlan />
          </div>
        )}
      </SettingsContainer>
    </div>
  );
};

LiveStreamSettings.propTypes = {
  hideHeader: PropTypes.bool,
};

export default LiveStreamSettings;
