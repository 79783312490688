import type { UserImageProps } from "@circleco/ui-kit";
import { t } from "@/i18n-js/instance";
import { formatDate } from "@/react/components/SettingsApp/ManageMembers/TabContent/Cells";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

type UserImageSizes = UserImageProps["size"];

export const AuthorInfo = ({
  name,
  avatarUrl,
  postName,
  createdAt,
  userImageSize = "6",
}: {
  name: string;
  postName: string;
  avatarUrl: string;
  createdAt?: string;
  userImageSize?: UserImageSizes;
}) => (
  <div className="flex items-center gap-3">
    <div>
      <UserImage src={avatarUrl} name={name} size={userImageSize} />
    </div>
    <div className="w-[90%]">
      <div className="text-selector-active truncate">
        <Typography.LabelSm leading="leading-3" weight="semibold">
          {name}
        </Typography.LabelSm>{" "}
        <Typography.LabelSm leading="leading-3" color="text-selector-active">
          {t("search_v2.commented_on")}
        </Typography.LabelSm>{" "}
        <Typography.LabelSm leading="leading-3" weight="semibold">
          {postName}
        </Typography.LabelSm>
      </div>
      <div className="leading-3">
        <Typography.LabelXs color="text-selector-active">
          {formatDate(createdAt)}
        </Typography.LabelXs>
      </div>
    </div>
  </div>
);
