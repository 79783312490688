import { t } from "@/i18n-js/instance";
import { useCustomizeSpaceDrawer } from "@circle-react/components/Drawers/CustomizeSpaceDrawer";
import { useAddSpaceLinksModal } from "@circle-react/components/Modals/AddSpaceLinksModal";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import type { Space } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import type { DropDownOption } from "@circle-react-uikit/Dropdown";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { Separator } from "../../SpaceCustomizationModal/CustomizationOptions/Separator";
import { useManageSpaceOptions } from "./useManageSpaceOptions";

export interface ManageSpaceOptionsProps {
  space: Space;
  onInviteMember?: () => void;
  onAddCoverImage?: () => void;
}

export const ManageSpaceOptions = ({
  space,
  onInviteMember,
  onAddCoverImage,
}: ManageSpaceOptionsProps) => {
  const { id: spaceId } = space;
  const { show: showSpaceDrawer } = useCustomizeSpaceDrawer();
  const { refetch } = useCurrentSpaceContext();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const { isV3Enabled, iconSize } = useIsV3();
  const addSpaceLinksModal = useAddSpaceLinksModal();

  const openCustomizeModal = ({
    initialSection,
    scrollToId,
  }: {
    initialSection: string;
    scrollToId?: string;
  }) => {
    void showSpaceDrawer({
      spaceId,
      refetch: () => void refetch(),
      initialSection,
      scrollToId,
    });
  };

  const openAddSpaceLinksModal = () => {
    void addSpaceLinksModal.show({ space });
  };

  const accessOptions: DropDownOption[] = [
    {
      value: t("customize_space.access"),
      label: t("customize_space.access"),
      icon: isV3Enabled ? "20-locker-open-v3" : "16-lock-unlocked",
      iconSize,
      badge: t(`spaces.form.create.visibility_types.${space.visibility}`),
      badgeVariant: "plain",
      onClick: () =>
        openCustomizeModal({
          initialSection: t("customize_space.customize"),
        }),
    },
  ];

  const manageSpaceOptions = useManageSpaceOptions({
    space,
    onInviteMemberClick: onInviteMember,
    onAddCoverClick: onAddCoverImage,
    openCustomizeModal,
    openAddSpaceLinksModal,
  });

  if (isMobileWebNewSpaceCustomizationUIEnabled) {
    return (
      <>
        <button
          type="submit"
          className="flex w-full items-center justify-between px-5 py-2.5"
          onClick={() =>
            openCustomizeModal({
              initialSection: t("customize_space.customize"),
            })
          }
        >
          <div className="flex items-center gap-4">
            <Icon type="16-lock-unlocked" size={16} className="text-default" />
            <Typography.LabelSm color="text-light" weight="medium">
              {t("customize_space.access")}
            </Typography.LabelSm>
          </div>
          <div className="flex items-center gap-2">
            <Typography.LabelSm color="text-dark" weight="semibold">
              {t(`spaces.form.create.visibility_types.${space.visibility}`)}
            </Typography.LabelSm>
            <Icon type="16-chevron-right" size={16} className="text-default" />
          </div>
        </button>
        <Separator />
        {manageSpaceOptions.map(option => (
          <button
            type="submit"
            key={option.value}
            className="flex w-full items-center justify-between px-5 py-2.5"
            onClick={option.onClick}
          >
            <div className="flex items-center gap-4">
              {option.icon && (
                <Icon
                  type={option.icon}
                  size={iconSize}
                  className="text-default"
                />
              )}
              <Typography.LabelSm color="text-light" weight="medium">
                {option.label}
              </Typography.LabelSm>
            </div>
            <Icon type="16-chevron-right" size={16} className="text-default" />
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <Dropdown.IconWithBadgeList options={accessOptions} />
      <Dropdown.Separator />
      <Dropdown.Header>{t("space")}</Dropdown.Header>
      <Dropdown.IconWithBadgeList options={manageSpaceOptions} />
    </>
  );
};
