import { useHistory, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { posts } from "@/react/helpers/urlHelpers";
import { isEventPost } from "@circle-react/helpers/postHelpers";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Modal, useModalData } from "@circle-react-shared/uikit/ModalV2";
import { PostActionsDropdownMenu } from "../PostViewListItem/PostActionsDropdownMenu";
import { PostShareOptions } from "../PostViewListItem/PostShareOptions";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";

interface LocationState {
  prevLocation?: Location;
  shouldFocusOnCommentsSection?: boolean;
}

interface HeaderActionsProps {
  onChange: () => void;
  onPostDestroy: () => void;
  invalidateQueries?: () => void;
  invalidateSpacePosts: () => void;
}

export const HeaderActions = ({
  onChange,
  onPostDestroy,
  invalidateQueries,
  invalidateSpacePosts,
}: HeaderActionsProps) => {
  const isSmScreen = useSmScreenMediaQuery();
  const { post, refetchPostDetails } = useCurrentPostHelpers();
  const location = useLocation<LocationState>();
  const { contentPortalElement } = useModalData();
  const history = useHistory();
  const prevLocation = location?.state?.prevLocation;
  const { slug, space_slug: spaceSlug } = post;
  const { refetch: refetchPosts } = usePostList();

  if (isSmScreen) {
    return (
      <>
        <TippyV2
          interactive={false}
          content={t("post.post_modal.go_back")}
          className="inline-flex h-6 w-6"
        >
          <IconButton
            name="20-back"
            iconSize={20}
            iconClassName="!text-default group-hover:!text-dark"
            className="group"
            buttonSize={24}
            onClick={() => {
              if (prevLocation) {
                history.push(prevLocation);
                invalidateQueries?.();
              }
            }}
            ariaLabel={t("post.post_modal.go_back")}
          />
        </TippyV2>
        <Modal.HeaderActions>
          <div className="flex gap-2">
            <BookmarkButton
              bookmarkType={isEventPost(post) ? "event" : "post"}
              variant="minimal-post-modal"
              record={post}
              onSuccess={() => {
                refetchPostDetails?.();
                invalidateSpacePosts();
              }}
            />
            <PostActionsDropdownMenu
              onChange={onChange}
              onPostDestroy={onPostDestroy}
              refetchPosts={refetchPosts}
            />
          </div>
        </Modal.HeaderActions>
      </>
    );
  }
  return (
    <Modal.HeaderActions className="mt-1">
      <div className="flex gap-2 md:gap-5">
        <div className="flex gap-2">
          <TippyV2
            interactive={false}
            content={t("post.post_modal.share_post_button_tooltip")}
          >
            <PostShareOptions
              onChange={() => {
                onChange();
                invalidateSpacePosts();
              }}
              appendTo={contentPortalElement ?? document.body}
              menuButtonEl="div"
              button={
                <IconButton
                  name="20-share"
                  iconSize={20}
                  iconClassName="!text-default group-hover:!text-dark"
                  className="group"
                  buttonSize={24}
                  ariaLabel={t("post.post_modal.share_post_aria_label")}
                />
              }
              shouldRenderCustomMenuButton
            />
          </TippyV2>

          <BookmarkButton
            bookmarkType={isEventPost(post) ? "event" : "post"}
            variant="minimal-post-modal"
            record={post}
            onSuccess={() => {
              refetchPostDetails?.();
              invalidateSpacePosts();
            }}
          />
          <PostActionsDropdownMenu
            onChange={onChange}
            onPostDestroy={onPostDestroy}
            refetchPosts={refetchPosts}
          />
        </div>

        <div className="bg-overlay-dark w-px" />
        <div className="flex gap-2">
          <TippyV2
            interactive={false}
            content={t("post.post_modal.open_post_in_full_page_button_tooltip")}
          >
            <IconButton
              name="20-expand"
              iconSize={20}
              iconClassName="!text-default group-hover:!text-dark"
              className="group"
              buttonSize={24}
              onClick={() => {
                history.push(
                  posts.show({
                    slug,
                    spaceSlug,
                  }),
                );
              }}
              ariaLabel={t("post.post_modal.open_post_in_full_page_aria_label")}
            />
          </TippyV2>
          <TippyV2
            interactive={false}
            content={t("post.post_modal.close_post_modal_tooltip")}
          >
            <IconButton
              name="20-close"
              iconSize={20}
              iconClassName="!text-default group-hover:!text-dark"
              className="group"
              buttonSize={24}
              onClick={() => {
                if (prevLocation) {
                  history.push(prevLocation);
                  invalidateQueries?.();
                }
              }}
              ariaLabel={t("post.post_modal.close_button_aria_label")}
            />
          </TippyV2>
        </div>
      </div>
    </Modal.HeaderActions>
  );
};
