import { t } from "@/i18n-js/instance";
import {
  useCurrentCommunityMember,
  usePunditUserContext,
} from "@circle-react/contexts/punditUserContext";
import { createNewCommunityPath } from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

/* cspell:disable-next-line */
const pendoParam = "?pendo=0oguJmJrou1SpPPXRwwyzbd4Wlo";

export const NewCommunityButton = () => {
  const currentCommunityMember = useCurrentCommunityMember();
  const { isV3Enabled } = useIsV3();
  const isCommunityAdmin = currentCommunityMember?.is_admin;
  const { currentCommunity } = usePunditUserContext();
  const pendoNewCommunityGuidePath = window.location.href + pendoParam;

  const isCurrentPagePlansPage = window.location
    .toString()
    .includes("settings/plans");

  const isExpiredTrialCommunity =
    currentCommunity?.plan_status === "Expired Trial";

  const shouldShowPendoUrl =
    isCommunityAdmin && isCurrentPagePlansPage && isExpiredTrialCommunity;

  const communitySwitcherUrl = shouldShowPendoUrl
    ? pendoNewCommunityGuidePath
    : createNewCommunityPath();

  if (isV3Enabled) {
    return (
      <TippyV2
        content={t("create_new_community")}
        placement="right"
        arrow={false}
        offset={[0, 16]}
        interactive={false}
      >
        <a
          className="hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover text-c-sidebar hover:text-c-sidebar focus:text-c-sidebar flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg transition-colors duration-150"
          href={communitySwitcherUrl}
          aria-label={t("create_new_community")}
        >
          <Icon type="20-plus-v3" size={20} />
        </a>
      </TippyV2>
    );
  }

  return (
    <div className="relative mb-4 flex h-8 w-8">
      <TippyV2
        content={t("create_new_community")}
        placement="right"
        arrow={false}
        offset={[0, 16]}
        interactive={false}
      >
        <a
          className="bg-c-header-hover text-c-sidebar focus:text-c-sidebar hover:text-c-sidebar flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg uppercase transition-colors duration-200"
          href={communitySwitcherUrl}
        >
          <Icon type="plus-v2" size={16} />
        </a>
      </TippyV2>
    </div>
  );
};
