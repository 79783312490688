import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { TiptapEditor } from "@circle-react-uikit/TipTap";
import { Typography } from "@circle-react-uikit/Typography";

export interface HistoryDetailViewNotificationProps {
  body: {
    path: string;
    message: {
      richTextBody: object;
    };
  };
}

export const HistoryDetailViewNotification = ({
  body,
}: HistoryDetailViewNotificationProps) => {
  const { path, message: richTextBody } = body;
  const { currentCommunity } = usePunditUserContext();
  const { root_url: rootUrl } = currentCommunity;

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t(
              "settings.workflows.action_messages.send_notification_to_member.path",
            )}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Typography.LabelSm>{`https://${rootUrl}/${path}`}</Typography.LabelSm>
        </div>
      </div>

      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t(
              "settings.workflows.action_messages.send_notification_to_member.message",
            )}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <TiptapEditor
            editable={false}
            rich_text_body={richTextBody}
            editorClassName="text-sm inline-flex flex-col w-full"
          />
        </div>
      </div>
    </div>
  );
};
