import { t } from "@/i18n-js/instance";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { PricingTabAddPriceModal } from "./AddPriceModal";
import { PricingTabPriceList } from "./PriceList";
import { PricingSettings } from "./PricingSettings";
import { usePricingTabContext } from "./hooks/usePricingTabContext";

const localeRoot =
  "settings.paywalls.modal.tabs.pricing.mobile_tab.empty_state";

export const MobilePricingTab = () => {
  const { getPrices, isShowingAddPriceModal, showAddPriceModal } =
    usePricingTabContext();
  const onSecondaryClick = () => {
    window.open(settingsNavPath.plans.brandedApps, "_blank");
  };
  const prices = getPrices();

  return (
    <>
      {!prices || !prices.length ? (
        <div className="m-4 flex flex-col items-center justify-center gap-4 rounded-lg border py-20">
          <EmptyState
            header={t([localeRoot, "title"])}
            description={t([localeRoot, "description"])}
            onPrimaryClick={showAddPriceModal}
            primaryLabel={t([localeRoot, "cta_primary"])}
            onSecondaryClick={onSecondaryClick}
            secondaryLabel={t([localeRoot, "cta_secondary"])}
          />
        </div>
      ) : (
        <div className="mr-4 flex flex-col gap-y-8 pb-8">
          <PricingTabPriceList />
          <PricingSettings />
        </div>
      )}
      {isShowingAddPriceModal() && <PricingTabAddPriceModal />}
    </>
  );
};
