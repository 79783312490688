import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

export const ActionLabelWithUpgradeIcon = ({
  label,
  showUpgradeButton,
}: {
  label: string;
  showUpgradeButton: boolean;
}) => (
  <div className="group relative flex items-center">
    {label}
    {showUpgradeButton && (
      <div className="absolute right-0 hidden group-hover:block">
        <span className="text-default border-dark bg-primary inline-flex h-5 items-center gap-1 rounded-2xl border px-1.5 py-[3px] text-[0.6rem] font-medium">
          <Icon
            type="12-arrow-up"
            size={12}
            className="!h-3 !w-3"
            useWithFillCurrentColor
            aria-hidden
          />
          {t("upgrade")}
        </span>
      </div>
    )}
  </div>
);
