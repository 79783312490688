import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useThemeContext } from "@circle-react/providers";
import type { Community } from "@circle-react/types";

export interface CommunityBrandProps {
  currentCommunity?: Community;
}

export const CommunityBrand = ({ currentCommunity }: CommunityBrandProps) => {
  const { isDarkAppearance } = useThemeContext();
  const { isV3Enabled } = useIsV3();

  const {
    name: communityName,
    logo_url: lightLogoUrl,
    dark_logo_url: darkLogoUrl,
  } = currentCommunity || {};
  let communityLogoUrl = lightLogoUrl;

  if (isDarkAppearance && darkLogoUrl) {
    communityLogoUrl = darkLogoUrl;
  }

  if (communityLogoUrl) {
    return (
      <a href="/" className="community__brand">
        <div className="community__logo">
          <img
            loading="lazy"
            alt={t("community_logo_alt", { community_name: communityName })}
            src={communityLogoUrl}
          />
        </div>
      </a>
    );
  }

  return (
    <a href="/" className="community__brand">
      <div
        className={classNames("brand-icon brand-icon__initial", {
          "!rounded-xl": isV3Enabled,
        })}
      />
      <div className="name">{communityName}</div>
    </a>
  );
};
