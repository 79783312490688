import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCommunityBotProfileSearch } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useCommunityBotProfileSearch";
import { newChatRoomPath } from "@circle-react/helpers/urlHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Button } from "@circle-react-shared/uikit/Button";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { AgentAvatar } from "./AgentAvatar";

export const EmptyState = () => {
  const { botProfiles, isBotsLoading } = useCommunityBotProfileSearch({
    name: "",
  });
  const { setCurrentBotProfile } = useCommunityInboxStore();
  const history = useHistory();

  if (isBotsLoading) {
    return <Loader variant="fullscreen" />;
  }

  if (!botProfiles.length) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-start pt-12">
        <Typography.LabelSm>{t("messaging.no_messages")}</Typography.LabelSm>
      </div>
    );
  }

  const botProfile = botProfiles[0];
  const handleNewConversationClick = () => {
    setCurrentBotProfile(botProfile);
    history.push(newChatRoomPath());
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-3">
      <div className="flex flex-col items-center gap-2">
        <AgentAvatar profile={botProfile} />
        <Typography.TitleSm as="h2" weight="semibold">
          {botProfile.name}
        </Typography.TitleSm>
        <div className="text-center text-sm font-normal leading-5 tracking-tighter">
          <TiptapEditor editable={false} rich_text_body={botProfile.bio} />
        </div>
      </div>
      <Button
        variant="primary"
        type="button"
        onClick={handleNewConversationClick}
        className="text-[13px] font-semibold"
      >
        {t("community_inbox.new_conversation")}
      </Button>
    </div>
  );
};
