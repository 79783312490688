import { useQueryClient } from "react-query";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { getBroadcastQueryKey } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { BroadcastDataProvider } from "@circle-react-shared/Emails/BroadcastDataContext";
import { EmailEditor } from "@circle-react-shared/Emails/EmailEditorModal";

export function FormEmailContent() {
  const { broadcastId, id: workflowId } = useParams<{
    broadcastId: string;
    id: string;
  }>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const updateBroadcastProps = useUpdateBroadcast({
    id: Number(broadcastId),
  });
  const { path: routePath } = useRouteMatch();

  const closeFullScreenEditor = async () => {
    await queryClient.invalidateQueries(
      getBroadcastQueryKey(Number(broadcastId)),
    );
    const pathWithoutBroadcastId = routePath.replace(
      "send-email/:broadcastId",
      "",
    );
    history.push(generatePath(pathWithoutBroadcastId, { id: workflowId }));
  };

  return (
    <BroadcastDataProvider id={Number(broadcastId)}>
      <EmailEditor
        update={updateBroadcastProps}
        onClose={closeFullScreenEditor}
      />
    </BroadcastDataProvider>
  );
}
