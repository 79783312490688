import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { CHAT_SORT_VALUES } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/constants";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import type { ChatSortType } from "@circle-react/types/CommunityBot/ChatSort";

export const useChatList = (
  params: { filter?: string; per_page?: number; sort?: ChatSortType } = {},
) => {
  const { filter = "", per_page = 20, sort = CHAT_SORT_VALUES.LATEST } = params;
  const { setChats, setTotalChatsCount } = useCommunityInboxStore();
  const fetchChats = (page: number) =>
    reactQueryGet(
      internalApi.communityInbox.index({
        params: {
          page,
          filter,
          per_page,
          sort,
        },
      }),
    );

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["community-inbox", filter, per_page, sort],
    ({ pageParam = 1 }) => fetchChats(pageParam),
    {
      select: ({ pages = [] }: { pages: Array<any> }) =>
        // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
      onSuccess: (data: any) => {
        const chatType = params.filter === "mine" ? "member" : "admin";
        setChats(data?.pages || [], chatType);
        setTotalChatsCount(
          data?.pagesList?.[data.pagesList.length - 1]?.count || 0,
        );
      },
    },
  );
  const chats = data?.pages || [];
  return {
    chats,
    isChatsLoading: isLoading,
    hasNextPage,
    fetchNextPage,
  };
};
