import type { ContextType, ReactNode } from "react";
import { useMemo } from "react";
import { useProfileInfo } from "@/react/hooks/profile/useProfileInfo";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import type { AudienceType } from "@circle-react/types/AudienceList";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { AdminAudienceEditModalContext } from "./AdminAudienceEditModalContext";

const params = {
  page_name: PROFILE_PAGES.EDIT_PROFILE,
};

export interface AdminAudienceEditModalContextProviderProps {
  children: ReactNode;
  publicId: string;
  type: AudienceType;
}

export const AdminAudienceEditModalContextProvider = ({
  children,
  publicId,
  type,
}: AdminAudienceEditModalContextProviderProps) => {
  const { getProfileInfoQuery: profileInfoQuery } = useProfileInfo({
    publicId,
    params,
    type,
  });

  const value = useMemo<ContextType<typeof AdminAudienceEditModalContext>>(
    () => ({
      publicId,
      profileInfoQuery,
      type,
    }),
    [publicId, profileInfoQuery, type],
  );

  if (profileInfoQuery.isLoading) {
    return <Loader />;
  }

  return (
    <AdminAudienceEditModalContext.Provider value={value}>
      {children}
    </AdminAudienceEditModalContext.Provider>
  );
};
