import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { InfoBox } from "@circle-react/components/shared/uikit/InfoBox";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { useAnalyticsInfo } from "./hooks/useAnalyticsInfo";

export const AnalyticsInfo = () => {
  const { onboardingData, isOnboardingDataLoading, mutateCommunityOnboarding } =
    useAnalyticsInfo();

  const history = useHistory();
  const { isV3Enabled } = useIsV3();

  if (isOnboardingDataLoading || onboardingData?.paywall_analytics_info) {
    return null;
  }

  const redirectToAnalytics = () => {
    mutateCommunityOnboarding();
    history.push(settingsNavPath.analytics_v2.payments);
  };

  return (
    <InfoBox
      className={classNames(
        "border-primary mx-6 flex !w-auto justify-between border",
        {
          "mt-6": !isV3Enabled,
          "mt-4 lg:mx-9": isV3Enabled,
        },
      )}
    >
      <Typography.LabelSm>
        <SanitizeHTMLString
          content={t("analytics_v2.paywall_snapshots.banner")}
        />
      </Typography.LabelSm>
      <Typography.LabelSm>
        <Button
          onClick={redirectToAnalytics}
          variant="plain"
          className="!py-0 font-normal"
        >
          {t("analytics_v2.paywall_snapshots.button")}
        </Button>
      </Typography.LabelSm>
    </InfoBox>
  );
};
