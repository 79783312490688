import { useState } from "react";
import { useMutation } from "react-query";
import i18n from "@/i18n-js/instance";
import { billingInfoApi } from "@circle-react/api/paywalls/communityMembers/billingInfoApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { CommunityMemberBillingInfo } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";
import type { OnStripeElementAddressChangeEvent } from "../../StripeElements/types";
import { useBillingInfoContext } from "./useBillingInfoContext";

export const useBillingInfo = () => {
  const { success: showSuccess, error: showError } = useToast();

  const {
    communityMember,
    billingInfo: communityMemberBillingInfo,
    onSuccess,
    asPrerequisite: isAsPrerequisite,
  } = useBillingInfoContext();

  const [isBillingInfoComplete, setIsBillingInfoComplete] =
    useState<boolean>(false);
  const [billingInfo, setBillingInfo] = useState<
    Omit<CommunityMemberBillingInfo, "id">
  >({
    address_line1: communityMemberBillingInfo?.address_line1,
    address_line2: communityMemberBillingInfo?.address_line2,
    address_city: communityMemberBillingInfo?.address_city,
    address_state: communityMemberBillingInfo?.address_state,
    address_postal_code: communityMemberBillingInfo?.address_postal_code,
    address_country: communityMemberBillingInfo?.address_country,
  });

  const onStripeElementAddressChangeEvent = (
    event: OnStripeElementAddressChangeEvent,
  ) => {
    const { value, complete: isComplete } = event;
    const { address } = value;

    setBillingInfo({
      address_line1: address.line1,
      address_line2: address.line2,
      address_city: address.city,
      address_state: address.state,
      address_postal_code: address.postal_code,
      address_country: address.country,
    });
    setIsBillingInfoComplete(isComplete);
  };

  const createOrUpdateBillingInfo = async () => {
    if (communityMemberBillingInfo.id) {
      return billingInfoApi.update(communityMember.id, billingInfo);
    }
    return billingInfoApi.create(communityMember.id, billingInfo);
  };
  const billingInfoMutation = useMutation<CommunityMemberBillingInfo, ApiError>(
    createOrUpdateBillingInfo,
    {
      onSuccess: () => {
        showSuccess(
          i18n.t("paywalls.community_members.billing_info_form.success"),
        );
        onSuccess();
      },
      onError: error => {
        console.error(error);
        showError(error.message);
      },
    },
  );
  const onSaveBillingInfoClick = () => {
    billingInfoMutation.mutate();
  };

  const { isLoading: isUpdatingBillingInfo } = billingInfoMutation;

  return {
    communityMember,
    asPrerequisite: isAsPrerequisite,
    billingInfo: communityMemberBillingInfo,
    isBillingInfoComplete,
    onStripeElementAddressChangeEvent,
    onSaveBillingInfoClick,
    isUpdatingBillingInfo,
  };
};
