import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptyChat = () => (
  <div className="flex h-[calc(100dvh-112px)] flex-col items-center justify-center">
    <Typography.LabelMd weight="semibold">
      {t("community_inbox.chats.no_conversations")}
    </Typography.LabelMd>
    <Typography.LabelSm>
      {t("community_inbox.chats.no_conversations_description")}
    </Typography.LabelSm>
  </div>
);
