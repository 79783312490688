import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import type { MenuBarProps } from "@circle-react-shared/uikit/TipTap/MenuBar";
import { MenuIconButton } from "@circle-react-shared/uikit/TipTap/MenuBar/MenuIconButton";

export const MenuBar = ({
  isSubmitting,
  isUploading,
  editorHasNoContent,
  handleSubmit,
}: MenuBarProps) => (
  <TippyV2
    placement="top"
    interactive={false}
    content={t("community_inbox.send_message")}
  >
    <MenuIconButton
      type="submit"
      onClick={handleSubmit}
      disabled={isSubmitting || editorHasNoContent() || isUploading}
      id="tiptap-send"
      aria-label={t("community_inbox.send_message")}
      icon={isSubmitting || isUploading ? "loader" : "20-send"}
      isLight={!editorHasNoContent()}
      shouldAnimateSpin={isUploading || isSubmitting}
    />
  </TippyV2>
);
