import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { delay } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { getWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { usePunditUserContext } from "@circle-react/contexts";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Typography } from "@circle-react-uikit/Typography";
import { availableActions, useEligibleActions } from "../Actions";

export const ActionSelector = ({ index = 0 }) => {
  const actionTypeName = `actions.${index}.action_type`;
  const actionApiParamsName = `actions.${index}.api_params`;
  const { watch, setValue, clearErrors, resetField } = useFormContext();
  const [areActionLablesUpdated, setAreActionLablesUpdated] = useState(false);

  const actionInputMap = useMemo(
    () =>
      availableActions.reduce((acc, availableAction) => {
        const { value, formComponent } = availableAction;
        acc.set(value, formComponent);
        return acc;
      }, new Map()),
    [],
  );

  const actionType = watch(actionTypeName);
  const ActionInputsComponent = actionInputMap.get(actionType);
  const { eligibleActions } = useEligibleActions();
  const upgradePlanModal = useUpgradePlanModal();
  const { currentCommunity = {} } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;

  useEffect(() => {
    const actionObject = availableActions.find(
      availableAction => availableAction.value === actionType,
    );
    if (actionObject && isEligiblePlanTier(actionObject, currentPlanTier))
      updateActionLabel(actionType, actionObject);
    setAreActionLablesUpdated(true);
  }, [actionType, currentPlanTier]);

  const handleSelectionChange = selection => {
    clearErrors(actionTypeName);
    clearErrors(actionApiParamsName);
    setValue(actionApiParamsName, {});

    const actionObject = availableActions.find(
      availableAction => availableAction.value === selection,
    );

    if (!isEligiblePlanTier(actionObject, currentPlanTier)) {
      const planTierForUpgrade = actionObject.eligiblePlanTiers[0]; // Select the minimum plan tier for upgrade

      // Show the upgrade plan modal
      upgradePlanModal.show({
        planTier: planTierForUpgrade,
        source: getWorkflowSource(),
        subheading: t(
          `settings.workflows.view.actions.${selection}.plan_upgrade.subheader`,
          {
            planName: capitalizeFirstLetter(planTierForUpgrade),
          },
        ),
        onSuccess: () => {
          upgradePlanModal.hide();
        },
        successButtonLabel: t("settings.workflows.upgrade_modal.done"),
        usedForWorkflows: true,
        showPlanDetails: true,
      });

      // Reset the action type field to the default value
      delay(() => {
        resetField(actionTypeName);
      });
    } else {
      updateActionLabel(selection, actionObject);
    }
  };

  const capitalizeFirstLetter = str =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const updateActionLabel = (selection, actionObject) => {
    if (typeof actionObject.label !== "string")
      actionObject.label = t(`settings.workflows.form.actions.${selection}`);
  };

  const isEligiblePlanTier = (actionObject, currentPlanTier) =>
    !actionObject.eligiblePlanTiers ||
    actionObject.eligiblePlanTiers.includes(currentPlanTier);

  return (
    <div className="mb-9 flex flex-col gap-9">
      {areActionLablesUpdated && (
        <FormTextSelect
          name={actionTypeName}
          groupBy={option => option.group}
          label={
            <Typography.LabelSm weight="semibold">
              {t("settings.workflows.form.choose_an_action")}
            </Typography.LabelSm>
          }
          labelClassName="!mb-2 !text-sm"
          rules={{
            required: t("settings.workflows.form.errors.required"),
          }}
          options={eligibleActions}
          onChange={handleSelectionChange}
        />
      )}
      {ActionInputsComponent && <ActionInputsComponent />}
    </div>
  );
};

ActionSelector.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
};
