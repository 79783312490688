import { forwardRef, useRef, useState } from "react";
import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import type { TrixEditorRefObject } from "@circle-react-shared/TrixEditor";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Button } from "@circle-react-uikit/Button";
import { fetchRequest } from "../../../helpers/backendRequestHelpers";
import { commentsPath, newSessionPath } from "../../../helpers/urlHelpers";
import RichTextInput from "../../RichTextInput";

export interface FormProps {
  space: any;
  post: any;
  parent_comment_id?: any;
  communityMember: any;
  onNewComment?: (comment: any) => void;
}

export const Form = forwardRef<TrixEditorRefObject, FormProps>(
  (
    { space, post, parent_comment_id, communityMember, onNewComment },
    formRef,
    // eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
  ) => {
    const divRef = useRef(null);
    const [count, setCounter] = useState(0);
    const [body, setBody] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    if (!post?.is_comments_enabled || !communityMember || !space?.id) {
      return null;
    }

    const containerClassnames = classNames("post__reply", {
      "reply-to-parent": !!parent_comment_id,
      "reply-to-post": !parent_comment_id,
    });

    const handleSubmit = async (event: any) => {
      event && event.preventDefault();
      if (!body || isSubmitted) {
        return;
      }

      setIsSubmitted(true);

      const params = {
        comment: {
          body,
          parent_comment_id: parent_comment_id,
          post_id: post.id,
        },
      };
      const response = await fetchRequest(commentsPath(), {
        method: "POST",
        body: JSON.stringify(params),
      });
      if (response.ok) {
        setBody("");
        const data = await response.json();
        setCounter(count + 1);
        onNewComment &&
          onNewComment({ ...data.comment, html: data.comment_html });
        window.loadOembed && window.loadOembed();
        setIsSubmitted(false);
      }
    };

    const onClick = () => {
      if (!communityMember) {
        window.location.href = newSessionPath({
          params: {
            alert: "Hey, there! You need to sign in to write a comment.",
          },
        });
      }
    };

    const onChange = (event: any) => {
      setBody(event.target.innerHTML);
    };

    const mayBeSubmitForm = (event: any) => {
      if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
        void handleSubmit(event);
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- We have a <form> element in the children
      <div
        role="button"
        ref={divRef}
        className={containerClassnames}
        onClick={onClick}
        onKeyDown={mayBeSubmitForm}
        tabIndex={0}
      >
        <UserImage
          src={communityMember.avatar_url}
          name={communityMember.name}
          size="10"
        />
        <form onSubmit={handleSubmit}>
          <RichTextInput
            ref={formRef}
            id={`comment-body-${post.id}`}
            key={count}
            placeholder={t("write_a_comment")}
            name="body"
            is_private_space={space.is_private}
            space_id={space.id}
            dataIsPrivateSpace={space.is_private}
            dataSpaceId={space.id}
            value={body}
            onChange={onChange}
          />
          <div className="btn-post-reply absolute bottom-4 right-4">
            <Button variant="primary" type="submit" disabled={!body}>
              {t("comments.post")}
            </Button>
          </div>
        </form>
      </div>
    );
  },
);
Form.displayName = "Form";
