import { t } from "@/i18n-js/instance";
import { useDisableModal } from "@circle-react/pages/BotBuilder/DisableModal";
import { useEnableModal } from "@circle-react/pages/BotBuilder/EnableModal";
import { ToggleWithLoader } from "@circle-react-shared/uikit/ToggleWithLoader";

export const AgentEnableSwitch = ({ row }: { row: any }) => {
  const enableModal = useEnableModal(row.original);
  const disableModal = useDisableModal(row.original);

  return (
    <div id={`toggle-wrapper-agent-${row.original.id}`}>
      <ToggleWithLoader
        isChecked={row.original?.enabled}
        checkedLabel={t("settings.community_bot.agents.on_value")}
        uncheckedLabel={t("settings.community_bot.agents.off_value")}
        tooltipCheckedLabel=""
        tooltipUncheckedLabel=""
        tooltipPlacement="bottom"
        isLoading={false}
        isDisabled={false}
        onChange={() => {
          if (row.original?.enabled) {
            void disableModal.show();
          } else {
            void enableModal.show();
          }
        }}
        containerClassName=""
        className=""
      />
    </div>
  );
};
