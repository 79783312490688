import * as yup from "yup";
import I18n from "@/i18n-js/instance";

export const CREATE_CONVERSATION_SCHEMA = yup.object().shape({
  text: yup
    .string()
    .required(I18n.t("support_widget.send_message_form.error.text")),
  type: yup
    .string()
    .required(I18n.t("support_widget.send_message_form.error.type")),
  area: yup
    .string()
    .required(I18n.t("support_widget.send_message_form.error.area")),
  is_important: yup.boolean().notRequired(),
});

export const UPDATE_CONVERSATION_SCHEMA = yup.object().shape({
  comment: yup.string().required("This field is required"),
});
