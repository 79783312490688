import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { chatRoomsPath } from "@circle-react/helpers/urlHelpers";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { Icon } from "@circle-react-shared/Icon";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";
import { useCommunityChat } from "../hooks/useCommunityChat";
import { Railbar } from "./Railbar";

export interface HeaderProps {
  chatRoomId: number;
  isDms: boolean;
}

export const Header = ({ chatRoomId, isDms }: HeaderProps) => {
  const { chat = {}, isChatLoading } = useCommunityChat(chatRoomId);
  const { shouldShowRailBar, toggleShowRailBar } = useRailbar();
  const history = useHistory();
  const isSmallScreen = useSmOrMdOrLgScreenMediaQuery();
  const {
    community_member: communityMember,
    community_bot_profile: communityBotProfile,
  } = chat;

  const author = isDms ? communityBotProfile : communityMember;
  return (
    <div className="border-primary bg-primary h-16 w-full border-b px-6 py-4">
      {isChatLoading ? (
        <RowContainer full>
          <Row xxl extraLong />
        </RowContainer>
      ) : (
        <div
          className={classNames(
            "flex items-center",
            isSmallScreen ? "justify-start" : "justify-between",
          )}
        >
          {isSmallScreen && (
            <button
              className="mr-2"
              type="button"
              onClick={() => history.push(chatRoomsPath())}
            >
              <Icon type="20-arrow-left" size={20} />
            </button>
          )}
          <SpaceHeaderTitleText>{author?.name}</SpaceHeaderTitleText>
          <button
            type="submit"
            className="mt-0.5 hidden lg:block"
            onClick={toggleShowRailBar}
          >
            <Icon
              aria-label={
                shouldShowRailBar
                  ? t("chat_space.header.collapse_railbar")
                  : t("chat_space.header.expand_railbar")
              }
              className="text-dark"
              useWithFillCurrentColor
              type={
                shouldShowRailBar ? "rail-collapse-right" : "rail-collapse-left"
              }
            />
          </button>
        </div>
      )}
      {shouldShowRailBar && <Railbar author={author} isDms={isDms} />}
    </div>
  );
};
