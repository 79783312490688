import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface DropdownMenuProps<T> {
  className?: string;
  items: T[];
  activeItem: T;
  localeScope: string;
  onItemClick: (item: T) => void;
}

export const DropdownMenu = <T extends string>({
  className,
  items,
  activeItem,
  localeScope,
  onItemClick,
}: DropdownMenuProps<T>) => (
  <Dropdown
    direction="bottom-end"
    className={className}
    buttonClassName="w-full"
    button={
      <Dropdown.MenuButton className="bg-tertiary hover:bg-overlay-dark flex justify-between rounded-md !py-1.5 px-3 transition-colors">
        <div className="flex gap-x-1.5">
          <Icon size={16} type="16-sort-icon" className="text-dark" />
          <Typography.LabelSm weight="semibold">
            {t([localeScope, activeItem])}
          </Typography.LabelSm>
        </div>
        <Icon
          size={16}
          type="16-chevron-triangle-down-small"
          className="text-dark"
        />
      </Dropdown.MenuButton>
    }
  >
    {items.map(item => (
      <Dropdown.Item key={item} onClick={() => onItemClick(item)}>
        <Dropdown.ItemLink type="button" active={activeItem === item}>
          {t([localeScope, item])}
        </Dropdown.ItemLink>
      </Dropdown.Item>
    ))}
  </Dropdown>
);
