import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { rewriteLoginRoutes } from "@/react/helpers/loginHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { newSessionPath } from "../../../helpers/urlHelpers";
import { List } from "../List";

export const Show = ({
  comment,
  communityMember,
  defaultShowReplyForm = false,
  ...rest
}) => {
  const containerRef = useRef(null);
  const { href } = window.location;

  const commentContentRef = useCallback(
    node => {
      if (node !== null) {
        rewriteLoginRoutes(node);
      }
    },
    [href],
  );

  const [showReplyForm, setShowReplyForm] = useState(defaultShowReplyForm);
  const [page, setPage] = useState();
  const formRef = useRef(null);
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const handleClick = event => {
      event.preventDefault();
      if (communityMember) {
        setShowReplyForm(true);
        const { sgid, content, name } = event.currentTarget.dataset;
        if (sgid) {
          setTimeout(() => {
            formRef.current.focus();
            if (formRef.current.shouldInsert(sgid)) {
              const editor =
                formRef.current.editorRef.current.editor.composition;
              editor.attachments.forEach(function (attachment) {
                editor.removeAttachment(attachment);
              });
              formRef.current.insertMention({ sgid, content, name });
            }
          }, 100);
        }
      } else {
        window.location.push(
          newSessionPath({
            params: { notice: I18n.t("comments.log_in_to_add_a_comment") },
          }),
        );
      }
    };

    containerRef.current
      .querySelectorAll('[data-action="toggle-reply-box"]')
      .forEach(replyElement => {
        replyElement.addEventListener("click", handleClick);
      });
    return () => {
      containerRef.current
        .querySelectorAll('[data-action="toggle-reply-box"]')
        .forEach(replyElement => {
          replyElement.removeEventListener("click", handleClick);
        });
    };
  }, [
    containerRef,
    setShowReplyForm,
    showReplyForm,
    page,
    formRef,
    formRef.current,
  ]);

  const containerClassnames = classNames(
    `post--comment comment--${comment.id}`,
  );
  const id = `comment_wrapper_${comment.id}`;
  return (
    <div ref={containerRef} className={containerClassnames} id={id}>
      <div data-controller="trix-attachment-preview">
        <div className="text-dark" ref={commentContentRef} />
      </div>
      {Boolean(!comment?.parent_comment_id) && (
        <div className="post__replies">
          <List
            {...rest}
            communityMember={communityMember}
            comments={comment.replies}
            hasNextPage={comment.replies_count > 1}
            parent_comment_id={comment.id}
            parent_comment={comment}
            showReplyForm={showReplyForm}
            onPageChange={setPage}
            formRef={formRef}
          />
        </div>
      )}
    </div>
  );
};

Show.propTypes = {
  comment: PropTypes.object,
  communityMember: PropTypes.object,
  defaultShowReplyForm: PropTypes.bool,
  rest: PropTypes.any,
};
