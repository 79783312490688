import type { UserImageProps } from "@circleco/ui-kit";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import "./styles.scss";

export interface ParticipantAvatarProps {
  name: string;
  src: string;
  size?: "lg" | "md" | "sm";
  variant?: "circle" | "rounded-square" | "full";
}

/** @deprecated Please use the new UserImage component from @circleco/ui-kit */
export const ParticipantAvatar = ({
  name,
  src,
  size = "sm",
  variant = "rounded-square",
}: ParticipantAvatarProps) => {
  let imageSize: UserImageProps["size"] = "10";
  if (size === "lg") imageSize = "16";
  if (size === "md") imageSize = "7";

  return (
    <div
      className={classNames("participant-avatar relative", {
        "participant-avatar--lg": size === "lg",
        "participant-avatar--md": size === "md",
        "participant-avatar--sm": size === "sm",
        "participant-avatar--circle": variant === "circle",
        "participant-avatar--full": variant === "full",
        "participant-avatar--rounded-square": variant === "rounded-square",
      })}
    >
      <UserImage name={name} src={src} size={imageSize} />
    </div>
  );
};
