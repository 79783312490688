import { ChatError } from "@/react/components/Spaces/ChatSpace/Messenger/ChatError";
import type { ChatSortType } from "@circle-react/types/CommunityBot/ChatSort";
import { ErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { ChatListComponent } from "./ChatList";

export const ChatList = ({ activeSort }: { activeSort: ChatSortType }) => (
  <ErrorBoundary renderFunc={ChatError}>
    <ChatListComponent activeSort={activeSort} />
  </ErrorBoundary>
);
