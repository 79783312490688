import PropTypes from "prop-types";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { Action } from "../Action";
import { useActionGroup } from "./useActionGroup";

const iconSizes = {
  md: 16,
  lg: 24,
};

export const ActionGroup = ({
  actions: actionsProp = [],
  size = "lg",
  truncationLimit = 2,
  className,
}) => {
  const { actions, remainingActionsCount, remainingItemsTooltip } =
    useActionGroup({
      actions: actionsProp,
      truncationLimit,
    });

  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();

  const iconSize = iconSizes[size] || iconSizes.lg;

  if (actionsProp.length === 0 || truncationLimit < 1) {
    return <div className="py-[25px] leading-none">-</div>;
  }

  const remainingText = `+${remainingActionsCount}`;

  return (
    <div
      className={classNames(
        "flex items-center",
        {
          "gap-1": size === "md",
          "gap-2": size === "lg",
        },
        className,
      )}
    >
      {actions.map(({ title, icon }, index) => (
        <Action tooltipContent={title} key={`${icon}-${index}`} size={size}>
          {isWorkflowsV2Enabled ? (
            <div className="bg-tertiary flex h-8 max-h-8 w-8 max-w-[2rem] shrink-0 items-center justify-center rounded-full">
              <Icon
                type={icon}
                size={16}
                className="text-dark !h-4 !w-4 shrink-0"
              />
            </div>
          ) : (
            <Icon
              type={`${iconSize}-workflow-${icon}`}
              size={iconSize}
              useWithFillCurrentColor
              className={classNames({
                "!w-4": size === "md",
                "!w-6": size === "lg",
              })}
            />
          )}
        </Action>
      ))}
      {remainingActionsCount > 1 && (
        <Action tooltipContent={remainingItemsTooltip} size={size}>
          <div
            className={classNames({
              "bg-tertiary flex h-8 max-h-8 w-8 max-w-[2rem] shrink-0 items-center justify-center rounded-full":
                isWorkflowsV2Enabled,
              "flex items-center": !isWorkflowsV2Enabled,
            })}
          >
            <Typography.LabelSm weight="semibold">
              <span
                className={classNames("text-default flex", {
                  "text-xxs": size === "md",
                  "text-dark": isWorkflowsV2Enabled,
                  "text-default": !isWorkflowsV2Enabled,
                })}
              >
                {remainingText}
              </span>
            </Typography.LabelSm>
          </div>
        </Action>
      )}
    </div>
  );
};

ActionGroup.propTypes = {
  actions: PropTypes.array,
  size: PropTypes.oneOf(["md", "lg"]),
  truncationLimit: PropTypes.number,
  shouldUseTooltip: PropTypes.bool,
  className: PropTypes.string,
};
