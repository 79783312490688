import type { ReactNode } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { FormEmailContent } from "./Actions/Member/sendEmail/FormEmailContent";

export const getSendEmailPath = ({
  path,
  broadcastId = ":broadcastId",
}: {
  path: string;
  broadcastId?: string | number;
}) => `${path}/send-email/${broadcastId}`;

export function BroadcastContentEditorRouteWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const { path } = useRouteMatch();

  return (
    <>
      {children}
      <Route path={getSendEmailPath({ path })}>
        <FormEmailContent />
      </Route>
    </>
  );
}
