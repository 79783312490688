import { t } from "@/i18n-js/instance";
import { COURSE_TYPES } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/constants";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import type { Space } from "@circle-react/types/Space";

export const isSelfPacedCourseSpace = (space: Space): boolean =>
  space?.post_type === "course" &&
  space?.course_setting?.course_type === COURSE_TYPES.self_paced;

export const isStructuredCourseSpace = (space: Space): boolean =>
  space?.post_type === "course" &&
  space?.course_setting?.course_type === COURSE_TYPES.structured;

export const isScheduledCourseSpace = (space: Space): boolean =>
  space?.post_type === "course" &&
  space?.course_setting?.course_type === COURSE_TYPES.scheduled;

export const isDrippedCourseSpace = (space: Space): boolean =>
  isScheduledCourseSpace(space) || isStructuredCourseSpace(space);

export const drippedAtTippyContent = ({
  drippedAt,
  dripDelay,
  needsToCompletePreviousLesson,
}: {
  drippedAt: string;
  dripDelay: number;
  needsToCompletePreviousLesson: boolean;
}): string => {
  if (needsToCompletePreviousLesson) {
    return t("courses.edit.lesson.complete_previous_lesson_tooltip");
  }
  if (drippedAt) {
    const date = drippedAtDateTime(drippedAt);
    return `${t("courses.edit.lesson.available_on_tooltip")} ${date}`;
  }
  const unit = computeDripDelayUnit(dripDelay);
  const count = calculateDripDelayValue(dripDelay);
  const delayText = computeDripDelayCountText(count, unit);
  return t("courses.edit.lesson.available_after_tooltip", {
    drip_delay: delayText,
  });
};

export const drippedAtDateTime = (drippedAt: string): string =>
  formattedDateTime({
    dateTime: new Date(drippedAt),
    format: "date_time_with_short_timezone",
  });

export const computeDripDelayUnit = (dripDelay: number) =>
  // we only get days from the backend
  // if it's divisible by 7 we set the unit to weeks
  dripDelay % 7 !== 0 || dripDelay === 0 ? "days" : "weeks";

export const calculateDripDelayValue = (dripDelay: number) =>
  // we only get days from the backend, so we need to convert it
  // to weeks if it's divisible by 7
  dripDelay % 7 === 0 ? dripDelay / 7 : dripDelay;

export const computeDripDelayCountText = (dripDelay: number, unit: string) =>
  // this returns "immediately" or 1 ,2, day / days | week / weeks
  dripDelay === 0
    ? t("courses.edit.sections.drip_schedule.form.immediately")
    : `${dripDelay} ${computeDripDelayUnitText(dripDelay, unit)}`;

export const computeDripDelayUnitText = (dripDelay: number, unit: string) =>
  // this returns day, days, week, weeks depending on the drip delay count
  t(`courses.edit.sections.drip_schedule.form.${unit}`, {
    count: dripDelay,
  });
