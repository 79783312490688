import PropTypes from "prop-types";
import classNames from "classnames";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";

const sizes = {
  md: "w-8 h-8",
  lg: "min-w-[2.5rem] h-10",
};

export const Action = ({
  tooltipContent,
  size = "lg",
  className,
  children,
}) => {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();

  const Content = () => (
    <div
      className={classNames(
        "flex items-center justify-center",
        {
          "border-dark text-default bg-primary rounded-full border":
            !isWorkflowsV2Enabled,
        },
        !isWorkflowsV2Enabled && sizes[size],
        className,
        {
          "cursor-pointer": tooltipContent,
        },
      )}
    >
      {children}
    </div>
  );

  if (tooltipContent) {
    return (
      <Tippy placement="top" content={tooltipContent}>
        <Content />
      </Tippy>
    );
  }

  return <Content />;
};

Action.propTypes = {
  tooltipContent: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  className: PropTypes.string,
  children: PropTypes.node,
};
