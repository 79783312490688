import { useEffect } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery, useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import {
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import "@circle-react/helpers/backendRequestHelpers";
import { chatBotPath } from "@circle-react/helpers/urlHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import type { Message } from "@circle-react/types/CommunityInbox/Message";
import { ACTIONS } from "./useScroll";

interface MessageListProps {
  id: number;
  params?: any;
  isDms?: boolean;
}

interface QueryData {
  pages: any[];
  pageParams: number[];
  pagesList: any[];
}

export const useMessageList = ({
  id,
  params = { per_page: 20 },
  isDms = false,
}: MessageListProps) => {
  const { setMessages, appendMessage, setScrollAction, setCurrentChatId } =
    useCommunityInboxStore();
  const { pathname } = useLocation();
  const history = useHistory();
  const isNewConversation = pathname.startsWith("/messages/new");

  const fetchMessages = (page: number) =>
    reactQueryGet(
      internalApi.communityInbox.fetchMessages({
        id,
        params: {
          page,
          ...params,
        },
      }),
    );

  useEffect(() => {
    setCurrentChatId(id);
  }, [id, setCurrentChatId]);

  const { isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<any, Error, QueryData>(
      ["community-inbox", "chat-room", "messages", id],
      ({ pageParam = 1 }) => fetchMessages(pageParam),
      {
        select: ({ pages = [] }) => ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
        enabled: !!id,
        getNextPageParam: (lastPage: any) =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        onSuccess: (data: any) => {
          if (data.pagesList.length === 1) {
            setScrollAction(ACTIONS.FIRST_LOAD);
          }
          setMessages(data.pages);
        },
        refetchOnWindowFocus: false,
      },
    );

  const { mutateAsync: createMessage } = useMutation(
    (formValues: any) =>
      reactQueryPost(
        internalApi.communityInbox.createMessage({
          id,
        }),
        { ...formValues },
      ),
    {
      onSuccess: (message: Message) => {
        setScrollAction(ACTIONS.NEW_MESSAGE);
        const type = isDms ? "member" : "admin";
        appendMessage(message, type);
        isNewConversation && history.push(chatBotPath({ id: String(id) }));
      },
      onError: err => {
        console.error("Error while creating message", err);
      },
    },
  );

  return {
    isMessagesLoading: isLoading,
    createMessage,
    hasNextPage,
    fetchNextPage,
    isFetching,
  };
};
