/* eslint-disable sonarjs/cognitive-complexity -- TODO: Refactor this file */
import { useState } from "react";
import { t } from "@/i18n-js/instance";
import {
  isEventActive,
  isOnlineEvent,
} from "@/react/components/Events/NewEventsSpace/shared/helpers";
import { useRecurringUnRsvpModal } from "@/react/components/Modals/RecurringUnRsvpModal";
import { AddToCalendarComponent } from "@circle-react/components/Events/NewEventsSpace/EventDetailPage/Sidebar/AddToCalendarComponent";
import { AddToCalendarConfirmationModal } from "@circle-react/components/Events/NewEventsSpace/EventDetailPage/Sidebar/AddToCalendarConfirmationModal";
import { addToCalendarHelper } from "@circle-react/components/Events/helpers/addToCalendarHelper";
import { EVENT_TYPES } from "@circle-react/components/Events/helpers/constants";
import { useRecurringFrequencyLabel } from "@circle-react/components/Events/hooks/useRecurringFrequencyLabel";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { isPublicStream } from "@circle-react/components/EventsV3/Modal/utils";
import { useLiveStreamsUsageLimits } from "@circle-react/components/LiveStreams/hooks";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useEventsFeatureFlags } from "@circle-react/hooks/useEventsFeatureFlags";
import { AddToCalendar } from "@circle-react-uikit/AddToCalendar";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { ListRecurringEventButton } from "../../../Rsvp/ListRecurringEventButton";
import { RecurringRsvpModal } from "../../../Rsvp/RecurringRsvpModal";
import { useRecurringRsvpModal } from "../../../Rsvp/RecurringRsvpModal/hooks/useRecurringRsvpModal";
import { RsvpAction } from "../../../Rsvp/RsvpAction";
import { RsvpLimitInfo } from "../../shared/RsvpLimitInfo";
import { EventFinished } from "./EventFinished";
import { JoinButton } from "./JoinButton";
import { Location } from "./Location";
import { LocationIcon } from "./LocationIcon";
import { SidebarEventTimestamp } from "./SidebarEventTimestamp";
import { TicketInfo } from "./TicketInfo";

interface SidebarProps {
  onChange: (post: any) => void;
  shouldDisplayRsvpAction?: boolean;
}

export const Sidebar = ({
  onChange,
  shouldDisplayRsvpAction,
}: SidebarProps) => {
  const { record: event, isUsedInMinimalTipTapViewModal } =
    useCurrentPostContext();
  const { selfUpdateCalendarEnabled } = useEventsFeatureFlags();
  const { currentCommunityMember } = usePunditUserContext();
  const isLoggedIn = !!currentCommunityMember;
  const {
    event_type: eventType,
    event_setting_attributes: eventData,
    recurring_setting_attributes: recurringSetting,
    upcoming_occurrences_count: comingRecurrences,
    rsvped_event: rsvpedEvent,
    rsvp_status: rsvpStatus,
    paywall_attributes: ticketData,
  } = event || {};

  const [shouldShowAddToCalendarModal, setShouldShowAddToCalendarModal] =
    useState(false);

  const dismissAddToCalendarModal = () =>
    setShouldShowAddToCalendarModal(false);

  const { frequency: recurringFrequency } = recurringSetting || {};

  const { isRecurringRsvpOpen, openRecurringRsvpModal, onRecurringModalCose } =
    useRecurringRsvpModal({ event });

  const unRsvpModal = useRecurringUnRsvpModal();

  const { isMonthlyHourLimitReached, isStorageLimitReached } =
    useLiveStreamsUsageLimits();

  const {
    starts_at: startsAt,
    ends_at: endsAt,
    hide_location_from_non_attendees: shouldHideLocationFromNonAttendees,
    live_stream_room_setting_attributes: liveStreamRoomSetting,
  } = eventData || {};

  const { label: recurringFrequencyLabel } = useRecurringFrequencyLabel({
    startsAt,
    frequency: recurringFrequency,
  });

  if (!eventData) {
    return <SkeletonLoader variant="right-sidebar" />;
  }

  const hasEventFinished = !!endsAt && dateIsPast(endsAt);
  const isRecurring = eventType === EVENT_TYPES.RECURRING;
  const { rsvped_event: isEventConfirmed } = event;

  const openRecurringUnRsvpModal = () => {
    void unRsvpModal.show({ event, onChange });
  };

  const rsvpActionProps = {
    event,
    onChange,
    full: true,
    detailsPage: true,
    ...(isRecurring && {
      toggleRecurringRsvp: isEventConfirmed
        ? openRecurringUnRsvpModal
        : openRecurringRsvpModal,
    }),
  };

  const isLiveLimitReached =
    isMonthlyHourLimitReached ||
    (liveStreamRoomSetting?.recording_enabled && isStorageLimitReached);

  const shouldHideLocationFromCurrentMember =
    shouldHideLocationFromNonAttendees && !rsvpedEvent;

  const canAccessLocation = isLoggedIn || isPublicStream(event);

  const shouldDisplayJoinButton =
    canAccessLocation &&
    isOnlineEvent(event) &&
    isEventActive(event) &&
    !isLiveLimitReached &&
    !hasEventFinished &&
    !shouldHideLocationFromCurrentMember;

  const shouldDisplayRecurringEventInfo = isRecurring && comingRecurrences > 0;

  const shouldDisplayAddToCalendar = !hasEventFinished && rsvpedEvent;

  return (
    <BlockInViewOnlyMode>
      <div
        className={classNames(
          "border-primary bg-primary w-full rounded-2xl border p-6",
          {
            "my-5": isUsedInMinimalTipTapViewModal,
          },
        )}
      >
        <div className="rsvp-section">
          <div className="border-primary border-b pb-4 last:border-b-0 last:pb-0">
            <SidebarEventTimestamp eventData={eventData} />
          </div>
          <Location
            event={event}
            hasEventFinished={hasEventFinished}
            shouldDisplayJoinButton={shouldDisplayJoinButton}
            isLiveLimitReached={isLiveLimitReached}
            shouldHideLocationFromCurrentMember={
              shouldHideLocationFromCurrentMember
            }
          />
          {shouldDisplayRecurringEventInfo && (
            <div className="mt-4 flex flex-row items-center space-x-4">
              <LocationIcon icon="20-calendar-recurring" />
              <div>
                <div>
                  <Typography.LabelSm>
                    {recurringFrequencyLabel}
                  </Typography.LabelSm>
                </div>
                {!isPaidEvent(event) && (
                  <ListRecurringEventButton
                    event={event}
                    onChange={onChange}
                    buttonText={t("events.rsvp.show_all_events")}
                  />
                )}
              </div>
            </div>
          )}
          {hasEventFinished && (
            <EventFinished
              eventData={eventData}
              rsvpedEvent={rsvpedEvent}
              endsAt={endsAt}
              dataTestid="event-finished"
            />
          )}
          {!shouldDisplayJoinButton &&
            (shouldDisplayRsvpAction || shouldDisplayAddToCalendar) && (
              <div
                className={classNames({
                  "mt-6": shouldDisplayRsvpAction,
                })}
              >
                {shouldDisplayRsvpAction && (
                  <>
                    {isPaidEvent(event) && (
                      <TicketInfo
                        rsvpStatus={rsvpStatus}
                        ticketData={ticketData}
                        event={event}
                      />
                    )}
                    <RsvpLimitInfo event={event} />
                    <RsvpAction {...rsvpActionProps} />
                  </>
                )}
                {shouldDisplayAddToCalendar && (
                  <div className="pt-4">
                    {selfUpdateCalendarEnabled ? (
                      <AddToCalendarComponent
                        setShouldShowAddToCalendarModal={
                          setShouldShowAddToCalendarModal
                        }
                      />
                    ) : (
                      <AddToCalendar
                        {...addToCalendarHelper(event)}
                        variant="button"
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          {shouldDisplayJoinButton && (
            <JoinButton event={event} onChange={onChange} />
          )}
        </div>
        <RecurringRsvpModal
          event={event}
          show={isRecurringRsvpOpen}
          onClose={onRecurringModalCose}
          onChange={onChange}
        />
        <AddToCalendarConfirmationModal
          event={event}
          isOpen={shouldShowAddToCalendarModal}
          onClose={dismissAddToCalendarModal}
        />
      </div>
    </BlockInViewOnlyMode>
  );
};
