import { useState } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePaywallsAdminFormModal } from "@/react/components/Paywalls/Admin/PaywallsAdmin/FormModal";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { usePunditUserContext } from "@circle-react/contexts";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Paywall } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export interface PaywallContextualMenuProps {
  paywall: Paywall;
  showRemove?: boolean;
  onRemove: (paywall: Paywall) => void;
  refetchPaywalls: () => void;
}

export const PaywallContextualMenu = ({
  paywall,
  showRemove = false,
  onRemove,
  refetchPaywalls,
}: PaywallContextualMenuProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const [shouldShowConfirmationModal, toggleConfirmationModal] =
    useBoolean(false);
  const canHover = useAnyHoverMediaQuery();
  const [hasDropdownOpen, setHasDropdownOpen] = useState(false);
  const { show } = usePaywallsAdminFormModal();
  const editPaywallModal = (paywall: Paywall) =>
    show({
      paywall,
      refetchPaywalls,
      paywallGroupsEnabled:
        currentCommunityMember.policies.paywall_group_enabled,
    });

  const onConfirmDelete = () => {
    onRemove(paywall);
    toggleConfirmationModal();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={shouldShowConfirmationModal}
        title={t("customize_space.paywalls.remove_modal.title")}
        onClose={toggleConfirmationModal}
        onCancel={toggleConfirmationModal}
        onConfirm={() => onConfirmDelete()}
        confirmVariant="danger"
      >
        {t("customize_space.paywalls.remove_modal.description", {
          name: paywall.display_name,
        })}
      </ConfirmationModal>

      <Dropdown
        direction="bottom-end"
        className={classNames("text-dark flex justify-end", {
          "invisible group-hover:visible": canHover && !hasDropdownOpen,
        })}
        button={
          <span className="hover:bg-tertiary flex h-6 w-6 items-center justify-center rounded transition-colors">
            <Icon
              type="20-menu-dots-horizontal"
              className="text-default"
              size={20}
            />
          </span>
        }
        onToggle={setHasDropdownOpen}
      >
        <Dropdown.ItemWithLink
          onClick={event => {
            event.preventDefault();
            void editPaywallModal(paywall);
          }}
        >
          {t("edit")}
        </Dropdown.ItemWithLink>
        {showRemove && (
          <Dropdown.ItemWithLink
            onClick={event => {
              event.preventDefault();
              toggleConfirmationModal();
            }}
          >
            {t("customize_space.paywalls.remove")}
          </Dropdown.ItemWithLink>
        )}
      </Dropdown>
    </>
  );
};
