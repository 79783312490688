import { t } from "@/i18n-js/instance";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { Icon } from "@circle-react-shared/Icon";
import { ErrorNotice } from "@circle-react-shared/uikit/Notice/ErrorNotice";
import { Typography } from "@circle-react-shared/uikit/Typography";

const i18nBase =
  "settings.workflows.table.notices.marketing_hub_access_ending_soon";

interface MhAccessEndingSoonErrorNoticeProps {
  accessEndDate: string;
}

export const MhAccessEndingSoonErrorNotice = ({
  accessEndDate,
}: MhAccessEndingSoonErrorNoticeProps) => (
  <div className="bg-primary px-6 pt-5">
    <ErrorNotice
      iconEl={<Icon type="20-info" size={20} className="mt-0.5 !self-start" />}
    >
      <div className="flex flex-col gap-1">
        <Typography.LabelSm weight="bold">
          {t(`${i18nBase}.title`)}
        </Typography.LabelSm>
        <Typography.LabelSm>
          {t(`${i18nBase}.description`, {
            accessEndDate: formatDateString(accessEndDate),
          })}
        </Typography.LabelSm>
      </div>
    </ErrorNotice>
  </div>
);
