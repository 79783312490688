import { useRouteMatch } from "react-router-dom";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useIsCustomColorTheme } from "@circle-react/hooks/useIsCustomColorTheme";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { useThemeContext } from "@circle-react/providers";
import { useIsEitherOptInOrPreviewBarVisible } from "../NewVersionOptIn/useIsEitherOptInOrPreviewBarVisible";
import { useSidebarBorderStyles } from "../Spaces/SidebarV2/hooks";

export const useCommunitySwitcherStyles = (className?: string) => {
  const isCustomColorTheme = useIsCustomColorTheme();
  const bodyStyles = useSidebarBorderStyles();

  const themeContext = useThemeContext();
  const isDarkAppearance = Boolean(themeContext.isDarkAppearance);

  const analyticsRoute = useRouteMatch({ path: "/analytics", exact: false });
  const isAnalyticsRoute = Boolean(analyticsRoute);

  const isDefault = !isDarkAppearance && !isCustomColorTheme;
  const isDarkDefault = isDarkAppearance && !isCustomColorTheme;

  const { isV3Enabled } = useIsV3();
  const hasMasquerade = useMasqueradeEnabledAndActive();
  const hasV3Bar = useIsEitherOptInOrPreviewBarVisible();

  const v2Presentation = classNames("community__switcher", className);

  const v3Presentation = classNames(v2Presentation, "min-h-screen", {
    "!bg-c-switcher border-primary border-r": isDefault,
    "!bg-c-switcher border-c-sidebar border-r": isDarkDefault,
  });

  const presentationClasses = isV3Enabled ? v3Presentation : v2Presentation;

  const v2Body = classNames("switcher__wrapper scrollbar-hide", {
    "!bg-c-switcher border-primary overflow-x-hidden border-r": isDefault,
    "!bg-c-switcher border-c-sidebar overflow-hidden border-r": isDarkDefault,
    "!bg-c-sidebar overflow-hidden border-r": isCustomColorTheme,
    "!top-12": !isAnalyticsRoute && !hasMasquerade && !hasV3Bar,
    "!top-24":
      (!isAnalyticsRoute && hasMasquerade && !hasV3Bar) ||
      (!isAnalyticsRoute && !hasMasquerade && hasV3Bar),
  });

  const v3Body = classNames("min-h-screen p-4", {
    "!bg-c-sidebar overflow-hidden border-r": isCustomColorTheme,
    "!top-24": !isAnalyticsRoute && hasMasquerade && !hasV3Bar,
  });

  const bodyClasses = isV3Enabled ? v3Body : v2Body;

  return {
    presentationClasses,
    bodyClasses,
    bodyStyles,
  };
};
