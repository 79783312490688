import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import smallCircleLogo from "@circle-assets/images/small-circle-logo.png";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { ToggleSwitch } from "@circle-react-shared/uikit/Form/ToggleSwitch";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const Switch = ({
  name,
  icon,
  count,
  tooltipContent,
  disabled,
}: {
  name: string;
  icon?: IconType;
  count?: number;
  tooltipContent?: React.ReactNode;
  disabled?: boolean;
}) => {
  const { watch } = useFormContext();
  const isEnabled = watch(name);

  return (
    <div
      className={classNames(
        "rounded-lg border-[1.5px] py-2 pl-3 pr-6",
        { "border-secondary dark:border-gray-300": isEnabled },
        { "border-primary dark:border-primary": !isEnabled },
      )}
    >
      <div className="flex items-center justify-between">
        <div className="dark:text-light flex h-10 w-10 justify-center align-middle">
          {icon ? (
            <Icon type={icon} size={20} />
          ) : (
            <div className="content-center">
              <img
                src={smallCircleLogo}
                alt={t("community_bot.bot_builder.knowledge_page.circle_logo")}
                loading="eager"
                className="h-5 w-5"
              />
            </div>
          )}
        </div>
        <div className="text-dark flex grow items-center gap-2">
          <Typography.LabelSm weight="semibold" as="div" textTransform="mb-px">
            {t(`community_bot.bot_builder.knowledge_page.${name}`)}
          </Typography.LabelSm>
          {typeof count === "number" && (
            <div className="border-primary flex h-4 min-w-[20px] items-center justify-center rounded border px-1 text-[11px] font-semibold leading-4 tracking-[-0.4px]">
              {count}
            </div>
          )}
          {tooltipContent && (
            <TippyV2 content={tooltipContent}>
              <div className="flex items-center">
                <Icon type="16-question-mark" size={16} />
              </div>
            </TippyV2>
          )}
        </div>
        {disabled ? (
          <TippyV2
            content={t(
              "community_bot.bot_builder.knowledge_page.at_least_one_tooltip",
            )}
          >
            <div className="pointer-events-none">
              <ToggleSwitch name={name} shouldUseCheckmark variant="small" />
            </div>
          </TippyV2>
        ) : (
          <ToggleSwitch name={name} shouldUseCheckmark variant="small" />
        )}
      </div>
    </div>
  );
};
