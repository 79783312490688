import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Icon } from "@circle-react-shared/Icon";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-uikit/Typography";

export const PreviewConversationBlock = () => {
  const { currentBotProfile } = useCommunityInboxStore();
  if (!currentBotProfile) {
    return null;
  }
  const { name, bio } = currentBotProfile;

  return (
    <div className="mb-5">
      <Icon type="48-ai-bot" size={48} className="mb-2 !h-[3rem] !w-[3rem]" />
      <Typography.TitleSm as="h2" weight="semibold">
        {name}
      </Typography.TitleSm>
      {bio && (
        <div className="text-dark whitespace-pre-line text-sm font-normal">
          <TiptapEditor editable={false} rich_text_body={bio} />
        </div>
      )}
    </div>
  );
};
