import PropTypes from "prop-types";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { Icon } from "@circle-react-shared/Icon";
import { Action } from "../Action";
import { useEntryRule } from "./useEntryRule";

export const EntryRule = ({ trigger, audience, workflowType }) => {
  const entryRule = useEntryRule({
    trigger,
    audience,
    workflowType,
  });

  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();

  if (!entryRule) {
    return null;
  }

  const { title, iconType, size = "lg" } = entryRule || {};

  if (isWorkflowsV2Enabled) {
    return (
      <Action size={size} tooltipContent={title}>
        <div className="bg-tertiary flex h-8 max-h-8 w-8 max-w-[2rem] shrink-0 items-center justify-center rounded-full">
          <Icon
            type={iconType}
            size={16}
            className="text-dark !h-4 !w-4 shrink-0"
          />
        </div>
      </Action>
    );
  }

  return (
    <Action size={size} tooltipContent={title}>
      <Icon
        type={`24-workflow-${iconType}`}
        size={24}
        useWithFillCurrentColor
        className="!h-5 !w-5"
      />
    </Action>
  );
};

EntryRule.propTypes = {
  trigger: PropTypes.string,
  audience: PropTypes.string,
  workflowType: PropTypes.string,
};
