import { t } from "@/i18n-js/instance";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useDeleteSnippet } from "../snippetsQueries";

export const DeleteSnippetModalComponent = ({
  id,
  title,
}: {
  id: number;
  title: string;
}) => {
  const modal = useModal();
  const deleteSnippetMutation = useDeleteSnippet({
    onSuccess: modal.remove,
  });

  return (
    <ConfirmationModal
      size="md"
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t(
        "settings.community_bot.knowledge.custom.delete_snippet_modal.title",
      )}
      confirmVariant="danger"
      confirmText={
        deleteSnippetMutation.isLoading
          ? t(
              "settings.community_bot.knowledge.custom.delete_snippet_modal.confirming",
            )
          : t(
              "settings.community_bot.knowledge.custom.delete_snippet_modal.confirm",
            )
      }
      onConfirm={() => {
        deleteSnippetMutation.mutate({ id });
      }}
      disabled={deleteSnippetMutation.isLoading}
    >
      <Typography.BodyMd>
        <SanitizeHTMLString
          content={t(
            "settings.community_bot.knowledge.custom.delete_snippet_modal.description",
            { title },
          )}
        />
      </Typography.BodyMd>
    </ConfirmationModal>
  );
};
