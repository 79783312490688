import { awardPoints } from "@circle-react/components/SettingsApp/Workflows/components/Actions/Gamification/awardPoints";
import { rsvpMemberToEvent } from "./Event";
import { timeDelay } from "./Flow/TimeDelay";
import {
  disableMessaging,
  enableMessaging,
  removeMember,
  sendDM,
  sendEmail,
  sendPushNotification,
  unsubscribe,
} from "./Member";
import { addTag, removeTag } from "./MemberTag";
import {
  cancelSubscriptionFromPaywall,
  subscribeToPaywallTrial,
} from "./Paywall";
import { addMemberToSpace, removeMemberFromSpace } from "./Space";
import {
  addMemberToSpaceGroup,
  removeMemberFromSpaceGroup,
} from "./SpaceGroup";
import { sendToWebhook } from "./Webhook";

export const availableActions = [
  sendEmail,
  sendDM,
  addTag,
  removeTag,
  removeMember,
  addMemberToSpace,
  removeMemberFromSpace,
  addMemberToSpaceGroup,
  removeMemberFromSpaceGroup,
  sendToWebhook,
  cancelSubscriptionFromPaywall,
  subscribeToPaywallTrial,
  rsvpMemberToEvent,
  sendPushNotification,
  enableMessaging,
  disableMessaging,
  timeDelay,
  awardPoints,
  unsubscribe,
] as const;
