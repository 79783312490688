import { t } from "@/i18n-js/instance";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { Button } from "@circle-react-uikit/Button";

interface Props {
  handleTestWebhook: () => void;
  isDisabled: boolean;
  isTestingWebhook: boolean;
  isWebhookTestSuccessful: boolean;
  isWebhookUrlChanged: boolean;
  isWebhookTested: boolean;
  triggerType: string;
}

export const WebhookTestButton = ({
  handleTestWebhook,
  isDisabled,
  isTestingWebhook,
  isWebhookTestSuccessful,
  isWebhookUrlChanged,
  isWebhookTested,
  triggerType,
}: Props) => {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
  const getTestButtonMessage = () => {
    const isTriggerTypeEmpty = !triggerType || triggerType === "";
    const shouldTestAgain =
      (!isWebhookTestSuccessful || isWebhookUrlChanged) && isWebhookTested;

    if (isTestingWebhook) {
      return t("settings.workflows.form.testing_webhook");
    }
    if (shouldTestAgain) {
      return t("settings.workflows.form.test_again");
    }
    if (isTriggerTypeEmpty) {
      return t("settings.workflows.form.select_entry_rule");
    }
    return t("settings.workflows.form.test_webhook");
  };

  return (
    <Button
      disabled={isDisabled}
      onClick={handleTestWebhook}
      large={!isWorkflowsV2Enabled}
      variant="circle"
      type="button"
    >
      {getTestButtonMessage()}
    </Button>
  );
};
