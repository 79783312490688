import PropTypes from "prop-types";
import I18n, { t } from "@/i18n-js/instance";
import { useUpdateCommunitySetting } from "@/react/hooks/UseCommunitySetting";
import { usePunditUserContext } from "@circle-react/contexts";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import { Button } from "@circle-react-uikit/Button";
import { Drawer } from "@circle-react-uikit/Drawer";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { DefaultSort } from "./DefaultSort";
import { ExcludeEmptyProfiles } from "./ExcludeEmptyProfiles";

const localeScope = "members_directory.header.settings";
const viewOptions = [
  { value: VIEWS.CARDS, label: t(["members_directory.header", "views.cards"]) },
  { value: VIEWS.LIST, label: t(["members_directory.header", "views.list"]) },
];

export const Settings = ({ open, onClose }) => {
  const toastr = useToast();
  const { isLoading, currentCommunitySettings } = usePunditUserContext();

  const updateCommunitySettingMutation = useUpdateCommunitySetting({
    onSuccess: () => {
      toastr.success(I18n.t(`${localeScope}.save.success`));
      onClose();
    },
    onError: () => {
      toastr.error(I18n.t(`${localeScope}.save.error`));
    },
  });
  if (!currentCommunitySettings || isLoading) {
    return null;
  }

  const { member_directory_settings = {} } = currentCommunitySettings;

  const onSubmit = async formData => {
    updateCommunitySettingMutation.mutate({
      data: { community_settings: currentCommunitySettings },
      updates: formData,
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={I18n.t(`${localeScope}.title`)}
      noWrapperPadding
    >
      <Form
        onSubmit={onSubmit}
        defaultValues={{ member_directory_settings }}
        className="h-full"
        formClassName="flex flex-col h-full"
      >
        <div className="grow px-6">
          <ExcludeEmptyProfiles />
          <DefaultSort defaultValue={member_directory_settings.sort} />
          <Form.Item
            name="member_directory_settings.view"
            label={I18n.t([localeScope, "form.view"])}
            hideDescription
            hideBorder
          >
            <Form.SelectNative
              options={viewOptions}
              defaultValue={VIEWS.CARDS}
            />
          </Form.Item>
        </div>
        <Drawer.Footer>
          <Button
            disabled={updateCommunitySettingMutation.isLoading}
            type="submit"
            variant="primary"
            full
          >
            {I18n.t(`${localeScope}.save.button`)}
          </Button>
        </Drawer.Footer>
      </Form>
    </Drawer>
  );
};

Settings.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
