import { t } from "@/i18n-js/instance";
import { Input } from "@circle-react/components/FormV2/Input";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { usePunditUserContext } from "@circle-react/contexts";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Typography } from "@circle-react-shared/uikit/Typography";

const i18nRoot = "settings.emails.builder";

interface SendTestEmailModalContentProps {
  handleSendTestEmail: () => void;
}

const SendTestEmailModalContent = ({
  handleSendTestEmail,
}: SendTestEmailModalContentProps) => {
  const modal = useModal();
  const onAction = () => {
    void modal.hide();
  };

  const { currentUser, isLoading } = usePunditUserContext();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ActionModal
      isOpen={modal.visible}
      onClose={onAction}
      actionVariant="circle"
      actionText={t([i18nRoot, "send"])}
      onAction={handleSendTestEmail}
      title={t([i18nRoot, "send_test_email_modal.send_test_email"])}
    >
      <div className="mb-1">
        <Input value={currentUser.email} disabled />
      </div>
      <div className="leading-tight">
        <Typography.LabelXs color="text-default">
          {t([i18nRoot, "send_test_email_modal.description"])}
        </Typography.LabelXs>
      </div>
    </ActionModal>
  );
};

export const SendTestEmailModal = ModalManager.create(
  ({ handleSendTestEmail }: SendTestEmailModalContentProps) => (
    <SendTestEmailModalContent handleSendTestEmail={handleSendTestEmail} />
  ),
);

export const useSendTestEmailModal = () => useModal(SendTestEmailModal);
