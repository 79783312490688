import isFunction from "lodash/isFunction";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { CustomizeSpaceFullPage } from "@/react/components/Spaces/CustomizeSpaceFullPage";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { parseSpacePayload } from "@circle-react/components/Spaces/Edit/EditForm/helpers.js";
import { TOPICS_QUERY_KEY } from "@circle-react/components/Spaces/TopicFilters/hooks/useTopics";
import {
  SPACE_SHOW_REQUEST_KEY,
  usePunditUserContext,
  useSpaceResource,
  useSpacesContext,
} from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import {
  isCourseSpace,
  useSpaceLinkWithDefaultTab,
} from "@circle-react/helpers/spaceHelpers";
import { spaceUpdatePath } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { getCustomizeSpaceSections } from "./getCustomizeSpaceSections";

export interface CustomizeSpaceDrawerProps {
  spaceId: number;
  refetch?: () => Promise<void> | void;
  initialSection?: string;
  shouldRedirectOnSlugChange?: boolean;
  scrollToId?: string;
}

const CustomizeSpaceDrawerComponent = ({
  spaceId,
  refetch,
  initialSection,
  shouldRedirectOnSlugChange = true,
  scrollToId,
}: CustomizeSpaceDrawerProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { currentCommunitySettings = {} } = usePunditUserContext();
  const { space, refetch: refetchSpace } = useSpaceResource({ spaceId });
  const {
    actions: { refresh: refetchSpaces },
  } = useSpacesContext();
  const getSpaceLinkWithDefaultTab = useSpaceLinkWithDefaultTab();
  const drawer = useModal();
  const { success } = useToast();

  const refetchAllSpaceData = async () => {
    await queryClient.invalidateQueries([SPACE_SHOW_REQUEST_KEY, spaceId]);
    await queryClient.invalidateQueries([TOPICS_QUERY_KEY, spaceId]);
    await refetchSpace();
    // TODO: Replace with invalidation after migration to react-query
    await refetchSpaces();

    if (isFunction(refetch)) {
      // refetches the space within the space context
      await refetch();
    }
  };

  const onSlugChange = (space: any) => {
    if (shouldRedirectOnSlugChange) {
      history.push(getSpaceLinkWithDefaultTab(space));
    }
  };

  const onSuccess = async () => {
    success(t("changes_saved"));
    await refetchAllSpaceData();
  };

  const updateSpaceMutation = useMutation(
    formData => {
      const payload = parseSpacePayload(formData);
      return reactQueryPut(spaceUpdatePath(String(space?.id)), {
        space: payload,
      });
    },
    {
      onSuccess,
    },
  );

  const onSubmitFullPageForm = ({ data, onSuccess }: any) => {
    updateSpaceMutation.mutate(data, { onSuccess });
  };

  const sections = getCustomizeSpaceSections({
    isCourseSpace: isCourseSpace(space),
    areWorkflowsEnabled:
      currentCommunitySettings?.upgrade_flows_for_workflows_spaces_enabled,
  });

  return (
    <CustomizeSpaceFullPage
      isOpen={drawer.visible}
      space={space}
      sections={sections}
      initialSection={initialSection || sections[0]}
      scrollToId={scrollToId}
      onSlugChange={onSlugChange}
      onSave={onSubmitFullPageForm}
      onExit={() => {
        void refetchAllSpaceData();
        void drawer.hide();
      }}
      onClose={() => drawer.hide()}
    />
  );
};

const CustomizeSpaceDrawer = ModalManager.create(
  (props: CustomizeSpaceDrawerProps) => (
    <CustomizeSpaceDrawerComponent {...props} />
  ),
);

export const useCustomizeSpaceDrawer = () => useModal(CustomizeSpaceDrawer);
