import { t } from "@/i18n-js/instance";
import { Header } from "@/react/components/Chat/MessagingV2/RailBar/Header";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Rail } from "@circle-react-uikit/Rail";
import { AgentAvatar } from "./AgentAvatar";
import { PrivacyNotice } from "./PrivacyNotice";

interface BotProfileRailbarProps {
  botProfile: Profile;
}

export const BotProfileRailbar = ({ botProfile }: BotProfileRailbarProps) => {
  const { ref: headerRef, height: headerHeight } = useElementHeight();

  return (
    <RailBarPortal>
      <div ref={headerRef}>
        <Rail.Header className="!min-h-[4rem]">
          <Header title={t("profile")} isDirect />
        </Rail.Header>
      </div>
      <Rail.Content noPadding heightGap={headerHeight}>
        <div className="flex h-full flex-col px-6 py-5">
          <div className="flex grow flex-col">
            <div className="mb-4">
              <AgentAvatar profile={botProfile} />
              <Typography.TitleSm weight="semibold">
                {botProfile.name}
              </Typography.TitleSm>
            </div>
            {botProfile.bio && (
              <div className="mb-5 flex flex-col gap-y-2">
                <Typography.LabelSm weight="semibold">
                  {t("community_inbox.profile.bio")}
                </Typography.LabelSm>
                <div className="text-sm font-normal leading-5 tracking-tighter">
                  <TiptapEditor
                    editable={false}
                    rich_text_body={botProfile.bio}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="shrink-0 pb-2 text-center">
            <PrivacyNotice />
          </div>
        </div>
      </Rail.Content>
    </RailBarPortal>
  );
};
