import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { FormProvider, useForm } from "react-hook-form";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { getDefaultAction } from "../../constants";
import type { Workflow } from "../../generated/WorkflowsApi.types";

interface WorkflowFormProviderProps {
  workflow: Workflow;
}

export const WorkflowFormProvider = ({
  workflow,
  children,
}: PropsWithChildren<WorkflowFormProviderProps>) => {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
  const hasActions = !isEmpty(workflow?.actions);
  const actions = useMemo(() => {
    if (isWorkflowsV2Enabled && !hasActions) {
      return [];
    }
    if (hasActions) {
      return workflow.actions;
    }
    return [getDefaultAction()];
  }, [isWorkflowsV2Enabled, hasActions, workflow?.actions]);

  const isBulkAction = workflow?.workflow_type === "bulk_action";
  const isScheduled = workflow?.workflow_type === "scheduled";
  const hasAudienceFilters = workflow?.audience?.filter_rules?.filters;
  const audience = useMemo(() => {
    if ((isBulkAction || isScheduled) && hasAudienceFilters) {
      return workflow.audience;
    }
    return {
      filter_rules: {
        filters: [],
      },
    };
  }, [hasAudienceFilters, isBulkAction, isScheduled, workflow.audience]);

  const schedule = isScheduled ? workflow.schedule : {};

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...workflow,
      actions,
      audience,
      schedule,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
