import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { reactQueryPatch } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

interface UpdateOptInPayload {
  operation: "migrate_admin" | "rollback_admin" | "migrate_community";
}

const setSessionStorageItem = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (err) {
    console.warn(`Unable to access session storage for key "${key}".`);
  }
};

export const useUpdateOptInSettings = () => {
  const { error } = useToast();

  return useMutation<unknown, ApiError, UpdateOptInPayload>({
    mutationFn: payload =>
      reactQueryPatch(internalApi.settings.v3_ui_preference.update(), payload),
    onSuccess: (_, payload) => {
      if (payload.operation === "migrate_admin") {
        setSessionStorageItem("showAdminPreviewModal", "true");
      } else if (payload.operation === "migrate_community") {
        setSessionStorageItem("showVersionActivationModal", "true");
      }

      window.location.href = "/";
    },
    onError: () => {
      error(t("failed_to_save_changes"));
    },
  });
};
