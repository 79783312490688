import { t } from "@/i18n-js/instance";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { LinkClicksStats } from "@circle-react-shared/Emails/BroadcastReport/LinkClicksStats";
import { StatsPercentageGrid } from "@circle-react-shared/Emails/BroadcastReport/StatsPercentageGrid";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { BroadcastMeta } from "./BroadcastMeta";

interface FullReportsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function FullReportsModal({ isOpen, onClose }: FullReportsModalProps) {
  const { isLoading } = useBroadcastReport();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content size="2xl" shouldCloseOnOutsideClick>
        <Modal.Header>
          <Modal.Title>{t("settings.workflows.view.email_report")}</Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="pb-6 pt-4">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col gap-y-8">
              <BroadcastMeta />
              <div>
                <StatsPercentageGrid />
                <LinkClicksStats />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
