import { useRef } from "react";
import classNames from "classnames";
import { isFunction } from "lodash";
import { MessageIndicatorLines } from "@/react/components/Chat/ChatWindowV2/MessageItem/MessageIndicatorLines";
import { CursorPagination } from "@/react/components/shared/Pagination/CursorPagination";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import type { Message } from "@circle-react/types/CommunityInbox/Message";
import { MESSAGE_CONTAINER_ID } from "../../hooks/useScroll";
import { MessageBox } from "../MessageBox";
import { Avatar } from "./Avatar";
import { ContentBlock } from "./ContentBlock";
import { NewConversationBlock } from "./NewConversationBlock";
import { PreviewConversationBlock } from "./PreviewConversationBlock";
import { UnavailableIndicator } from "./UnavailableIndicator";

interface ChatWindowProps {
  messages: Message[];
  createMessage: (data: any) => void;
  isDms: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
  isNewConversation: boolean;
  isPreviewMode?: boolean;
  agentName?: string;
  isProcessing?: boolean;
  isDisabled?: boolean;
}

export const ChatWindow = ({
  messages,
  createMessage,
  isDms,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isNewConversation,
  isPreviewMode,
  agentName,
  isProcessing,
  isDisabled,
}: ChatWindowProps) => {
  const prevDayRef = useRef<string | null>(null);
  const shouldDisplayPreviewBlock = isPreviewMode && !messages?.length;
  return (
    <div
      className={classNames(
        "bg-primary flex w-full flex-col justify-between",
        isNewConversation || isPreviewMode
          ? "h-full"
          : "h-[calc(100dvh-64px)] lg:h-[calc(100%-64px)]",
      )}
    >
      {hasNextPage && (
        <CursorPagination
          componentId={MESSAGE_CONTAINER_ID}
          fetchNewPage={fetchNextPage}
          onEvent="scrollUp"
          messageId={null}
          isLoading={isLoading}
        />
      )}
      <div id={MESSAGE_CONTAINER_ID} className="overflow-auto">
        {messages.map((message: Message) => {
          const {
            created_at: createdAt,
            updated_at: updatedAt,
            id,
            body,
            author_type: authorType,
            community_bot_profile: communityBotProfile,
            community_member: communityMember,
            sources,
          } = message;

          const isUserMessage = authorType === "community_member";
          const authorName = isUserMessage
            ? communityMember?.name || ""
            : communityBotProfile?.name || "";
          const elementKey = `${id}-${updatedAt}`;

          const { day } = formattedMessageSentAt(createdAt);
          const isDayChange = prevDayRef?.current !== day;
          if (prevDayRef && !isFunction(prevDayRef)) {
            prevDayRef.current = day;
          }

          return (
            <>
              {!isPreviewMode && (
                <MessageIndicatorLines
                  showDateChangeLine={isDayChange}
                  day={day}
                />
              )}
              <div className="px-4 py-3" id={id.toString()} key={elementKey}>
                <div className="flex w-full items-start justify-start gap-3">
                  <div className="pt-1.5">
                    <Avatar message={message} />
                  </div>
                  <ContentBlock
                    authorName={authorName}
                    timestamp={createdAt}
                    body={body}
                    sources={sources}
                  />
                </div>
              </div>
            </>
          );
        })}
        {isDisabled && agentName && (
          <UnavailableIndicator agentName={agentName} />
        )}
      </div>
      {isDms && (
        <div className="px-4 py-3">
          {isNewConversation && <NewConversationBlock />}
          {shouldDisplayPreviewBlock && <PreviewConversationBlock />}
          {!isDisabled && (
            <MessageBox
              onSubmit={createMessage}
              isProcessing={isProcessing}
              agentName={agentName}
            />
          )}
        </div>
      )}
    </div>
  );
};
