import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Button } from "@circle-react-shared/uikit/Button";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Modal } from "@circle-react-uikit/ModalV2";

export const AdminPreviewModalComponent = () => {
  const modal = useModal();

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t("new_opt_in_admin_preview_modal.title")}
    >
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title size="sm">
            <Typography.TitleMd weight="font-bold">
              {t("new_opt_in_admin_preview_modal.title")}
            </Typography.TitleMd>
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-2">
            <Typography.LabelLg color="text-dark" weight="semibold">
              {t("new_opt_in_admin_preview_modal.body.title")}
            </Typography.LabelLg>
            <Typography.BodyMd color="text-default">
              <SanitizeHTMLString
                content={t("new_opt_in_admin_preview_modal.body.description")}
              />
            </Typography.BodyMd>
            <ul className="ml-4 list-outside space-y-4">
              <li className="space-y-2">
                <Typography.LabelMd color="text-dark" weight="semibold">
                  {t("new_opt_in_admin_preview_modal.body.point_1")}
                </Typography.LabelMd>
                <SanitizeHTMLString
                  as="p"
                  className="text-default text-base leading-6 tracking-tighter"
                  content={t(
                    "new_opt_in_admin_preview_modal.body.description_1",
                  )}
                />
              </li>
              <li className="space-y-2">
                <Typography.LabelMd color="text-dark" weight="semibold">
                  {t("new_opt_in_admin_preview_modal.body.point_2")}
                </Typography.LabelMd>
                <SanitizeHTMLString
                  as="p"
                  className="text-default text-base leading-6 tracking-tighter"
                  content={t(
                    "new_opt_in_admin_preview_modal.body.description_2",
                  )}
                />
              </li>
              <li className="space-y-2">
                <Typography.LabelMd color="text-dark" weight="semibold">
                  {t("new_opt_in_admin_preview_modal.body.point_3")}
                </Typography.LabelMd>
                <SanitizeHTMLString
                  as="p"
                  className="text-default text-base leading-6 tracking-tighter"
                  content={t(
                    "new_opt_in_admin_preview_modal.body.description_3",
                  )}
                />
              </li>
              <li className="space-y-2">
                <Typography.LabelMd color="text-dark" weight="semibold">
                  {t("new_opt_in_admin_preview_modal.body.point_4")}
                </Typography.LabelMd>
                <SanitizeHTMLString
                  as="p"
                  className="text-default text-base leading-6 tracking-tighter"
                  content={t(
                    "new_opt_in_admin_preview_modal.body.description_4",
                  )}
                />
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-end border-t">
          <Button
            variant="circle"
            onClick={() => {
              void modal.hide();
            }}
          >
            {t("new_opt_in_admin_preview_modal.got_it")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const AdminPreviewModal = ModalManager.create(() => (
  <AdminPreviewModalComponent />
));

export const useAdminPreviewModal = () => useModal(AdminPreviewModal);
